<!------- "version": "1.60", "date": "2024-12-20 924", "status": "aed2, fault/warn/Pass icons, test" ---------->
<template>
  <v-card id="idBackGnd" width="100%" min-width="900px" class="ma-0 pa-0 d-flex">

    <!-- original background color is #161616, card is #393939    -->

    <!----------------------- Left Pane ----------------------------->
    <v-container class="transparent ma-0 pa-0" style="width: 65%;">

      <v-sheet width="99%" height="90px" class="ma-0 pa-0 d-flex transparent elevation-0 border-0">
        <!-- <DevStatistics title="Total" message="12" :width="statW" :height="statH" class="ml-1 mt-1" @onClickCard="onClickTotal"/>
        <DevStatistics title="Normal" message="8" :width="statW" :height="statH" class="ml-1 mt-1" @onClickCard="onClickNormal"/>  -->
        <DevStatistics title="Fault" :value="alarmStatus.fault" :unit="alarmUnit.fault" :size="statSize"
          class="ml-2 mt-2" @onClickCard="onClickFault" :msg2="faultAlert.msg2" :msg="faultAlert.msg1" />
        <DevStatistics title="Warning" :value="alarmStatus.warn" :unit="alarmUnit.warn" :size="statSize"
          class="ml-2 mt-2" @onClickCard="onClickWarning" :msg="warnAlert.msg1" />
        <DevStatistics title="Offline" :value="alarmStatus.offline" :unit="alarmUnit.offline" :size="statSize"
          class="ml-2 mt-2" @onClickCard="onClickOffline" :msg="offlineAlert.msg1" />
        <!-- <DevStatistics title="Rescue" :value="alarmRescue.value" :unit="alarmRescue.unit" :size="statSize" class="ml-2 mt-2" @onClickCard="onClickRescue"/>  -->
      </v-sheet>

      <!------ Progress circle ------------->
      <v-sheet width="100%" class="ma-0 pa-0 d-flex transparent">
        <v-progress-circular v-if="showProgress" color="blue" :size="30" indeterminate class="mt-1"
          style="position: absolute; top:1; left:60%;"></v-progress-circular>
      </v-sheet>

      <!------ 3 Doughnut Circles ---------->
      <v-sheet width="98%" height="185px" class="mx-2 px-0 mt-4 d-flex rounded-lg" style="background-color: #303030;">
        
        <!-- <v-row no-gutters>
          <v-col cols="4"  align="center">
            <DevStatus :title="aryAnno[0].title" :message="aryAnno[0].message" :width="statusW" :height="statusH"
              :ppData="aryWarranty2" :bgcolor="statusC" class="mt-1" :trigger="triggerChart"
              @ChartClick="onClickWarranty2" />
          </v-col>
          <v-col cols="4" align="center">
            <DevStatus :title="aryAnno[1].title" :message="aryAnno[1].message" :width="statusW" :height="statusH"
              :ppData="aryBatt2" :bgcolor="statusC" class="mt-1 " :trigger="triggerChart"
              @ChartClick="onClickWarning2" />
          </v-col>
          <v-col cols="4"  align="center">
            <DevStatus :title="aryAnno[2].title" :message="aryAnno[2].message" :width="statusW" :height="statusH"
              :ppData="aryPads2" :bgcolor="statusC" class="mt-1 " :trigger="triggerChart"
              @ChartClick="onClickWarning3" />
          </v-col>
        </v-row>  -->

        <v-row no-gutters>
          <v-col cols="4"  align="center">
            <DoughNut3 pWidth="190" pHeight="190" :pTitle="aryAnno[0].title" :pMessage="aryAnno[0].message" 
            :pData="aryWarranty2" :pTrigger="triggerChart" @ChartClick="onClickWarranty2"/>
          </v-col>
          <v-col cols="4" align="center">
            <DoughNut3 pWidth="190" pHeight="190" :pTitle="aryAnno[1].title" :pMessage="aryAnno[1].message" 
            :pData="aryBatt2" :pTrigger="triggerChart" @ChartClick="onClickWarning2"/>
          </v-col>
          <v-col cols="4"  align="center">
            <DoughNut3 pWidth="190" pHeight="190" :pTitle="aryAnno[2].title" :pMessage="aryAnno[2].message" 
            :pData="aryPads2" :pTrigger="triggerChart" @ChartClick="onClickWarning3"/>
          </v-col>
        </v-row>

      </v-sheet>

      <!------------- bottom --------------->
      <v-card class="mx-0 mt-0 pa-0 d-flex transparent" style="height: calc(100vh - 348px);">
        <!-- height never set to 100% -->

        <!------- bottom left: Timeline ----------->
        <!-- <v-card width="31%" class="ml-2 mt-2 pa-1 rounded-lg elevation-0" style="background-color: #161616; height: calc(100vh - 420px);">  -->
        <!-- <v-card width="31%" height="100%" class="ml-2 mt-2 pa-1 rounded-lg elevation-0" style="background-color: #303030;">  -->
        <v-container class="my-1 ml-2 mr-1 pa-1 fill-height classContainer rounded-lg elevation-0"
          style="background-color: #303030;">

          <!---- header ---->
          <v-card width="100%" height="40px" class="mx-0 pa-0 mt-n1 transparent elevation-0">

            <v-card width="100%" height="40px" class="mx-0 pa-0 mt-1 d-flex transparent elevation-0">
              <v-card width="45px" height="45px" class="transparent elevation-0" @click="onClickRescue"
                style="position: relative;">
                <v-sheet width="45px" height="45px" color="green" class="ml-2 mt-2 pa-0 rounded-pill elevation-0"
                  style="filter: blur(2px); opacity: 0.4; position: absolute; top:0; left:0;">
                </v-sheet>
                <v-img :width="30" :src="heartwaveIcon" class="ml-4 mt-4 elevation-0"></v-img>
              </v-card>

              <v-sheet class="transparent elevation-0">
                <v-row width="70%" class="mt-n1 elevation-0">
                  <v-sheet class="ml-7 mt-1 transparent white--text elevation-0 fontTime1">{{ rescueTimes }}
                    times</v-sheet>
                </v-row>
                <v-row width="80%" class="pa-1 d-flex elevation-0">
                  <v-sheet
                    class="ml-6 mt-n3 transparent grey--text text--lighten-1 fontTime2 elevation-0 border-0">Rescue</v-sheet>
                  <select class="mt-n2 ml-3 px-1 py-0 grey lighten-2 black--text rounded elevation-0"
                    @click="onChangeRescue"
                    style="height: 18px; padding: 2; font-family: Montserrat; font-size: 0.7em; font-weight: 580;">
                    <option value="1">1 days</option>
                    <option value="7">7 days</option>
                    <option value="30" selected>30 days</option>
                  </select>
                </v-row>
              </v-sheet>
            </v-card>

            <v-sheet v-if="rescueDevices !== ''"
              class="ml-2 mt-4 transparent grey--text fontTime3 elevation-0 border-0">{{ rescueDevices }}/{{ deviceTotal
              }} AED have been used</v-sheet>
            <v-sheet v-else class="ml-2 mt-4 transparent grey--text fontTime3 elevation-0 border-0">No AED has been
              used</v-sheet>

          </v-card>

          <!-- timeline   pagelength* -->
          <v-card id="id_timeline" width="100%" class="mx-0 mt-6 pa-0 transparent elevation-0"
            style="overflow-y: scroll; height: calc(100vh - 455px);">
            <v-timeline v-if="rescueTimes !== '0'" height="100%" align-top dark dense
              class="pt-0 ml-n5 mt-0 transparent elevation-0">
              <!-- <v-timeline-item v-for="item in emergrpts" :key="item.id" color="orange" class="mt-3 pa-0 white--text" small fill-dot>  -->
              <v-timeline-item v-for="item in emergrpts" :key="item.id" color="#63625f" fill-dot
                class="mt-3 pa-0 white--text elevation-0" small icon="mdi-checkbox-blank-circle" icon-color="orange">
                <v-sheet class="transparent white--text fontTimeLst">{{ item.sn }}</v-sheet>
                <v-sheet class="transparent white--text fontTimeLst">{{ item.InstLoc }}</v-sheet>
                <v-sheet class="transparent white--text fontTimeLst">{{ item.datetime }}</v-sheet>
              </v-timeline-item>
            </v-timeline>
            <v-sheet v-else width="auto" height="20px" class="transparent" >
              <p class="mt-5 grey--text text--lighten-1 text-center elevation-0 text-h4"
                style="font-family: Montserrat;">No Rescue data within {{ selectRescue }} days</p>
            </v-sheet>
          </v-card>

        </v-container>

        <!-------  bottom middle: ListBox ----------->
        <!-- <v-card width="69%" height="100%" class="ml-2 mr-1 mt-2 pa-1 rounded-lg elevation-0" style="background-color: #303030;"> -->
        <v-container width="50%" class="my-1 mr-1 pa-1 fill-height rounded-lg elevation-0"
          style="background-color: #303030;">

          <v-sheet width="100%" height="18px" class="mx-0 pa-0 mt-1 d-flex elevation-0 transparent">
            <v-sheet class="ma-0 pa-0 transparent elevation-0 white--text fontN2">
              <v-img :width="15" :src="listboxIcon" class="ml-2 mt-1 elevation-0"></v-img>
            </v-sheet>
            <v-sheet class="ml-4 white--text transparent elevation-0 fontTitleListBox">Recently Installation</v-sheet>
          </v-sheet>

          <v-sheet width="100%" height="22px" class="mx-0 pa-0 mt-1 d-flex elevation-0 rounded-t grey lighten-2">
            <v-col cols="3" class="pa-0"><v-sheet class="ml-3 mt-1 black--text transparent fontItemListBoxBtn">AED Serial
                number</v-sheet></v-col>
            <!-- <v-col cols="3" class="pa-0"><v-sheet class="ml-1 black--text transparent fontN2">Install Date</v-sheet></v-col> -->
            <v-col cols="7" class="pa-0"><v-sheet class="ml-2 mt-1 black--text transparent fontItemListBoxBtn">Installation
                location</v-sheet></v-col>
            <v-col cols="2" class="pa-0 text-right"><v-sheet
                class="mr-5 mt-1 black--text transparent fontItemListBoxBtn">Action</v-sheet></v-col>
          </v-sheet>

          <!-- pagelength*2 -->
          <v-list id="idList" width="100%" dense class="mt-n1 rounded-b-lg elevation-0 border-0"
            style="background-color: #303030; height: calc(100vh - 415px)">
            <v-list-item-group v-if="aeditems2.length > 0">
              <v-list-item v-for="(item, i) in aeditems2" :key="i" class="pa-0 my-0" @click="getLocation(i, item)"
                :color="getColor(i)">
                <v-list-item-content class="pa-0 ml-2 cBorder1W">
                  <v-row no-gutters class="pa-0 ml-1 my-0">
                    <v-col class="my-0" cols="3"><v-sheet class="my-0 white--text transparent fontItemListBox">{{
                      item.devSn }}</v-sheet></v-col>
                    <!-- <v-col class="my-0" cols="3"><v-sheet class="my-0 white--text transparent fontN2">{{ item.InstTime }}</v-sheet></v-col> -->
                    <v-col v-if="item.instLoc !== ''" class="my-0" cols="7"><v-sheet
                        class="my-0 white--text transparent nowrap fontItemListBox">{{ item.instLoc }}</v-sheet></v-col>
                    <v-col v-else class="my-0" cols="7"><v-sheet
                        class="my-0 white--text transparent nowrap fontItemListBox">{{ item.deliveryAddress
                        }}</v-sheet></v-col>
                    <v-col class="my-0 transparent text-right" cols="2"><v-btn width="20px" height="20px"
                        class="ma-1 mr-2 pa-1 text-capitalize fontItemListBoxBtn"
                        @click="onBtnDetail(item)">Detail</v-btn></v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-card v-else width="100%" height="100%" class="ma-0 transparent">
              <v-sheet class="mt-10 text-center transparent grey--text generalFonts">Data not available</v-sheet>
            </v-card>
          </v-list>
        </v-container>

      </v-card>

    </v-container>

    <!----------------------- Right Pane (Map) ----------------------------->
    <!-- <v-card width="35%" height="auto%" class="mt-2 ml-1 pa-1 rounded-xl" style="background-color: #282828; height: calc(100vh - 145px);">  -->
    <v-container fluid class="mt-2 m1-1 pa-1 rounded-lg" style="background-color: #282828; width: 35%;">

      <!-- Serial Number -->
      <v-sheet width="99%" height="35px" class="mt-0 py-0 pa-2 d-flex rounded-t-lg" style="background-color: #424141;">
        <v-sheet width="40%" height="100%" class="ml-2 mt-2 transparent white--text fontLocation1">Serial
          number</v-sheet>
        <v-sheet width="60%" height="100%" class="ml-0 pa-0 transparent white--text">
          <v-text-field dense dark type="input" regular width="100%" height="20px" v-model="keybuff" @keyup="onKeyUp"
            class="ml-4 mt-1 text-decloration-none transparent fontSN" :spellcheck="false" prepend-icon="mdi-magnify">
          </v-text-field>
        </v-sheet>
      </v-sheet>

      <!-- Location -->
      <v-sheet width="99%" height="35px" class="mt-2 px-2 py-0 d-flex" style="background-color: #424141;">
        <v-img :aspect-ratio="1" width="12px" height="20px" :src="locationIcon" class="ml-1 mt-1"></v-img>
        <v-sheet width="40%" height="100%" class="ml-4 mt-1 transparent white--text fontLocation1">Location :</v-sheet>
        <v-sheet width="60%" height="100%" class="ml-2 mt-2 transparent white--text fontLocation2">{{ map.location
          }}</v-sheet>
      </v-sheet>

      <!-- Maps embedded API -->
      <v-sheet width="100%" class="mt-3 pa-0" color="transparent" style="height: calc(100vh - 165px);">
        <iframe v-if="showMap" ref="refMap" width="99%" height="100%" frameborder="1" style="background-color: #37474F;"
          referrerpolicy="no-referrer-when-downgrade" class="rounded-xl">
        </iframe>
      </v-sheet>

    </v-container>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>

  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import cookies from '@/js/Cookie.js'
import DevStatistics from '@/components/HomePage/DevStatistics'
//import DevStatus from '@/components/HomePage/DevStatus'
import DoughNut3 from '@/components/HomePage/DoughNut3'
//import PlotlyLine from '@/components/HomePage/PlotlyLine'

import * as Tbl from '@/js/tables.js'
import * as Ut from '@/js/ut.js'
import * as Samples from '@/js/samples/data.js'
import * as TestInfo from '@/js/samples/TestInfo.js'
import * as Emergency from '@/js/samples/Emergency.js'

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiReload, mdiMagnify, mdiCheckboxIntermediateVariant } from '@mdi/js';

export default {
  name: 'HomePage',

  components: {
    SvgIcon, DevStatistics, DoughNut3
  },

  data() {
    return {
      pathReload: mdiReload,
      pathMagnify: mdiMagnify,
      heartwaveIcon: require("@/assets/Images/icons/heartwave1.png"),
      listboxIcon: require("@/assets/Images/icons/listbox1.png"),
      locationIcon: require("@/assets/Images/icons/location1.png"),
      // this.$config.apiBaseUrl
      //axiosPathGetDevInfoV1: '/client/api/v1/device',
      axiosPathGetDevInfoV1: '/client/api/v1/aedDeviceRecord',
      axiosPathLatestTestReport: '/api/v1/latesttestreports',
      axiosPathLatestEmergency: '/api/v1/latestemergreports',
      axiosPathEmergency: '/api/v1/emergreports',
      axiosPathTestReports: '/api/v1/testreports',
      axiosPathLatestEmergReport: '/api/v1/latestemergreports',
      connectionTimeout: 5000,
      axiosLoad: { "aeditems": false, "testrpts": false, "emergrpts": false },
      cancelTokenSource: null,
      // aeditems2: this.aeditems,

      aeditems2: [],
      dashbrd: {},
      testrpts: [],
      emergrpts: [],
      showMap: true,
      showProgress: false,
      snackBar: { "on": false, "color": "", "message": "" },
      snackTimeout: 3000,
      snackMsgLogout: "Login expired, please login again",
      tmrObj: {},

      statSize: { "width": '33%', "height": "100%" },
      statusW: '32%',
      statusH: '195',
      statusC: '#303030',
      keybuff: '',
      keybuffMax: 30,
      axiosRetry: 3,

      alarmStatus: { "total": 0, "normal": 0, "fault": 0, "warn": 0, "offline": 0 },
      alarmUnit: { "total": "pcs", "normal": "pcs", "fault": "pcs", "warn": "pcs", "offline": "pcs" },
      alarmRescue: { "text": "Rescue", "value": 0, unit: "   ", limit: 7 },
      // for list table (OBSOLETED 240904)
      aryPads: [
        { text: 'Expired', value: '', limit: 0 },
        { text: '≤ 3 months', value: '', limit: 90 },
        { text: '≤ 12 months', value: '', limit: 365 }
      ],
      aryBatt: [
        { text: 'Critical low', value: '', limit: 10 }, // <= 10%
        { text: '≤ 20%', value: '', limit: 20 },
        { text: '≤ 35%', value: '', limit: 35 }
      ],
      aryWarranty: [
        { text: 'Expired', value: '', limit: 0 },
        { text: '≤ 1 year', value: '', limit: 365 },
        { text: '> 1 year', value: '', limit: 365 }
      ],

      // for Doughnut chart, display only   ≤   ≥
      //   color in DevStatus.vue,  [0]=red, [1]=green, [2]=yellow
      aryPads2: [
        { label: 'Expired', data: 0 },
        { label: '≤ 3 months', data: 0 },
        { label: '> 3 months', data: 0 }
      ],
      aryBatt2: [
        { label: 'Critical low', data: 0 }, // <= 10%
        { label: '< 35%', data: 0 },
        { label: '≥ 35%', data: 0 }
      ],
      aryWarranty2: [
        { label: 'Expired', data: 0 },
        { label: '≤ 1 year', data: 0 },
        { label: '> 1 year', data: 0 }
      ],
      // messages appear in the middle of the Doughnut chart
      aryAnno: [
        { title: 'Warranty', message: 'device expired' },
        { title: 'Battery', message: 'device critical' },
        { title: 'Pads', message: 'pads expired' },
      ],

      triggerChart: 0,  

      faultAlert: { 'msg1': '', 'msg2': '' },
      warnAlert: { 'msg1': '', 'msg2': '' },
      offlineAlert: { 'msg1': '', 'msg2': '' },
      rescueTimes: '',
      rescueDevices: '',
      deviceTotal: '',
      selectRescue: "30", 
      itemRescue: ["1", "7", "30"],

      //aryPadsSt3mon: [],

      // ------- Google Map -----------
      // map modes:  place / view X / directions X / streetview X / search
      mapQueryPrefix: 'https://www.google.com/maps/embed/v1/place?key=' + this.$config.GOOGLE_MAP_API_KEY,
      map: { "search": "", "location": "", "selectedIndex": 0 },

      // ------- Plotly - Bar chart -----------
      //aryX: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      //aryY: [20, 14, 25, 16, 18, 22, 19, 15, 12, 16, 14, 17]
    }
  },

  methods: {
    onClickTotal() {
      //console.log('HomePage-->onClickTotal()');
      this.$router.push('/DevInfo');
    },
    onClickNormal() {
      //console.log('HomePage-->onClickNormal()');
      this.$router.push('/DevInfo');
    },
    onClickFault() {
      // console.log('HomePage-->onClickFault()');
      this.$router.push({ name: "EventManagement", params: { "tab": "Fault", "value": 0 } });
    },
    onClickWarning() {            // click on DevStatistics component
      this.$router.push({ name: "EventManagement", params: { "tab": "Warning", "value": 0 } });
    },
    onClickOffline() {
      // console.log('HomePage-->onClickOffline()');
      this.$router.push({ name: "EventManagement", params: { "tab": "Offline", "value": 0 } });
    },

    onClickWarranty2() {              // click on doughnut chart - warranty
      this.$router.push({ name: "DevInfo" });
    },
    onClickWarning2(xLabel, xidx) {    // click on doughnut chart - Battery
      this.$router.push({ name: "EventManagement", params: { "tab": "Warning", "value": 1 } });
      //console.log("##battery", xLabel, xidx);
    },
    onClickWarning3(xLabel, xidx) {    // click on doughnut chart - Pads
      this.$router.push({ name: "EventManagement", params: { "tab": "Warning", "value": 2 } });
      //console.log("##pads", xLabel, xidx);
    },

    onClickRescue() {
      // console.log('HomePage-->onClickRescue()');
      this.$router.push({ name: "EventManagement", params: { "tab": "Rescue", "value": 0 } });
    },

    async onChangeRescue(xitem) {
      // parameter: xitem.target.selectedIndex    type: number
      //console.log("##onChangeRescue", xitem.target.selectedIndex, typeof(xitem.target.selectedIndex));  //both types are number
      if (xitem.target.selectedIndex === undefined || xitem.target.selectedIndex === null) return;
      try {
        let flag = false;
        switch (xitem.target.selectedIndex) {
          case 0: this.selectRescue = "1"; flag = true; break;
          case 1: this.selectRescue = "7"; flag = true; break;
          case 2: this.selectRescue = "30"; flag = true; break;
          default: flag = false; break;
        }
        if (flag) {
          let vdata = await this.axiosGetDashboard2();      // simsrv not implement this yet 241009
          //console.log("##onChangeRescue", this.selectRescue, vdata);
          if (vdata !== undefined && vdata !== null) {
            this.dashbrd = vdata;
            this.setDashBoard();
            this.processEmergencyReport(this.emergrpts);
          }
        }
      }
      catch (err) { return; }
    },

    // DESCRIPTION:  handle button "Detail" : xitem = {InstTIme, deliveryAddress, devSn, maploc}
    async onBtnDetail(xitem) {
      if (xitem === null || xitem.devSn === "") return;
      await this.getProcessedItem(xitem.devSn);
      if (this.aeditems === undefined || this.aeditems === null || this.aeditems.length === 0) return;
      /* let idx=0;
      for(idx=0; idx < this.aeditems.length; idx++) {
        if (this.aeditems[idx].devSn === xitem.devSn) break;
      }  */
      //if (this.aeditems.length > 1) console.log("##onBtnDetail1", this.aeditems.length);
      let idx = 0;
      // here**
      if (idx < this.aeditems.length) {
        let xobj = this.aeditems[idx];
        //let xobj = await this.getProcessedItem(xitem.devSn);
        //await this.getProcessedItem(xitem.devSn);   //debug*
        this.$root.$emit('App_Tab', "/DevInfo");  // change tab in top bar
        this.$router.push({ name: "DevInfoDetail", params: { xobj }, query: { sn: xobj.devSn } });
      }
      //this.$root.$emit('App_Tab', "/DevInfo");  // change tab in top bar
      //this.$router.push({name: "DevInfoDetail", params: {xobj}, query:{sn:xobj.devSn}});
    },

    // OBSOLETED since rev1.55
    onBtnRefresh() {
      if (this.showProgress) return;  //not allow to refresh if last refresh not finished
      this.loadTables();
    },

    // DESCRIPTION:  set map location, data provided by Calvin's dashboard interface - DeviceList
    // xitem: {devSn, InstTime, deliveryAddress, maploc}         // null, undefined
    getLocation(xidx, xitem) {
      if (xitem.maploc !== null && xitem.maploc !== undefined && xitem.maploc !== '' && xitem.maploc !== '/') {
        this.setMapAddr(xitem.maploc);
        this.map.location = xitem.maploc;
      } else {
        if (xitem.instLoc !== null && xitem.instLoc !== undefined && xitem.instLoc !== '' && xitem.instLoc !== '/') {
          this.setMapAddr(xitem.instLoc);
          this.map.location = xitem.instLoc;
        } else {
          this.setMapAddr("Hong Kong");
          this.map.location = "";
        }
      }
      this.map.selectedIndex = xidx;
    },

    isFieldEmpty(xstr) {
      if (xstr === undefined || xstr === null || xstr === "" || xstr === "/") {
        return null;
      }
      return xstr;
    },

    getColor(xidx) {
      if (xidx === this.map.selectedIndex) {
        return "yellow";
      }
      return "transparent";
    },

    onMapSearch(xstr) {
      this.map.search = xstr;
    },

    setMapAddr(xaddr) {
      if (xaddr === null) {
        return;
      }
      // this.$refs.refMap.src = this.mapQueryPrefix + "&q=New+York";
      // this.$refs.refMap.src = this.mapQueryPrefix + "&q=22.34167,114.19387";
      // &zoom=22, kai tak 22.33060,114.19954 
      if (this.$refs.refMap !== undefined) {
        // this.$refs.refMap.src = this.mapQueryPrefix + "&q=" + xaddr;
        this.$refs.refMap.src = this.mapQueryPrefix + "&q=" + xaddr;
      }
    },

    snackStart(xmsg, xcolor) {
      if (this.snackBar.on) {
        this.snackStop();
      }
      this.snackBar.message = xmsg;
      this.snackBar.color = xcolor;
      this.snackBar.on = true;
      this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
    },

    snackTimerEvent() {
      this.snackBar.on = false;
      this.tmrObj = null;
      if (this.snackBar.message === this.snackMsgLogout) {
        this.snackBar.message = '';
        this.$root.$emit('App_Logout', "115");
      }
      this.snackBar.message = '';
    },

    snackStop() {
      clearTimeout(this.tmrObj);
      this.snackBar.message = '';
      this.snackBar.on = false;
      this.tmrObj = null;
    },

    onKeyUp(evn) {
      if (this.keybuff.length >= this.keybuffMax + 1) {
        this.keybuff = this.keybuff.substring(0, this.keybuffMax);
        return;
      }
      if (evn.keyCode === 13) {
        this.map.search = this.keybuff.trim();
        if (this.map.search.length > 0) {
          for (let idx = 0; idx < this.aeditems2.length; idx++) {
            if (this.aeditems2[idx].devSn.toUpperCase() === this.map.search) {
              this.map.selectedIndex = idx;
              if (this.aeditems2[idx].maploc !== null && this.aeditems2[idx].maploc !== "" && this.aeditems2[idx].maploc !== "/") {
                this.map.location = this.aeditems2[idx].maploc;
                this.setMapAddr(this.map.location);
              } else if (this.aeditems2[idx].instLoc !== null && this.aeditems2[idx].instLoc !== "" && this.aeditems2[idx].instLoc !== "/") {
                this.map.location = this.aeditems2[idx].instLoc;  //241025 1.55c
                this.setMapAddr(this.map.location);
              } else {
                this.map.location = "";
                this.setMapAddr("test1 site");
              }
              return;
            }
          }
        }
        return;
      }
      this.keybuff = this.keybuff.toUpperCase();
    },

    // DESCRIPTION:  expiry date - date now, return <= 0 if expired
    getDateDiff(xdate) {
      return (new Date(xdate) - new Date()) / (1000 * 3600 * 24);
    },

    findSnFromDeviceReport(xdevSn) {
      for (let idx = 0; idx < this.aeditems.length; idx++) {
        if (this.aeditems[idx].devSn === xdevSn) return this.aeditems[idx];
      }
      return null;
    },


    // ----------------------------------------------------------------------
    //              [Axios] [axios]
    // 
    // ----------------------------------------------------------------------

    // ---------------------------------------------
    // DESCRIPTION:  Get deivce info, V1 (with token)
    async axiosGetDashboard() {                   // OBSOLETED
      const CancelToken = axios.CancelToken;
      this.cancelTokenSource = CancelToken.source();
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + '/dashboard',
        timeout: 5000,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        },
        cancelToken: this.cancelTokenSource.token,
      }

      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //this.setDashBoard(resp.data.data);
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetDashboard(1) error: axios data request fail');
          }
        } else {
          console.log('#axiosGetDashboard(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetDashboard(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
          // this.$root.$emit('App_Logout', "115");
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },


    // ---------------------------------------------
    // DESCRIPTION:  Get Dashboard v2, with /v2/dashboard?rescueInterval=30
    async axiosGetDashboard2() {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + '/v2/dashboard?rescueInterval=' + this.selectRescue,
        //timeout: 3000,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //this.setDashBoard(resp.data.data);
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetDashboard2(1) error: axios data request fail');
          }
        } else {
          console.log('#axiosGetDashboard2(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetDashboard2(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
          // this.$root.$emit('App_Logout', "115");
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  Latest Test Report 
    async axiosGetTestReportLatest() {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestTestReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetTestReportLatest(1) error: axios data request fail');
          }
        } else {
          console.log('#axiosGetTestReportLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetTestReportLatest(3) error: ' + error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  All Emergency report shown in Timeline
    async axiosGetEmergency() {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathEmergency,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //Ut.copyArray(this.emergrpts, resp.data.data);
              //this.processEmergencyReportLatest();
              return resp.data.data;
            } else {
              this.rescueTimes = "0";
            }
          } else {
            console.log('#axiosGetEmergency(1) error: axios data request fail');
          }
        } else {
          console.log('#axiosGetEmergency(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetEmergency(3) error: ' + error.message);
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  Get deivce info, V1 (with token)
    async axiosGetDevInfoV1() {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathGetDevInfoV1,
        //timeout: 3000,
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetDevInfoV1(1) error: axios data request fail');
          }
        } else {
          console.log('#axiosGetDevInfoV1(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetDevInfoV1(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  
    async axiosGetEmergencyLatest() {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestEmergReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            return resp.data.data;
          }
        } else {
          console.log('#eventMgmt-axiosGetEmergencyLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#eventMgmt-axiosGetEmergencyLatest(3) error: ' + error.message);
      }
      return null;
    },

    // --------------------- Sn -----------------------------------
    // --------------------------------------------- here**
    // DESCRIPTION:  Get deivce info Sn, V1 (with token)
    async axiosGetDevInfoV1Sn(xSn) {
      if (xSn === undefined || xSn === null || xSn === "") return null;
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathGetDevInfoV1 + "/" + xSn,
        timeout: 2000,
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              return resp.data.data;
            }
          }
        } else {
          console.log('#axiosGetDevInfoV1(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetDevInfoV1(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  Latest Test Report Sn
    async axiosGetTestReportLatestSn(xSn) {
      if (xSn === undefined || xSn === null || xSn === "") return null;
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestTestReport + "/" + xSn,
        timeout: 2000,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              return resp.data.data;
            }
          }
        } else {
          console.log('#axiosGetTestReportLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetTestReportLatest(3) error: ' + error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },


    //DESCRIPTION:  download DevInfo(Sn) + TestReport(Sn) and process
    async getProcessedItem(xSn) {             // here**
      let vData1 = await this.axiosGetDevInfoV1Sn(xSn);
      this.storeToAedItems(vData1);
      let vData2 = await this.axiosGetTestReportLatestSn(xSn);
      await this.processTestReportLatest(vData2);
      //console.log("####",this.aeditems);
      //console.log("####",vData1);
    },




    // ----------------------------------------------------------------------
    //              [Process reports]
    // 
    // ----------------------------------------------------------------------

    // --------------------------------------------- [setDashBoard]
    // DESCRIPTION:  display Dashboard data, after axios 
    async setDashBoard() {
      let str = "";
      //this.dashbrd = JSON.parse(JSON.stringify(xdata));  //copy dashboard data

      // debug ------------------------------------------------- debug data
      // (1) PadsShelfLife warning
      /* this.dashbrd.warning.quantity++;
      this.dashbrd.warning.warnSN.push("AE9-44009715");
      this.dashbrd.PadsShelfLife.no.st3mon++;
      this.dashbrd.PadsShelfLife.details.st3mon.push(
          {"sn": "AE9-44009715", "padExpiry": "2024-10-01"}
      );  */

      // (2) batt low:  critical / st20 / st35
      /* this.dashbrd.warning.quantity++;
      this.dashbrd.warning.warnSN.push("AE9-44009715");
      this.dashbrd.BatteryCapacity.no.critical++;
      this.dashbrd.BatteryCapacity.details.critical.push( 
        {"sn": "AE9-44009715", "batterySN": "HY86204200086", "batteryPercent": 20}
      ); */

      // (3) Fault:  
      /* this.dashbrd.Fault.faultSN.push("AE9-44009715");
      this.dashbrd.Fault.quantity++;  */

      // (4) Rescue:  
      //this.dashbrd.rescue.rescueSN.push("AE9-44009715");
      //this.dashbrd.rescue.totalRescueTime++;  

      //console.log("##Dashboard", this.dashbrd);

      // --------- Top row ----------------
      // (1) Fault alarm
      if (this.dashbrd.Fault.faultSN !== undefined && this.dashbrd.Fault.faultSN !== null) {
        this.alarmStatus.fault = this.dashbrd.Fault.faultSN.length;
      }
      // (2) Warning alarm
      this.alarmStatus.warn = this.dashbrd.warning.quantity;
      // (3) Offline alarm
      this.alarmStatus.offline = this.dashbrd.Offline.quantity;
      // test*
      //this.alarmStatus.fault = 0; this.alarmStatus.warn = 0; this.alarmStatus.offline = 0;  //debugging
      // (4) Rescue alarm
      Ut.clearArray(this.emergrpts);
      if (this.dashbrd.rescue.rescueSN !== undefined && this.dashbrd.rescue.rescueSN !== null) {
        this.alarmRescue.value = this.dashbrd.rescue.rescueSN.length;
        this.rescueDevices = this.alarmRescue.value + ' ';
      } else {
        this.alarmRescue.value = 0;
        this.rescueDevices = '0';
      }
      this.rescueTimes = this.dashbrd.rescue.totalRescueTime.toString();
      if (this.dashbrd.rescue.rescue !== undefined && this.dashbrd.rescue.rescue !== null) {
        for (let idx = 0; idx < this.dashbrd.rescue.rescue.length; idx++) {
          //this.emergrpts.push(this.dashbrd.rescue.rescue[idx]);
          let vObj = {
            "sn": this.dashbrd.rescue.rescue[idx].sn,
            "datetime": moment(this.dashbrd.rescue.rescue[idx].datetime).format("YYYY-MM-DD HH:mm:ss"),
            //"InstLoc": ""
          }
          this.emergrpts.push(vObj);
        }
      }

      // save warning and fault info from Master Record 
      Ut.writePadsSt3mon(this.dashbrd.PadsShelfLife.details.st3mon);
      Ut.writePadsExpire(this.dashbrd.PadsShelfLife.details.Expired);
      Ut.writeBatt35(this.dashbrd.BatteryCapacity.details.st35, this.dashbrd.BatteryCapacity.details.st20, this.dashbrd.BatteryCapacity.details.critical);
      //Ut.writeAryFault(this.dashbrd.Fault.faultSN);
      Ut.writeAryFault(this.dashbrd.Fault.details);
      Ut.writeAryRescue(this.dashbrd.rescue.rescueSN);


      // --------- 2nd row ----------------
      // (1) Remaining Warranty
      str = JSON.stringify(this.dashbrd.remainingWarranty.no);
      str = str.replace("1yrs", "oneyr");
      let vObj = JSON.parse(str);
      /* this.aryWarranty[0].value = vObj.Expired + " ";
      this.aryWarranty[1].value = vObj.oneyr + " ";
      this.aryWarranty[2].value = vObj.Large1yrs + " "; */
      this.aryWarranty2[0].data = vObj.Expired;
      this.aryWarranty2[1].data = vObj.oneyr;
      this.aryWarranty2[2].data = vObj.Large1yrs;
      if (this.aryWarranty2[0].data > 0) this.aryAnno[0].message = vObj.Expired + " devices warranty#expired";
      else if (this.aryWarranty2[1].data > 0) this.aryAnno[0].message = vObj.oneyr + " devices warranty#expired within 1 year";
      else this.aryAnno[0].message = "Normal";
      //console.log("##dash-warranty", this.aryWarranty2[0].data, this.aryWarranty2[1].data, this.aryWarranty2[2].data);

      // (2) Battery Capacity
      /* this.aryBatt[0].value = this.dashbrd.BatteryCapacity.no.critical + " pcs";
      this.aryBatt[1].value = this.dashbrd.BatteryCapacity.no.st20 + " pcs";
      this.aryBatt[2].value = this.dashbrd.BatteryCapacity.no.st35 + " pcs"; */
      this.aryBatt2[0].data = this.dashbrd.BatteryCapacity.no.critical;
      this.aryBatt2[1].data = this.dashbrd.BatteryCapacity.no.st35 + this.dashbrd.BatteryCapacity.no.st20;  //241104
      this.aryBatt2[2].data = this.dashbrd.BatteryCapacity.no.lt35;
      if (this.aryBatt2[0].data > 0) this.aryAnno[1].message = this.aryBatt2[0].data + " devices need to#change battery";
      else if (this.aryBatt2[1].data > 0) this.aryAnno[1].message = this.aryBatt2[1].data + " devices battery low";
      else this.aryAnno[1].message = "Normal";
      //console.log("##dash-batter", this.aryBatt2[0].data, this.aryBatt2[1].data, this.aryBatt2[2].data);

      // (3) Pads Shelf Life
      /* this.aryPads[0].value = this.dashbrd.PadsShelfLife.no.Expired + " ";
      this.aryPads[1].value = this.dashbrd.PadsShelfLife.no.st3mon + " ";
      this.aryPads[2].value = this.dashbrd.PadsShelfLife.no.st12mon + " "; */
      this.aryPads2[0].data = this.dashbrd.PadsShelfLife.no.Expired;
      this.aryPads2[1].data = this.dashbrd.PadsShelfLife.no.st3mon;
      this.aryPads2[2].data = this.dashbrd.PadsShelfLife.no.lt12mon + this.dashbrd.PadsShelfLife.no.st12mon;  // 241025 1.55c
      if (this.aryPads2[0].data > 0) this.aryAnno[2].message = this.aryPads2[0].data + " devices need to#change pads";
      else if (this.aryPads2[1].data > 0) this.aryAnno[2].message = this.aryPads2[1].data + " pads going to#expire";
      else this.aryAnno[2].message = "Normal";
      //console.log("##dash-pads", this.aryPads2[0].data, this.aryPads2[1].data, this.aryPads2[2].data);

      // --------- 3rd row ----------------
      Ut.clearArray(this.aeditems2);
      if (this.dashbrd.DeviceList !== undefined && this.dashbrd.DeviceList !== null) {
        for (let idx = 0; idx < this.dashbrd.DeviceList.length; idx++) {

          let vObj = {
            "devSn": this.dashbrd.DeviceList[idx].sn.trim(),
            "InstTime": moment(this.dashbrd.DeviceList[idx].instTime).format("YYYY-MM-DD"),
            "deliveryAddress": "", "maploc": "", "instLoc": ""
          };

          if (vObj.InstTime === "Invalid date") vObj.InstTime = "/";
          str = this.dashbrd.DeviceList[idx].maploc;
          if (str !== null && str !== undefined && str !== "") vObj.maploc = str.trim();
          else vObj.maploc = "/";
          str = this.dashbrd.DeviceList[idx].instLoc
          if (str !== null && str !== undefined && str !== "") vObj.instLoc = str.trim();
          else vObj.instLoc = "/";

          this.aeditems2.push(vObj);
        }
      }

      // go to 1st location after loading
      if (this.aeditems2 !== undefined && this.aeditems2 !== null && this.aeditems2.length > 0) {
        this.getLocation(0, this.aeditems2[0]);
      }

      // --------- Data Processing ----------------
      this.showAlert();
      //this.triggerChart++;   // update 3 doughnut chart
    },
    /*  this.dashbrd.DeviceList[idx] :
          sn / model / batteryPercent / WarrentyDue / deliveryAddress / maploc / 
          instLoc / instTime / deliveryDate /  
          "pad": [ { "Qty": 1, "lot": "230725-4306", "type": "MR60", "ExpiryDate": "2026-06" } ]
    */

    // DESCRIPTION:  show alert message in the Fault/Warning/Offline boxes
    showAlert() {
      if (this.alarmStatus.fault > 0) {
        this.faultAlert.msg2 = "AED error";
        this.faultAlert.msg1 = "Please contact us";
      }
      else {
        this.faultAlert.msg2 = "";
        this.faultAlert.msg1 = "";
      }

      if (this.alarmStatus.warn > 0) this.warnAlert.msg1 = "Check your event management";
      else this.warnAlert.msg1 = "";

      if (this.alarmStatus.offline > 0) this.offlineAlert.msg1 = "Check your event management";
      else this.offlineAlert.msg1 = "";

      if (this.aeditems2 !== undefined && this.aeditems2 !== null) {
        this.deviceTotal = this.aeditems2.length + ' ';
      } else {
        this.deviceTotal = "0";
      }
    },

    // ---------------------------------------------
    // DESCRIPTION:  check if testinfo - padsLife & batt level should overwrite dashboard data,  OBSOLETED 241010
    async TestReportToDashboard() {
      if (this.testrpts === undefined || this.testrpts === null) return;
      let vT = {};
      let expireDate = "", sPads = "", sn = "";
      let expired = 0, battTot = 0, battRes = 0, battPerc = 0;

      for (let idx = 0; idx < this.testrpts.length; idx++) {
        vT = this.testrpts[idx];
        sn = vT.sn;

        //(A) Testinfo-Pads Expiry Date
        expireDate = vT.testitems.padsExpiringDate.result;
        expired = vT.testitems.padsExpiried.result;
        if (expireDate === null || expireDate === "") continue; //expiry Date absent
        sPads = Ut.getPadsStatus(expired, expireDate); //"3", "12", "Expired", "Normal"

        //(B) Testinfo-Battery Level
        battPerc = 0;
        battTot = vT.testitems.batTotalCapacity.result;
        battRes = vT.testitems.batResidualCapacity.result;
        if (battTot > 0) {
          battPerc = Math.floor((battRes * 100) / battTot);
          battPerc = Math.abs(battPerc);
        }

        //(C) Dashboard-PadsShelfLife
        if (this.dashbrd.PadsShelfLife.details.Expired !== undefined && this.dashbrd.PadsShelfLife.no.Expired > 0) {  //(C1) pads Expired
          for (let idx = 0; idx < this.dashbrd.PadsShelfLife.details.Expired.length; idx++) {

            if (sn === this.dashbrd.PadsShelfLife.details.Expired[idx].sn) {
              if (sPads !== "Expired") {
                if (sPads === "3") {
                  this.dashbrd.PadsShelfLife.no.st3mon++;
                  this.dashbrd.PadsShelfLife.details.st3mon.push(this.dashbrd.PadsShelfLife.details.Expired[idx]);
                } else if (sPads === "12") {
                  this.dashbrd.PadsShelfLife.no.st12mon++;
                  this.dashbrd.PadsShelfLife.details.st12mon.push(this.dashbrd.PadsShelfLife.details.Expired[idx]);
                }
                this.dashbrd.PadsShelfLife.no.Expired--;
                this.dashbrd.PadsShelfLife.details.Expired[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.PadsShelfLife.details.st3mon !== undefined && this.dashbrd.PadsShelfLife.no.st3mon > 0) {  //(C2) pads st3mon
          for (let idx = 0; idx < this.dashbrd.PadsShelfLife.details.st3mon.length; idx++) {
            if (sn === this.dashbrd.PadsShelfLife.details.st3mon[idx].sn) {
              if (sPads !== "3") {
                if (sPads === "Expired") {
                  this.dashbrd.PadsShelfLife.no.Expired++;
                  this.dashbrd.PadsShelfLife.details.Expired.push(this.dashbrd.PadsShelfLife.details.st3mon[idx]);
                } else if (sPads === "12") {
                  this.dashbrd.PadsShelfLife.no.st12mon++;
                  this.dashbrd.PadsShelfLife.details.st12mon.push(this.dashbrd.PadsShelfLife.details.st3mon[idx]);
                }
                this.dashbrd.PadsShelfLife.no.st3mon--;
                this.dashbrd.PadsShelfLife.details.st3mon[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.PadsShelfLife.details.st12mon !== undefined && this.dashbrd.PadsShelfLife.no.st12mon > 0) {  //(C3) pads st12mon
          for (let idx = 0; idx < this.dashbrd.PadsShelfLife.details.st12mon.length; idx++) {
            if (sn === this.dashbrd.PadsShelfLife.details.st12mon[idx].sn) {
              if (sPads !== "12") {
                if (sPads === "Expired") {
                  this.dashbrd.PadsShelfLife.no.Expired++;
                  this.dashbrd.PadsShelfLife.details.Expired.push(this.dashbrd.PadsShelfLife.details.st12mon[idx]);
                } else if (sPads === "3") {
                  this.dashbrd.PadsShelfLife.no.st3mon++;
                  this.dashbrd.PadsShelfLife.details.st3mon.push(this.dashbrd.PadsShelfLife.details.st12mon[idx]);
                }
                this.dashbrd.PadsShelfLife.no.st12mon--;
                this.dashbrd.PadsShelfLife.details.st12mon[idx].sn = "delete";
              }
            }
          }
        }
        this.dashbrd.PadsShelfLife.details.Expired = this.dashbrd.PadsShelfLife.details.Expired.filter(e => e.sn !== 'delete');
        this.dashbrd.PadsShelfLife.details.st3mon = this.dashbrd.PadsShelfLife.details.st3mon.filter(e => e.sn !== 'delete');
        this.dashbrd.PadsShelfLife.details.st12mon = this.dashbrd.PadsShelfLife.details.st12mon.filter(e => e.sn !== 'delete');
        // console.log("##chk111", this.dashbrd.PadsShelfLife.details.Expired);

        let batt_critical = Ut.Limits.batt.value0;
        let batt_st20 = 20;
        let batt_st35 = Ut.Limits.batt.value2;
        //(D) Dashboard-Battery
        if (this.dashbrd.BatteryCapacity.details.critical !== undefined && this.dashbrd.BatteryCapacity.no.critical > 0) {  //(D1) batt critical
          for (let idx = 0; idx < this.dashbrd.BatteryCapacity.details.critical.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.critical[idx].sn) {
              if (battPerc > batt_critical) {
                if (battPerc > batt_critical && battPerc <= batt_st20) {
                  this.dashbrd.BatteryCapacity.no.st20++;
                  this.dashbrd.BatteryCapacity.details.st20.push(this.dashbrd.BatteryCapacity.details.critical[idx]);
                } else if (battPerc <= batt_st35) {
                  this.dashbrd.BatteryCapacity.no.st35++;
                  this.dashbrd.BatteryCapacity.details.st35.push(this.dashbrd.BatteryCapacity.details.critical[idx]);
                }
                this.dashbrd.BatteryCapacity.no.critical--;
                this.dashbrd.BatteryCapacity.details.critical[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.BatteryCapacity.details.st20 !== undefined && this.dashbrd.BatteryCapacity.no.st20 > 0) {  //(D1) batt != 20
          for (let idx = 0; idx < this.dashbrd.BatteryCapacity.details.st20.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.st20[idx].sn) {
              if (battPerc <= batt_critical || (battPerc > batt_st20 && battPerc <= batt_st35)) {
                if (battPerc <= batt_critical) {
                  this.dashbrd.BatteryCapacity.no.critical++;
                  this.dashbrd.BatteryCapacity.details.critical.push(this.dashbrd.BatteryCapacity.details.st20[idx]);
                } else if (battPerc > batt_st20 && battPerc <= batt_st35) {
                  this.dashbrd.BatteryCapacity.no.st35++;
                  this.dashbrd.BatteryCapacity.details.st35.push(this.dashbrd.BatteryCapacity.details.st20[idx]);
                }
                this.dashbrd.BatteryCapacity.no.st20--;
                this.dashbrd.BatteryCapacity.details.st20[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.BatteryCapacity.details.st35 !== undefined && this.dashbrd.BatteryCapacity.no.st35 > 0) {  //(D2) batt != 35
          for (let idx = 0; idx < this.dashbrd.BatteryCapacity.details.st35.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.st35[idx].sn) {
              if (battPerc <= batt_st20 || battPerc > batt_st35) {
                if (battPerc <= batt_critical) {
                  this.dashbrd.BatteryCapacity.no.batt_critical++;
                  this.dashbrd.BatteryCapacity.details.critical.push(this.dashbrd.BatteryCapacity.details.st35[idx]);
                }
                else if (battPerc <= batt_st20) {
                  this.dashbrd.BatteryCapacity.no.st20++;
                  this.dashbrd.BatteryCapacity.details.st20.push(this.dashbrd.BatteryCapacity.details.st35[idx]);
                }
                this.dashbrd.BatteryCapacity.no.st35--;
                this.dashbrd.BatteryCapacity.details.st35[idx].sn = "delete";
              }
            }
          }
        }
        this.dashbrd.BatteryCapacity.details.critical = this.dashbrd.BatteryCapacity.details.critical.filter(e => e.sn !== 'delete');
        this.dashbrd.BatteryCapacity.details.st20 = this.dashbrd.BatteryCapacity.details.st20.filter(e => e.sn !== 'delete');
        this.dashbrd.BatteryCapacity.details.st35 = this.dashbrd.BatteryCapacity.details.st35.filter(e => e.sn !== 'delete');

      } // end of for()

    },


    // ---------------------------------------------
    // DESCRIPTION:  convert axios format to devInfo format  
    storeToAedItems(xAry) {
      if (xAry === undefined || xAry === null) return;
      Ut.clearArray(this.aeditems);
      let str = "";
      let j2 = 0;
      for (let idx = 0; idx < xAry.length; idx++) {
        let vObj2 = xAry[idx];       // get dev sn and search its most recent report
        if (vObj2.isDeleted) continue;
        let vObj = Tbl.createDevInfo();   // devinfo obj template
        vObj.id = vObj2.id
        vObj.devSn = vObj2.sn;
        vObj.devModel = vObj2.model;
        vObj.battCap = "/";
        //vObj.InstLoc       = vObj2.maploc;
        if (vObj2.instLoc !== null || vObj2.instLoc !== "") vObj.InstLoc = vObj2.instLoc;
        vObj.CustName = vObj2.organization;
        vObj.WarrantyStatus = vObj2.warrentyMonths;
        if (vObj2.warrentyMonths === null) vObj.WarrantyStatus = "/";
        //vObj.AssetSn      = vObj2.assetsn;
        vObj.DevStatus = vObj2.devstatus;
        if (vObj.DevStatus === null || vObj.DevStatus === "") vObj.DevStatus = "/";
        vObj.PadStatus = "/";
        if (vObj2.instTime !== null) {
          //vObj.InstTime      = moment(vObj2.instTime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.InstTime = moment(vObj2.instTime).format("YYYY-MM-DD");
        } else vObj.InstTime = "/";

        if (vObj2.testperiod !== null && vObj2.testperiod !== "") {
          vObj.DevSelfTestPeriod = vObj2.testperiod;
        }
        else vObj.DevSelfTestPeriod = "/";
        if (vObj2.reportperiod !== null && vObj2.reportperiod !== "") {
          if (vObj2.reportperiod === 1) vObj.SelfTestSendPeriod = "Daily";
          else if (vObj2.reportperiod === 7) vObj.SelfTestSendPeriod = "Weekly";
          else vObj.SelfTestSendPeriod = "/";
        }
        else vObj.SelfTestSendPeriod = "/";

        vObj.WirelessStrength = "/";
        if (vObj2.networktype === null || vObj2.networktype === "") vObj.NetworkType = "/";
        else vObj.NetworkType = vObj2.networktype;
        if (vObj.NetworkType === "") vObj.NetworkType = "/";
        vObj.DevAdmin = vObj2.adminName;
        //vObj.ExtendedWarranty  = '';
        vObj.LastReportUploadTime = "/";
        //vObj.RescueTimes       = '';
        vObj.WarrantyExpireDate = moment(vObj2.warrentyDue).format("YYYY-MM-DD");
        if (vObj.WarrantyExpireDate.indexOf("Invalid") >= 0) vObj.WarrantyExpireDate = "/";
        //vObj.ICCID             = vObj2.iccid;
        vObj.LocAttr = vObj2.locattr;
        if (vObj.LocAttr === null || vObj.LocAttr === "") vObj.LocAttr = "/";
        //vObj.Address         = vObj2.deliveryAddress;
        if (vObj2.maploc === null || vObj2.maploc === "") vObj.MapLoc = "/";
        else vObj.MapLoc = vObj2.maploc;
        if (vObj2.batterySN === null || vObj2.batterySN === "") vObj.battSn = "/";
        else vObj.battSn = vObj2.batterySN;
        vObj.DeliveryAddr = vObj2.deliveryAddress;
        if (vObj2.instLoc === null || vObj2.instLoc === "") vObj.InstLoc = "/";
        else vObj.InstLoc = vObj2.instLoc;
        // Pads
        str = "";
        if ("pad" in vObj2 && vObj2.pad !== undefined && vObj2.pad !== null && vObj2.pad.length > 0) {
          for (j2 = 0; j2 < vObj2.pad.length; j2++) {
            str = str + vObj2.pad[j2].type + ",";
            vObj.PadsShelfLife = vObj2.pad[j2].ExpiryDate; //last always overwrite first
            if (vObj2.pad[j2].lot !== "/") vObj.PadLot = vObj2.pad[j2].lot;
          }
        }
        if (str.trim() !== "") vObj.PadType = str;
        else vObj.PadType = "/";

        // PIC:  error in reading PIC.length for zero length array
        if ("PIC" in vObj2 && vObj2.PIC !== undefined && vObj2.PIC !== null && vObj2.PIC.length > 0) {
          for (let idx = 0; idx < vObj2.PIC.length; idx++) {
            vObj.Pic.push(vObj2.PIC[idx]);
          }
        }

        // Preventive Maintenance (PM) : totalPM(num), PMrecords(array), 
        // PMrecords = [{"time": 2206, "report": "RC00416"}, {"time": 2306, "report": "RD00328"}, {"time": 2406, "report": "RE00387"}]
        if ("PMrecords" in vObj2 && vObj2.PMrecords !== undefined && vObj2.PMrecords !== null && vObj2.PMrecords.length > 0) {
          vObj.LastPM = Math.max.apply(Math, vObj2.PMrecords.map(function (xvar) { return xvar.time; }));
        }
        else vObj.LastPM = "/";

        vObj.show = true;

        //if (idx === 0) console.log("##devList-1", vObj, vObj2);  //debug
        //if (vObj2.sn === "AE7-3B095116") console.log(vObj2);  //debug
        this.aeditems.push(vObj);
      }
    },

    // ---------------------------------------------
    // DESCRIPTION:  process latest test report: total count statistics   (OBSOLETED)
    // main.js\testrpts[]  contains the latest test reports
    async processTestReportLatest(xAry) {
      if (xAry === undefined || xAry === null || xAry.length === 0) {
        //console.log('#HomeP-err001 *** testrpts[] empty ***');
        return;
      }

      let sOffline = "", sPads = "", sDev = "Normal", str = "";
      let iRescue = 0;
      let vT = {}, vDev = {};
      let expireDate = "";
      let expired = 0, battTot = 0, battRes = 0, val = 0, val2 = 0;

      for (let idx = 0; idx < xAry.length; idx++) {
        vT = xAry[idx];

        // seach corresponding device object from test report sn
        if (this.aeditems.length == 0) continue;
        vDev = this.findSnFromDeviceReport(vT.sn);
        if (vDev === null) continue;
        vDev.testreport = 1;    //testreport available for this sn

        // (0) Date format fix:  2024-04-03T08:07:44.000Z to YYYY-MM-DD HH:MM:SS
        xAry[idx].datetime = moment(vT.datetime).format("YYYY-MM-DD HH:mm:ss");
        //this.testrpts[idx].testtime = moment(vT.testtime).format("YYYY-MM-DD HH:mm:ss");
        xAry[idx].testtime = moment.parseZone(vT.testtime).local(true).format("YYYY-MM-DD HH:mm:ss");

        //(1) Fault
        sDev = "Normal";
        if (vT.testerrorcode !== "/" && vT.testerrorcode !== "") {
          sDev = "Fault";
        } else {
          if (xAry[idx].testresult === 0) sDev = "Fault";
          if (xAry[idx].relatedresult === 0) sDev = "Fault";
        }

        //(2) Warning, pads expired + expired in 3 months
        expireDate = vT.testitems.padsExpiringDate.result;  //return YYYY-MM-DD  or  ""
        expired = vT.testitems.padsExpiried.result;         //0 or 1
        if (expireDate === null || expireDate === "") {
          sPads = "/";
          vDev.PadsShelfLife = "/";
        }
        else {
          vDev.PadsShelfLife = expireDate;
          sPads = Ut.getPadsStatus(expired, expireDate);      //"3", "12", "Expired", "Normal"
          //if (sPads === "3" || sPads === "12") {
          if (sPads === "3") {
            sPads = "Expired Soon";
            if (sDev === "Normal") sDev = "Warning";
          } else if (sPads === "12") {
            sPads = "Expired in 12 months";
          } else if (sPads === "Expired") {
            sDev = "Fault"; sPads = "Expired";
          }
        }
        // finally check padsExpiried, padsExpireSoon flags, where expiry date is absent (MR60)
        if (sPads === "" || sPads === "Normal" || sPads === "/") {
          if (vT.testitems.padsExpiried.result === 1) {
            sPads = "Expired";
            if (sDev !== "Fault") sDev = "Fault";
          } else {
            if (vT.testitems.padsExpireSoon.result === 1) {
              sPads = "Expired Soon";
              if (sDev !== "Fault") sDev = "Warning";
            }
          }
        }

        battTot = vT.testitems.batTotalCapacity.result;
        battRes = vT.testitems.batResidualCapacity.result;
        //console.log('##testrpt: ', vT.sn, battRes, battTot);  
        if (battTot > 0) {
          val = Math.floor((battRes * 100) / battTot);
          val = Math.abs(val);
          vDev.battCap = val + "%";
          if (val <= Ut.Limits.batt.value2 && sDev === "Normal") sDev = "Warning";
          if (val === 0) sDev = "Fault";
        }

        //(3) Offline:  datetimeNow - reportDateTime > xmit period (1 or 7)
        // sOffline
        val2 = Ut.getDateDiff(vT.datetime);    // ret < 0 if past
        if (vT.netinfo.TransmissionPeriod + val2 < 0) {
          //sOffline = (Math.abs(val)).toString();
          sOffline = "Offline";
        }
        else sOffline = "Normal";

        //(4) Rescue:  done in processEmergencyReportLatest()

        // -------- find sn in aeditems[] ---------
        //if (this.aeditems.length > 0 ) {
        //vDev = this.findSnFromDeviceReport(vT.sn);   
        //if (vDev !== null) {
        vDev.WirelessStrength = vT.netinfo.SignalLevel;
        vDev.RescueTimes = iRescue;
        vDev.Offline = sOffline;
        vDev.PadStatus = sPads;
        if (sOffline === "Offline") {
          vDev.DevStatus = sDev + "/Offline";
        }
        else vDev.DevStatus = sDev;
        vDev.DevSelfTestPeriod = vT.netinfo.TestPeriod;   // Hide
        // vDev.SelfTestSendPeriod = vT.netinfo.TransmissionPeriod; 
        if (vT.netinfo.TransmissionPeriod === 1) vDev.SelfTestSendPeriod = "Daily";
        else if (vT.netinfo.TransmissionPeriod === 7) vDev.SelfTestSendPeriod = "Weekly";
        else vT.netinfo.TransmissionPeriod = "/";
        vDev.LastReportUploadTime = vT.datetime;
        switch (vT.netinfo.NetType) {
          case 0: vDev.NetworkType = "Wifi"; break;
          case 1: vDev.NetworkType = "4G"; break;
          default: vDev.NetworkType = "/"; break;
        }
        //console.log('##DevInfo-111', vT);  //debug
        //  }
        //}
      } // end of for

    },

    // ---------------------------------------------
    // DESCRIPTION:  check if testinfo - padsLife & batt level should overwrite dashboard data
    //   xary[] = rescue data:  sn,datetime,InstLoc          // id,cprduration,shock,....
    async processEmergencyReport(xary) {
      if (xary === undefined || xary === null || xary.length === 0) return;
      let vE = {};
      let vDev = {};
      for (let idx = 0; idx < xary.length; idx++) {
        //vE = xary[idx];
        //xary[idx].datetime = moment(xary[idx].datetime).format("YYYY-MM-DD HH:mm:ss");
        for (let idx2 = 0; idx2 < this.aeditems2.length; idx2++) {
          if (this.aeditems2[idx2] !== undefined && this.aeditems2[idx2] !== null) {
            if (this.aeditems2[idx2].devSn == xary[idx].sn) {
              if (xary[idx].InstLoc !== '/' && xary[idx].InstLoc !== "") {
                xary[idx]['InstLoc'] = this.aeditems2[idx2].instLoc;
                //console.log(xary[idx].sn, this.aeditems2[idx2].devSn);
              }
            }
          }
          //console.log(xary[idx].sn, vDev.devSn);
        } // for(aeditems2)
      } //for(xart)

    },


    // ---------------------------------------------
    //              Uts
    // ---------------------------------------------
    // DESCRIPTION:  search sn in testinfo and return its object
    searchSnFromTestInfo(xSn) {
      if (xSn === undefined || xSn === null || xSn === "") return null;
      for (let idx = 0; idx < this.testrpts.length; idx++) {
        if (xSn === this.testrpts[idx].sn) return this.testrpts[idx];
      }
      return null;  //not found
    },

    async searchSnFromDeviceList(xSn) {
      if (xSn === null || xSn === "" || this.aeditems2 === undefined || this.aeditems2 === null) return null;
      for (let idx2 = 0; idx2 < this.aeditems2.length; idx2++) {
        if (this.aeditems2[idx2].devSn !== undefined && xSn === this.aeditems2[idx2].devSn) {
          return this.aeditems2[idx2];
        }
      }
      return null;  //not found
    },


    // ---------------------------------------------
    // DESCRIPTION: reload Home Page
    async loadTables() {
      //this.setMapAddr('Medisource-Asia');   
      this.showProgress = true;

      /* let vdata5 = await this.axiosGetEmergencyLatest();  //get the total number  rescueDevices
      if (vdata5 !== null && vdata5.length > 0) {
        this.rescueDevices = vdata5.length + ' ';
        vdata5 = null;
      } */

      let vdata = await this.axiosGetDashboard2();
      if (vdata !== undefined && vdata !== null) {
        this.dashbrd = vdata;
        try {
          this.setDashBoard();
          this.processEmergencyReport(this.emergrpts);
          this.triggerChart++;   // update 3 doughnut chart
        } catch (err) { Ut.noop(); }
      }

      /*
      // for ListBox --> <Detail> btn
      vdata = await this.axiosGetDevInfoV1();
      if (vdata !== undefined && vdata !== null) {
        try {
          this.storeToAedItems(vdata);  
        } catch(err) { Ut.noop(); }
      } 

      // test report & DeviceInfo  needed for basicInfo
      vdata = await this.axiosGetTestReportLatest();
      if (vdata !== undefined && vdata !== null) {
        try {
          Ut.copyArray(this.testrpts, vdata);
          this.processTestReportLatest();  
        } catch(err) { Ut.noop(); }
      }  */

      /* vdata = await this.axiosGetEmergency();
      if (vdata !== undefined && vdata !== null) {
        this.emergrpts = vdata;
        this.processEmergencyReport(vdata);
        //this.rescueTimes = this.emergrpts.length + ' ';
        // sn,datetime,sn,InstLoc,cprduration,......
      } */

      //this.setDashBoard();

      this.showProgress = false;

      vdata = null;

      /*  //(1) Load Dashboard
      this.axiosGetDashboard().then(xdata1 => {
        if (xdata1 !== null) {
          //this.dashbrd = JSON.parse(JSON.stringify(xdata1)); //copy dashboard data
          this.dashbrd = xdata1;
            //this.setDashBoard(xdata1);

      //(2) Load Test reports            
            this.axiosGetTestReportLatest().then(xdata2 => {
              if (xdata2 !== null && xdata2.length > 0) {
                  Ut.copyArray(this.testrpts, xdata2);
                  //this.processTestReportLatest();

                  this.setDashBoard().then(xdata3 => {
      //(3) Load Emergency reprots
                    this.axiosGetEmergency().then(xdata3 => {
                      this.emergrpts = xdata3;
                      this.processEmergencyReport(xdata3);
                      //console.log("##111",  this.rescuerpts);  //debug*
                    })
                  })
                  this.showProgress = false;

                }
              }).catch((err2) => {
                console.log("##Dashboard err2", err2);
                this.showProgress = false;
              });
          }
      }).catch((err1) => {
         console.log("##Dashboard err1", err1);
         this.showProgress = false;
      });  */
    }

  },  //end of methods

  mounted() {
    if (cookies.exist("aa") === false) {
      this.$router.push('/');
    }

    this.$root.$emit('App_TopBar', true);
    this.loadTables();
    this.selectRescue = "30";

  }

}
</script>

<style scoped>
.cTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2em;
  color: white;
}

/* HomePage background */
#idBackGnd {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #424141;
  height: calc(100vh - 50px);
  /* pagelength*1 */
}

#idBackGnd::-webkit-scrollbar {
  display: none;
}


#id_card {
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.generalFonts {
  font-family: Montserrat;
}

.classContainer {
  max-width: 300px;
}


#id_timeline::-webkit-scrollbar {
  display: none;
  background: inherit;
}

.theme--dark.v-timeline:before {
  background: orange;
}

.cBorder {
  border-bottom: 1px solid #383838;
}

.cBorder2 {
  border-bottom: 2px solid #383838;
}

.cBorder1W {
  border-bottom: 1px solid #616161;
}

.cBorderViolet {
  border: 2px solid #7C4DFF;
}


/* ListBox */
.v-list-item {
  min-height: 30px;
  font-size: 1.0em;
}

#idList {
  /* hide scrollbar in list, scroll by mouse wheel */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: 1px solid white;
}

#idList::-webkit-scrollbar {
  display: none;
}

/* v-text-field */
.v-text-field>>>.v-input__slot::before {
  border-color: blue !important;
}

/* text color */
.v-text-field>>>.v-text-field__slot input {
  color: white;
}

.fontN {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.8em;
  font-weight: normal;
  color: white;
}

.fontB {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
}

.fontN2 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.8em;
  font-weight: normal;
  color: white;
}

/* for number only */
.fontN3W {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.8em;
  font-weight: normal;
  color: white;
}

.fontN3B {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.8em;
  font-weight: lighter;
  color: black;
}

.fontSN {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 1.0em;
  font-weight: normal;
  color: white;
}

.fontLocation1 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.9em;
  font-weight: normal;
}

.fontLocation2 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.9em;
  font-weight: normal;
}


.fontTitleListBox {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 1.0em;
  font-weight: 100;
}

.fontItemListBox {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.9em;
  font-weight: 100;
}

.fontItemListBoxBtn {
  font-family: Montserrat;
  font-size: 0.8em;
  font-weight: 700;
}


.fontTime1 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 1.6em;
  font-weight: normal;
  color: white;
}

.fontTime2 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 1.0em;
  font-weight: normal;
  color: white;
}

.fontTime3 {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: 0.7em;
  font-weight: normal;
  color: white;
}

.fontTimeLst {
  font-family: Montserrat !important;
  font-style: normal;
  font-size: .9em;
  font-weight: normal;
  color: white;
}
</style>
