<!-- --------------------------------------------------------------------------------
                      TextInput.vue

DESCRIPTIONS
  - label & input
  - with box outline

Note:
  - parent container height >= 47px

---------------------------------------------------------------------------------- -->
<template>
  <v-card width="100%" height="100%" class="ma-0 pa-0 d-flex flex-row transparent elevation-5">

    <v-card :width="width1" class="mt-0 grey lighten-2 rounded-r-0 rounded-l-lg"><h5 class="ml-2 mt-2 fontN">{{ label }}</h5></v-card>
    <v-text-field :width="width2" dense light :placeholder="message" type="input" v-model="keybuff" hide-details
       class="white ma-0 pa-0 text-decloration-none rounded-l-0 rounded-r-lg fontN" @keyup="onKeyUp" solo outlined 
       :spellcheck="false" :readonly="readonly">
    </v-text-field>

  </v-card>
</template>

<script>
  export default {
    name: 'InputBox1',

    props: {
      label: {type: String, default: ''},
      message: {type: String, default: ''},
      readonly: {type: Boolean, default: false},
      width1: {type: String, default: '35%'},
      width2: {type: String, default: '65%'},
      id: {type: String, default: '0'},
      capitalize: {type: Boolean, default: false},
      init: {type: Number}
    },

    data () {
      return {
        keybuff: '',
        keybuffMax: 30
      }
    },

    methods: {
      onKeyUp (evn) {
        if (this.keybuff.length >= this.keybuffMax + 1) {
          this.keybuff = this.keybuff.substring(0, this.keybuffMax)
          return
        }
        if (evn.keyCode >= 0x20 || evn.keyCode === 13 || evn.keyCode === 8) {
          // this.$emit('msgInputBox1', this.keybuff);
          if (this.capitalize && evn.keyCode >= 0x20) {
            this.keybuff = this.keybuff.toUpperCase();
          }
          this.$emit('msgInputBox1', { 'id': this.id, 'text': this.keybuff} );
          return;
        }
        // this.keybuff = this.keybuff.toUpperCase()
      }
    },

    mounted () {
      this.keybuff = this.value;
    },

    watch: {
      message: function (newVal) {
        this.keybuff = newVal;
        // this.$emit('msgInputBox1', this.keybuff);
        this.$emit('msgInputBox1', { 'id': this.id, 'text': this.keybuff} );
      },
      init: function (newVal) {
        this.keybuff = "";
      }
    }

  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

.v-text-field {
  min-height: 20px;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
