<template>
  <v-card id="idCard1" width="100%" min-width="900px">

    <!------------------------- 1st row:  Search filters ------------------>
    <v-card width="auto" class="ma-0 mt-1" color="transparent">
      <!------------------ 1st Row A:  Input Box ----------------->
      <v-card width="auto" height="52px" class="mx-1 mt-1 d-flex rounded-b-0 rounded-t orange">
        <v-card width="25%" height="40px" class="mx-1 mt-1 transparent border-0">
          <inputBox1 label="Device SN" :message="sDevSn"  width1="58%" width2="42%" id="devsn"
          @msgInputBox1="onInputBox1"  :capitalize="true"/>
        </v-card>

        <!-- <v-card width="40%" height="40px" class="mx-1 mt-1 transparent border-0">
          <inputBox1 label="Install Location" :message="sInstallLoc"  width1="30%" width2="70%" @msgInputBox1="onInputBox1"/>
        </v-card>  -->

        <!-- <v-card width="25%" height="40px" class="mx-1 mt-1 transparent border-0">
          <inputBox1 label="Device Admin" :message="sAdmin"  width1="65%" width2="35%" @msgInputBox1="onInputBox1"/>
        </v-card>  -->

      <!------------------ 1st Row B: Date Picker ---------------------->
      <v-card width="auto" height="52px" class="mx-1 mt-0 d-flex flex-row rounded-t-0 rounded-b orange">
        <v-card width="185px" height="40px" class="ml-1 mt-1 amber accent-3 rounded elevation-5">
          <DatePicker :title="sDate1" :dayback="sDayBack" @DatePicker="onDateChange1"/>
        </v-card>

        <v-card width="185px" height="40px" class="ml-1 mt-1 amber accent-3 rounded elevation-5">
          <DatePicker :title="sDate2" @DatePicker="onDateChange2"/>
        </v-card>     

      </v-card>
      <v-progress-circular v-if="showProgress" color="blue" :size="30" indeterminate class="ml-2 mt-2"></v-progress-circular>

      <v-spacer></v-spacer>

      <!-- <v-btn icon color="#37474F" class="mr-2 mt-2 elevation-0 border-0" @click="onBtnRefresh">
        <svg-icon type="mdi" :path="pathRefreshCircle" size="40"></svg-icon>
      </v-btn>  -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="#37474F" class="mt-2 mr-0 elevation-0" v-bind="attrs" v-on="on" @click="onBtnRefresh">
            <svg-icon type="mdi" :path="pathRefreshCircle" size="32"></svg-icon>
          </v-btn>
        </template>
        <span class="yellow--text">Reload</span>
      </v-tooltip>      

      <!-- <v-btn icon class="mr-2 mt-2 elevation-5" style="background-color: #455A64; color: white;">
      <v-icon>mdi-magnify</v-icon></v-btn>  -->

      </v-card>

    </v-card>

    <!------------------------- 2nd row:  tabs ---------------------------->    
    <v-card id="idCard2" width="auto"  fixed-header class="mx-1 mt-1 pa-0" color="transparent" > 

      <!-- <v-tabs width="auto" v-model="selectedTab" dense dark align-with-title background-color="orange" slider-color="black" 
        class="mx-0 pa-0" active-class="mx-0 orange darken-3 white--text" center-active>  -->

      <v-tabs width="auto" height="30px" v-model="selectedTab" dense dark centered align-with-title background-color="orange" slider-color="transparent" 
        class="pa-0" active-class="orange darken-3 white--text" @change="onChangeTabs">

        <v-tab href="#TblEventFault" width="120" :class="sTabCapital" @click="onTab(1)">Fault</v-tab>
          <v-tab-item value="TblEventFault" :transition="false">
            <tblEventFault :tableItems="tblMgm" :pSn="vDevSn" :pDate1="vDate1" :pDate2="vDate2"
            @tblDevEmit="onEmitDevTbl2" :trigger="triggerMgmt"/>
          </v-tab-item>

        <v-tab href="#TblEventWarning" width="120" :class="sTabCapital" @click="onTab(2)">Warning</v-tab>
          <v-tab-item value="TblEventWarning" :transition="false">
            <tblEventWarning :tableItems="tblMgm" :pSn="vDevSn" :pDate1="vDate1" :pDate2="vDate2"
            @tblDevEmit="onEmitDevTbl2" :trigger="triggerMgmt"/>
          </v-tab-item>

        <v-tab href="#TblEventOffline" width="120" :class="sTabCapital" @click="onTab(3)">Offline</v-tab>
          <v-tab-item value="TblEventOffline" :transition="false">
            <tblEventOffline :tableItems="tblMgm" :pSn="vDevSn" :pDate1="vDate1" :pDate2="vDate2"
            @tblDevEmit="onEmitDevTbl2" :trigger="triggerMgmt"/>
          </v-tab-item>

        <v-tab href="#TblEventRescue" width="120" :class="sTabCapital" @click="onTab(4)">Rescue</v-tab>
          <v-tab-item value="TblEventRescue" :transition="false">
            <tblEventRescue :tableItems="tblEmerg" :pSn="vDevSn" :pDate1="vDate1" :pDate2="vDate2"
            @tblDevEmit="onEmitDevTbl2" :trigger="triggerMgmt"/>
          </v-tab-item>

        <v-tab href="#TblEventTestInfo" width="120" :class="sTabCapital" @click="onTab(5)">User Test</v-tab>
          <v-tab-item value="TblEventTestInfo" :transition="false">
            <tblEventTestInfo :tableItems="tblMgm" :pSn="vDevSn" :pDate1="vDate1" :pDate2="vDate2"
            @tblDevEmit="onEmitDevTbl2" :trigger="triggerMgmt"/>
          </v-tab-item>          

      </v-tabs>
    </v-card>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>

  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import cookies from '@/js/Cookie.js'
import inputBox1 from '../components/Common/InputBox1'
// import comboBox1 from '../components/Common/ComboBox1'
import DatePicker from '../components/Common/DatePicker'

import tblEventFault from '@/views/viewsEvent/TblEventFault.vue'
import tblEventWarning from '@/views/viewsEvent/TblEventWarning.vue'
import tblEventOffline from '@/views/viewsEvent/TblEventOffline.vue'
import tblEventRescue from '@/views/viewsEvent/TblEventRescue.vue'
import tblEventTestInfo from '@/views/viewsEvent/TblEventTestInfo.vue'
import * as Tbl from '@/js/tables.js'
import * as Ut from '@/js/ut.js'
import * as Emergency from '@/js/samples/Emergency.js'
import * as TestInfo from '@/js/samples/TestInfo.js'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiRefreshCircle } from '@mdi/js';

  export default {
    name: 'EventManagement',

    components: {
      SvgIcon, inputBox1, DatePicker,
      tblEventFault, tblEventWarning, tblEventOffline, tblEventRescue, tblEventTestInfo
    },

    data () {
      return {
        sTabCapital: "transparent blue-grey--text text--darken-3 font-weight-bold text-capitalize fontN",
        axiosPathLatestTestReport: '/api/v1/latesttestreports',
        axiosPathLatestEmergReport: '/api/v1/latestemergreports',
        axiosPathLatestDevStateReport: '/api/v1/getLatestDeviceStates',
        axiosPathGetDevInfoV1: '/client/api/v1/aedDeviceRecord',
        connectionTimeout:  1500,
        pathRefreshCircle: mdiRefreshCircle,
        objRoute: {},
        sDevSn: '',
        sInstallLoc: '',
        sAdmin: '',
        sDate1: 'First Failure Date',
        sDate2: 'Last Failure Date',
        iDayBack: 0,
        sDayBack: "0",
        hideBtnRefresh: false,
        selectedTab: '', // = TblEventFault, TblEventWarning, TblEventOffline, TblEventRescue, TblEventTestInfo
        selectedTab2: '',
        vDevSn: '',
        vDate1: '',
        vDate2: '',
        triggerMgmt: 0,
        showProgress: false,
        snackBar: {"on": false, "color": "", "message": ""},
        snackTimeout: 3000,
        snackMsgLogout: "Login expired, please login again",
        tmrObj: {},
        tmrObj2: {},
        retryCounter: 3,

        tblAed: this.aeditems,
        tblTest: [],
        tblEmerg: [],
        tblMgm: [],
        aryDeviceState: [],
        aryPadSt3mon: [],
        aryPadExpire: []
      }
    },

    methods: {

      // DESCRIPTION:  handle Device SN button click at table 
      //   receive emit (devSn) event Mgmt<--TblEventFault<--devTable2
      onEmitDevTbl2 (xstr) {
        if (xstr === "") return;
        let xobj = this.searchAedItemsSn(xstr);
        if (xobj !== null) {
          if (this.searchTestRptSn(xobj.devSn) !== null) {       //241025 1.55c
            xobj.testreport = 1;   //testreport for this Sn available
          }
          Ut.writeTabMgmt(this.selectedTab);  //save present tab location
          xobj["tab"] = this.selectedTab;        //add new property 'tab' to the object
          this.$root.$emit('App_Tab', "/DevInfo");  // change tab in top bar
          this.$router.push({name: "DevInfoDetail", params: {xobj}, query:{sn:xobj.devSn}});
        }
      },

      // DESCRIPTION:  handle logout
      onEmitMgmt (xstr) {
        switch(xstr) {
          case "logout": 
            Ut.WriteUser(Ut.createUser()); // assign empty user
            Ut.WriteOtp2Fa(Ut.createOtp2fa()); // assign empty otp2fa
            Ut.clearArray(this.aeditems);
            Ut.clearArray(this.testrpts);
            Ut.clearArray(this.emergrpts);     
            if (cookies.exist("kk")) cookies.delete("kk");
            if (cookies.exist("us")) cookies.delete("us");          
            //console.log("##onEmitMgmt");  //debug
            this.$router.push("/");          
            break;
          default: break;
        }
      },

      // DESCRIPTION:  handler of tabs change event, occur at clicking mgmt tab only
      // TblEventFault / TblEventWarning / TblEventOffline / TblEventResuce / TblEventTestInfo
      onChangeTabs (xtab) {
        //console.log("##onChangeTabs", xtab);
        let iRet = this.tabname2Index(xtab);
        if (iRet > 0) this.onTab(iRet);
        Ut.writeTabMgmt(this.selectedTab);  //save new tab location
      },

      // DESCRIPTION:  copy value emit from <inputBox1> to this.filters.devSn
      onInputBox1 (xobj) {
        switch(xobj.id) {
          case 'devsn'  :  this.vDevSn = xobj.text;  break;
          default: break;
        }  
      },

      onComboBox1 (xstr) {
        //console.log('combobox', xstr);
        Ut.noop();
      },

      onDateChange1 (xDate) {
        this.vDate1 = xDate;
        this.tblTrigger();
      },

      onDateChange2 (xDate) {
        this.vDate2 = xDate;
        this.tblTrigger();
      },

      // DESCRIPTION:  handle refresh button
      async onBtnRefresh () {
        //console.log('#Mgmt-Refresh', this.selectedTab);
        if (this.showProgress) return;  //not allow to refresh if last refresh not finished

        this.selectedTab2 = this.selectedTab;  //save present tab
        if (this.selectedTab2 !== "") {
          switch(this.selectedTab2) {
            case "TblEventFault": 
              this.objRoute = {"tab":"Fault", "value":0}; 
              break;
            case "TblEventWarning": 
              this.objRoute = {"tab":"Warning", "value":0}; 
              break;            
            case "TblEventOffline": 
              this.objRoute = {"tab":"Offline", "value":0}; 
              break;                   
            case "TblEventRescue": 
              this.objRoute = {"tab":"Rescue", "value":0}; 
              break;     
            case "TblEventTestInfo": 
              this.objRoute = {"tab":"UserTest", "value":0}; 
              break;                                 
          }
        }

        await this.loadTables();
        this.selectedTab = this.selectedTab2;   //restore saved tab
      },

      // DESCRIPTION:  hide items depending on event types 
      onTab (xtab) {
        //console.log("##onTab()", xtab);  
        if (this.tblMgm === undefined || this.tblMgm === null) return;
        switch(xtab)
        {
          case 1: this.sTitle = " - Device Fault List"; 
            // console.log('onTab - Fault, len=', this.tblMgm.length);
            Ut.writeTabMgmt("TblEventFault");
            this.sDate1 = 'First Failure Date';
            this.sDate2 = 'Last Failure Date';
            this.hideBtnRefresh = false;
            for (let idx=0; idx < this.tblMgm.length; idx++) {
              if (this.tblMgm[idx].errorCode === "" || this.tblMgm[idx].errorCode === "/" || this.tblMgm[idx].errorCode === "0" ) {
                this.tblMgm[idx].hidden = true;
              } else {
                this.tblMgm[idx].hidden = false;  //show
                //console.log("##onTab(2)", this.tblMgm[idx].sn, this.tblMgm[idx].hidden);  
              }
            }
            this.tblTrigger();
            break;
          case 2: this.sTitle = " - Device Warning List"; 
            Ut.writeTabMgmt("TblEventWarning");
            this.sDate1 = 'First Warning Date';
            this.sDate2 = 'Last Warning Date';
            this.hideBtnRefresh = false;      
            for (let idx=0; idx < this.tblMgm.length; idx++) {
              if (this.tblMgm[idx].warnType === "" && this.tblMgm[idx].warnMessage === "") {
                this.tblMgm[idx].hidden = true;
              } else {
                this.tblMgm[idx].hidden = false;
              }
            }
            this.tblTrigger();
            break;
          case 3: this.sTitle = " - Device Offline List"; 
            Ut.writeTabMgmt("TblEventOffline");
            this.sDate1 = 'First Offline Date';
            this.sDate2 = 'Last Offline Date';
            this.hideBtnRefresh = false;
            for (let idx=0; idx < this.tblMgm.length; idx++) {
              if (this.tblMgm[idx].offlineDays >= 1) {
                this.tblMgm[idx].hidden = false;
              } else {
                this.tblMgm[idx].hidden = true;
              }
            }
            this.tblTrigger();
            break;
          case 4: this.sTitle = " - Rescue List"; 
            Ut.writeTabMgmt("TblEventRescue");
            this.sDate1 = 'First Rescue Date';
            this.sDate2 = 'Last Rescue Date';
            this.hideBtnRefresh = false;
            for (let idx=0; idx < this.tblEmerg.length; idx++) {
              /* if (this.tblEmerg[idx].cprDuration > 0 || this.tblEmerg[idx].shock > 0 || this.tblEmerg[idx].receiveTime === "/") {           
                this.tblEmerg[idx].hidden = false;
              } else {
                this.tblEmerg[idx].hidden = true;
              } */
              this.tblEmerg[idx].hidden = false;
            }
            this.tblTrigger();
            break;
          case 5: this.sTitle = " - User Test Result"; 
            Ut.writeTabMgmt("TblEventTestInfo");
            this.sDate1 = 'First Test Date';
            this.sDate2 = 'Last Test Date';
            this.hideBtnRefresh = true;
            for (let idx=0; idx < this.tblMgm.length; idx++) {
              // receiveTime === ""  perhaps come from master record
              if (this.tblMgm[idx].receiveTime === "" || this.tblMgm[idx].receiveTime === "/") {
                this.tblMgm[idx].hidden = true;
              }
              else this.tblMgm[idx].hidden = false; //display all records
              //console.log("##onTab(5)", this.tblMgm[idx].devSn, this.tblMgm[idx].clickable);  
            }
            this.tblTrigger();
            break;         
          default: 
            this.sTitle = ""; 
            Ut.writeTabMgmt("");
            break;
        }
      },

      //DESCRIPTION:  change tab programmatically by this.objRoute.tab
      //  trigger:    dashboard-events, circles, topbar, browser reload    // refresh button No
      setTab () {
        //console.log("##setTab()", this.objRoute);
        if (this.objRoute === undefined || this.objRoute === null) return;
        let vObj = this.objRoute;
        if (vObj !== null && "tab" in vObj) {
          switch(vObj.tab) {
            case "Fault": 
            case "TblEventFault":
              this.onTab(1);
              this.selectedTab = "TblEventFault";            
              break;

            case "Warning":
            case "TblEventWarning":
              this.onTab(2);
              this.selectedTab = "TblEventWarning";            
              break;

            case "Offline":
            case "TblEventOffline":
              this.onTab(3);
              this.selectedTab = "TblEventOffline";
              break;

            case "Rescue":
            case "TblEventRescue":
              this.onTab(4);
              this.selectedTab = "TblEventRescue";
              break;

            case "UserTest":
            case "TblEventTestInfo": 
              this.onTab(5);
              this.selectedTab = "TblEventTestInfo"
              break;
          }        
        }
        else {  
          this.selectedTab = Ut.tabMgmt;
        } 
      },

      tabname2Index (xtabname) {
        switch(xtabname) {
          case "TblEventFault": return 1;
          case "TblEventWarning": return 2;
          case "TblEventOffline": return 3;
          case "TblEventResuce": return 4;
          case "TblEventTestInfo": return 5;
          default: return 0;
        }
      },


    // ----------------------------------------------------------------------
    //              [Ut]
    // ----------------------------------------------------------------------
    // DESCRIPTION:  trigger v-data-table
    tblTrigger() {
      if (this.triggerMgmt++ >= 60000) this.triggerMgmt=0;
    },


    // ----------------------------------------------------------------------
    //              [Axios] [axios]
    // 
    // ----------------------------------------------------------------------

    // ---------------------------------------------
    // DESCRIPTION:  Latest Test Report, tiggered by <Refresh> button
    async axiosGetTestReportLatest () {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestTestReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            /* if (resp.data.data !== null && resp.data.data.length > 0) {
              Ut.copyArray(this.testrpts, resp.data.data);              
              this.storeTestReportToEvntTable(this.testrpts);
              if (this.selectedTab2 !== "") {    //for onBtnRefresh only
                this.selectedTab = this.selectedTab2;
                this.selectedTab2 = "";
              }
            }  */
            return resp.data.data;
          } else {
            console.log('#eventMgmt-axiosGetTestReportLatest(1) error: axios data request fail');
          }  
        } else {
          console.log('#eventMgmt-axiosGetTestReportLatest(2) error: receive null data');
        }
      } catch (error) {
        this.showProgress = false;
        console.error('#eventMgmt-axiosGetTestReportLatest(3) error: ' + error.message);
        if (error.message.indexOf('401') >= 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    //----------------------------------------------------------------
    // DESCRIPTION:  Latest Test Report, tiggered by <Refresh> button
    async axiosGetEmergencyLatest () {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestEmergReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            /* if (resp.data.data != null && resp.data.data.length > 0) {
              Ut.copyArray(this.emergrpts, resp.data.data);
              this.storeEmergToTable(this.emergrpts);  
              this.axiosGetTestReportLatest(); 
            } else {
              this.axiosGetTestReportLatest(); 
            } */
            return resp.data.data;
          } 
        } else {
          console.log('#eventMgmt-axiosGetEmergencyLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#eventMgmt-axiosGetEmergencyLatest(3) error: ' + error.message);
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  All Device States, PowerOn PowerOff
    async axiosGetDeviceStateLatest () {                                         //OBSOLETED
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.axiosPathLatestDevStateReport,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== undefined && resp.data.data !== null && resp.data.data.length > 0) {
                this.storeDevicesStateToTable(resp.data.data);
                //console.log('##axiosGetDeviceStateLatest(0) OK');
              } else {
                if (this.aryDeviceState !== undefined && this.aryDeviceState !== null && this.aryDeviceState.length > 0) 
                  Ut.clearArray(this.aryDeviceState);
              }
            } else {
              console.log('#Detail-axiosGetDeviceStateLatest(1) error: axios data request fail');
              this.showProgress = false;
            }  
          } else {
            console.log('#Detail-axiosGetDeviceStateLatest(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          console.error('#Detail-axiosGetDeviceStateLatest(3) error: ' + error.message);
          this.showProgress = false;
        }
    },


    // ---------------------------------------------
    // DESCRIPTION:  Get deivce info, V1 (with token)
    async axiosGetDevInfoV1 () {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathGetDevInfoV1,
        //'url': 'http://196.76.0.156:7003/client/api/v1/aedDeviceRecord',
        //timeout: 3000,
        'headers': {
          //'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      
      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            return resp.data.data;
          } else {
            console.log('#axiosGetDevInfoV1(1) error: axios data request fail');
            this.showProgress = false;
          }  
        } else {
          console.log('#axiosGetDevInfoV1(2) error: receive null data');
          this.showProgress = false;
        }
      } catch (error) {
        this.showProgress = false;
        console.error('#axiosGetDevInfoV1(3) error: ', error.message);
        if (error.message.indexOf('401') >= 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message === undefined || error.message.indexOf('Network Error') >= 0) {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },


      // ----------------------------------------------------------------------
      //              [Processing] [stat]
      // 
      // ----------------------------------------------------------------------
      // DESCRIPTION:  load axios or sim data to this.tblTest[]          OBSOLETED
      storeTestReportToEvntTable (xAry) {
        if (xAry === undefined || xAry === null) return;
        Ut.clearArray(this.tblMgm);

        let str, sWarn = "", sWarnType = "";
        let bErrWarn = false;

        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          let vObj = Tbl.createEvnt();
          bErrWarn = false;
          sWarn = "";
          sWarnType = "";

          //warning:  pads expired OR pads <= 3 months, OR batt <= 35%   
          //  (1) Pads returns:  "Normal", "3", "12", "Expired"
          str = Ut.getPadsStatus(vObj2.testitems.padsExpiried.result,
            vObj2.testitems.padsExpiringDate.result);
          if (str === "Normal") sWarn = "";
          else {
            if (str === "3") {
              sWarnType = "Pads";
              sWarn = "Pads to expire";
            } 
            else if (str === "Expired") {
              sWarnType = "Pads";
              sWarn = "Pads expired";
            } 
          }         

          // (2) Batt Status returns:  Normal, error, Batt Low
          str = Ut.getBattStatus(vObj2.testitems.batResidualCapacity.result,
            vObj2.testitems.batTotalCapacity.result).Status;            
          if (str !== "Normal" && str.indexOf("Fault") < 0) {
            str = str.replace("Batt ", "");
            if (sWarn !== "") {
              sWarn = sWarn +",Batt "+ str; sWarnType = sWarnType + ",Batt";
            }
            else {
              sWarn = "Batt "+ str; sWarnType = "Batt";
            }
          }
          
          //(3) relatedtest in warning    241105
          str = Ut.getRelatedTestResult(vObj2.relatedtest);
          if (str !== "Normal") {
            if (sWarn !== "") {
              sWarn = sWarn +","+ str; sWarnType = sWarnType + ",relatedtest";
            }
            else {
              sWarn = str; sWarnType = "relatedtest";
            }
          }
          //console.log("##eMgmt-1", vObj2.sn, sWarn, sWarnType, str);  //debug  

          vObj.devSn          = vObj2.sn;
          vObj.devModel       = "";
          vObj.faultType      = Tbl.rptTestType[vObj2.testtype];
          vObj.errorCode      = vObj2.testerrorcode;
          if (vObj.errorCode === "") vObj.errorCode = "/";
          vObj.firstTime      = "";
          vObj.lastTime       = "";
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss");
          //vObj.testTime       = moment(vObj2.testtime).format("YYYY-MM-DD HH:mm:ss");
          vObj.testTime       = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss");
          vObj.CustName       = "";
          vObj.InstLoc        = "";
          vObj.warnType       = sWarnType.trim();
          vObj.warnMessage    = sWarn.trim();
          vObj.offlineDays    = Ut.getOfflineStatus(vObj.receiveTime, vObj2.netinfo.TransmissionPeriod);
          vObj.rescueTime     = "";
          vObj.hidden         = false;
          //debug offline
          //console.log('#EvntMgmt111', vObj.devSn, vObj.receiveTime, vObj2.netinfo.TransmissionPeriod, vObj.offlineDays);  //debug

          if (vObj.errorCode === "/" || vObj.errorCode === "" ) {
            //vObj.faultMessage = "/";
            vObj.faultMessage = "Normal";
            bErrWarn = false;
          } else {
            bErrWarn = true;
            if ('errorMessage' in vObj2) {
              vObj.faultMessage = "";
              if (vObj2.errorMessage !== undefined && vObj2.errorMessage !== null) {
                for(let idx2=0; idx2 < vObj2.errorMessage.length; idx2++) {
                  if (Ut.SysConf.language === "eng") {
                    vObj.faultMessage = vObj.faultMessage + vObj2.errorMessage[idx2].message+ ",";
                  } else if (Ut.SysConf.language === "chi")  {
                    vObj.faultMessage = vObj.faultMessage + vObj2.errorMessage[idx2].chiMessage+ ",";
                  }
                }
              }
            }
          }

          // Seach this.aeditems[]
          //if (bErrWarn || vObj.errorCode !== "" || vObj.warnType !== "") {
            let vObj3 = this.searchAedItemsSn(vObj2.sn);
            if (vObj3 !== null) {
              vObj.CustName = vObj3.CustName;
              vObj.InstLoc  = vObj3.InstLoc;
              vObj.clickable = true;
            } else {
              vObj.clickable = false;
            }
          

          this.tblMgm.push(vObj);
        }

        // Sort array:  newest at top of table
        if (this.tblMgm !== undefined && this.tblMgm.length > 1) {
          this.tblMgm.sort((a, b) => Ut.compareDates(a.receiveTime, b.receiveTime));
        }

        // Calculate time range for DatePicker
        if (this.tblMgm !== undefined && this.tblMgm.length > 0) {
          // testreport table
          let yDate2 = this.tblMgm[0].receiveTime;  //last
          let yDate1 = this.tblMgm[this.tblMgm.length - 1].receiveTime; //first
          //let yDiff = moment(yDate1).diff(moment(yDate2), 'days');  
          let yDiff = moment(yDate1).diff(moment(new Date()), 'days') - 1; 
          if (yDiff < this.iDayBack) this.iDayBack = yDiff;
          this.sDayBack = this.iDayBack.toString();
          if (this.emergrpts !== undefined && this.emergrpts !== null && this.emergrpts.length > 0) {
            // emergency table
            let yDate3 = this.emergrpts[this.emergrpts.length - 1].datetime;
            let yDiff2 = moment(yDate3).diff(moment(new Date()), 'days') - 1; 
            if (yDiff2 < this.iDayBack) this.iDayBack = yDiff2;
            this.sDayBack = this.iDayBack.toString();
          }
          //this.vDate1 = yDate1;
          //console.log("##mgmt-testinfo", yDate1, yDate2, yDiff, this.iDayBack);          
        }

        this.processMasterRecord();   //Master Record

        //this.setTab();
        this.tblTrigger();
      },

    //------------------------------------------------------------
    // DESCRIPTION:  process Master Record info
    processMasterRecord() {
      let Sn = "";
      let vObj = {};
      //(1) Warning
      //    (1a) pads3mon 
      if (Ut.padsSt3mon !== undefined && Ut.padsSt3mon !== null && Ut.padsSt3mon.length > 0) {
        for(let idx=0; idx < Ut.padsSt3mon.length; idx++) {
          Sn = Ut.padsSt3mon[idx].sn;
          let vObj = this.searchSnFromMgmtRpts(Sn);
          if (vObj !== null) continue;   //not existed in tblMgm, only in master rec
          let vObj2 = Tbl.createEvnt();
          vObj2.devSn = Sn;
          vObj2.warnType = "Pads";
          vObj2.warnMessage = "Pads to expire";
          vObj2.offlineDays = "";
          vObj2.errorCode = "";
          vObj2.faultType = "";

          let vDev = this.searchAedItemsSn(Sn);  
          if (vDev !== null) {
            vObj2.CustName = vDev.CustName;
            vObj2.InstLoc = vDev.InstLoc;
          } else {
            vObj2.CustName = "/";
            vObj2.InstLoc = "/";              
            //this.tblMgm.push(vObj2);     
          }
          this.tblMgm.push(vObj2);                
        }
      }
      //    (1b) padsExpire
      /* if (Ut.padsExpire !== null && Ut.padsExpire.length > 0) {
        for(let idx=0; idx < Ut.padsExpire.length; idx++) {
          Sn = Ut.padsExpire[idx].sn;
          let vObj = this.searchSnFromMgmtRpts(Sn);
          if (vObj === null) {  
            let vObj2 = Tbl.createEvnt();   //create new record
            vObj2.devSn = Sn;
            vObj2.warnType = "Pads";
            vObj2.warnMessage = "Pads expired";
            vObj2.offlineDays = "";
            vObj2.errorCode = "";
            vObj2.faultType = "";            
            let vDev = this.searchAedItemsSn(Sn);  
            if (vDev !== null) {
              vObj2.CustName = vDev.CustName;
              vObj2.InstLoc = vDev.InstLoc;
            } else {
              vObj2.CustName = "/";
              vObj2.InstLoc = "/";              
              //this.tblMgm.push(vObj2);
            }
            this.tblMgm.push(vObj2);
          } else {
            if (vObj.receiveTime !== "" && vObj.receiveTime !== "/") continue  //check if it is MasterRecord
            // record existed in tblMgm[], overwrite
            vObj.warnType = "Pads";
            vObj.warnMessage = "Pads expired";
          }
        }
      }  */
      //    (1c) battery level < 35%
      if (Ut.batt35 !== undefined && Ut.batt35 !== null && Ut.batt35.length > 0) {
        for(let idx=0; idx < Ut.batt35.length; idx++) {
          Sn = Ut.batt35[idx].sn;
          let vObj = this.searchSnFromMgmtRpts(Sn);
          if (vObj === null) {  
            let vObj2 = Tbl.createEvnt();   //create new record
            vObj2.devSn = Sn;
            vObj2.warnType = "Batt";
            vObj2.warnMessage = "Batt Low";
            vObj2.offlineDays = "";
            vObj2.errorCode = "";
            vObj2.faultType = "";            
            let vDev = this.searchAedItemsSn(Sn);  
            if (vDev !== null) {
              vObj2.CustName = vDev.CustName;
              vObj2.InstLoc = vDev.InstLoc;
            } else {
              vObj2.CustName = "/";
              vObj2.InstLoc = "/";              
            }
            this.tblMgm.push(vObj2);
          } else {
            if (vObj.receiveTime !== "" && vObj.receiveTime !== "/") continue  //check if it is MasterRecord
            vObj.warnMessage = "Batt Low";
            /* let vDev = this.searchAedItemsSn(Sn); 
            if (vDev !== null) {
              if (vObj.CustName === "" || vObj.CustName === "/") vObj.CustName = vDev.CustName;
              if (vObj.InstLoc === "" || vObj.InstLoc === "/") vObj.InstLoc = vDev.InstLoc;
            } */
          }
        }
      }      

      //(2) Fault:  Ut.aryFault[] contains serial number only, no object
      //console.log("Ut.aryFault", Ut.aryFault);
      if (Ut.aryFault !== undefined && Ut.aryFault !== null && Ut.aryFault.length > 0) {
        for(let idx=0; idx < Ut.aryFault.length; idx++) {
          Sn = Ut.aryFault[idx].sn;
          let vObj = this.searchSnFromMgmtRpts(Sn);
          if (vObj === null) {
            let vObj2 = Tbl.createEvnt();   //create new record
            vObj2.devSn = Sn;
            vObj2.testTime = Ut.aryFault[idx].testTime;
            vObj2.faultType = "MasterRecord";
            if (Ut.aryFault[idx].fault[0] !== undefined) {
              vObj2.faultMessage = Ut.aryFault[idx].fault[0].message;
            } else vObj2.faultMessage = "/";
            vObj2.warnMessage = "/";
            vObj2.warnType = "/";
            vObj2.offlineDays = "/";
            vObj2.errorCode = "999";  // meaning from master record
            let vDev = this.searchAedItemsSn(Sn);  
            if (vDev !== null) {
              vObj2.CustName = vDev.CustName;
              vObj2.InstLoc = vDev.InstLoc;
            } else {
              vObj2.CustName = "/";
              vObj2.InstLoc = "/";              
            }
            this.tblMgm.push(vObj2);
          } else {
            if (vObj.receiveTime !== "" && vObj.receiveTime !== "/") continue  //check if it is MasterRecord
            if (vObj.faultMessage !== "/" && vObj.faultMessage !== "") continue;  //testinfo record, dont overwrite
            vObj.warnMessage = "/";
            let vDev = this.searchAedItemsSn(Sn); 
            if (vDev !== null) {
              if (vObj.CustName === "" || vObj.CustName === "/") vObj.CustName = vDev.CustName;
              if (vObj.InstLoc === "" || vObj.InstLoc === "/") vObj.InstLoc = vDev.InstLoc;
            }
          }
        }
      } 

    },

      // DESCRIPTION:  load axios or sim data to this.tblEmerg[]
      storeEmergToTable (xAry) {
        if (xAry === undefined || xAry === null) return;
        Ut.clearArray(this.tblEmerg);

        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          let vObj = Tbl.createEvntRescue();
          // console.log('storeEmergToTable - ', vObj2.sn);  // debug
          vObj.devSn          = vObj2.sn;
          if (vObj2.devtype >= 0 && vObj2.devtype < Tbl.rptDeviceType.length) {
            vObj.devModel       = Tbl.rptDeviceType[vObj2.devtype];
          }
          vObj.firstTime      = "";
          vObj.lastTime       = "";
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss");
          vObj.rescueTime     = vObj2.totalduration;
          vObj.cprDuration    = vObj2.cprduration;
          vObj.shock          = vObj2.shock;
          vObj.hidden         = false;

          // Seach this.aeditems[]
          //if (vObj.cprDuration > 0 || vObj.shock > 0) {
          if (vObj.cprDuration > 0) {
            let vObj3 = this.searchAedItemsSn(vObj2.sn);
            if (vObj3 !== null) {
              vObj.CustName = vObj3.CustName;
              vObj.InstLoc  = vObj3.InstLoc;
            }
          }

          this.tblEmerg.push(vObj);
        } // end of for()

        // Sort array:  newest at top of table
        if (this.tblEmerg !== undefined && this.tblEmerg.length > 1) {
          this.tblEmerg.sort((a, b) => Ut.compareDates(a.receiveTime, b.receiveTime));
          //let yDate2 = this.tblEmerg[0].receiveTime;  //last
          let yDate1 = this.tblEmerg[this.tblEmerg.length - 1].receiveTime; //first
          //let yDiff = moment(yDate1).diff(moment(yDate2), 'days');  
          let yDiff = moment(yDate1).diff(moment(new Date()), 'days') - 1; 
          if (yDiff < this.iDayBack) this.iDayBack = yDiff;
          //console.log("##mgmt-emergs", yDate1, yDate2, yDiff, this.iDayBack); 
          //this.sDayBack = this.iDayBack.toString();          
        }

        //(3) rescue:  
        let Sn = "";
        if (Ut.aryRescue !== undefined && Ut.aryRescue !== null && Ut.aryRescue.length > 0) {
          for(let idx=0; idx < Ut.aryRescue.length; idx++) {
            Sn = Ut.aryRescue[idx];
            let vObj = this.searchEmergsSn(Sn);
            if (vObj === null) {
              let vObj2 = Tbl.createEvntRescue();   //create new record
              vObj2.devSn = Sn;
              vObj2.receiveTime = "/";
              vObj2.cprDuration = "/";
              vObj2.shock = "/";
              let vDev = this.searchAedItemsSn(Sn);  
              if (vDev !== null) {
                vObj2.CustName = vDev.CustName;
                vObj2.InstLoc = vDev.InstLoc;
              } else {
                vObj2.CustName = "/";
                vObj2.InstLoc = "/";              
              }
              this.tblEmerg.push(vObj2);
            } else {
              if (vObj.receiveTime !== "" && vObj.receiveTime !== "/") continue  //check if it is MasterRecord           
              let vDev = this.searchAedItemsSn(Sn); 
              if (vDev !== null) {
                if (vObj.CustName === "" || vObj.CustName === "/") vObj.CustName = vDev.CustName;
                if (vObj.InstLoc === "" || vObj.InstLoc === "/") vObj.InstLoc = vDev.InstLoc;
              }
            }
          }
        }
      },

      // DESCRIPTION:  load axios or sim data to this.aryEmergReport[]
      storeDevicesStateToTable (xAry) {
        if (xAry === undefined || xAry === null) return;
        Ut.clearArray(this.aryDeviceState);
        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          /* let vObj = Emergency.createEmergReportSn();
          vObj.devSn          = vObj2.sn;
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          //vObj.testTime       = moment(vObj2.testtime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.testTime       = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.state          = vObj2.state;
          vObj.rescueTime     = 0;
          vObj.cprDuration    = 0;
          vObj.shock          = 0;
          vObj.hidden         = false;
          this.aryEmergReport.push(vObj); */

          let vObj = Emergency.createDevStateReportSn(); 
          vObj.devSn          = vObj2.sn;
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.testTime       = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.netType        = Tbl.getTblItem(Tbl.rptNetworkType, vObj2.nettype);
          vObj.signalLevel    = vObj2.signallevel;
          vObj.status         = vObj2.state;
          if (vObj.netType === "") vObj.netType = "/";
          this.aryDeviceState.push(vObj);
          //console.log("##devState-113", vObj);  //debug
        }

        // Sort array:  newest at top of table
        if (this.aryDeviceState !== undefined && this.aryDeviceState.length > 1) {
          // most recent record at the top of table
          this.aryDeviceState.sort((a, b) => Ut.compareDates(a.receiveTime, b.receiveTime));
        }

        // DESCRIPTION:  search each deviceState SN in emergency table, find match  here*3
        if (this.aryDeviceState !== undefined && this.aryDeviceState !== null) {
          for(let idx=0; idx < this.aryDeviceState.length; idx++) {
            if (this.aryDeviceState[idx].state === "PowerOff") continue;
            let vEmergs = this.searchEmergsSn(this.aryDeviceState[idx].devSn);
            if (vEmergs === null) continue;
            let vTime1 = this.aryDeviceState[idx].receiveTime;
            let vTime2 = vEmergs.receiveTime;
            let vDiff = moment(vTime1).diff(moment(vTime2), 'seconds');    // time1 - time2
            //console.log("##storeDevicesStateToTable", this.aryDeviceState[idx].devSn, vTime1, vTime2, vDiff);
          }
        }
      },

    // ---------------------------------------------
    // DESCRIPTION:  convert axios format to devInfo format  
    storeToAedItems (xAry) {
      if (xAry === undefined || xAry === null) return;
      Ut.clearArray(this.aeditems);
      let str = "";
      for (let idx=0; idx < xAry.length; idx++) {
        let vObj2 = xAry[idx];       // get dev sn and search its most recent report
        if (vObj2.isDeleted) continue;
        let vObj = Tbl.createDevInfo();   // devinfo obj template
        vObj.id            = vObj2.id
        vObj.devSn         = vObj2.sn;
        vObj.devModel      = vObj2.model;
        vObj.battCap       = "/";
        vObj.PadsShelfLife = "/";
        //vObj.InstLoc       = vObj2.maploc;
        vObj.InstLoc       = vObj2.instLoc;
        if (vObj.InstLoc === "") vObj.InstLoc = "/";
        vObj.CustName      = vObj2.organization;
        vObj.WarrantyStatus = vObj2.warrentyMonths;
        vObj.SelfTestSendPeriod = vObj2.testperiod;
        //vObj.AssetSn      = vObj2.assetsn;
        vObj.DevStatus     = vObj2.devstatus;
        if (vObj.DevStatus === "") vObj.DevStatus = "/";
        vObj.PadStatus     = "/";
        vObj.InstTime      = moment(vObj2.instTime).format("YYYY-MM-DD HH:mm:ss"); 
        vObj.DevSelfTestPeriod = vObj2.testperiod;
        vObj.WirelessStrength  = "/";
        vObj.NetworkType       = vObj2.networktype;
        if (vObj.NetworkType === "") vObj.NetworkType = "/";
        vObj.DevAdmin          = vObj2.adminName;
        //vObj.ExtendedWarranty  = '';
        vObj.LastReportUploadTime   = "/";
        //vObj.RescueTimes       = '';
        vObj.WarrantyExpireDate = "/";
        //vObj.ICCID             = vObj2.iccid;
        vObj.LocAttr           = vObj2.locattr;
        if (vObj.LocAttr  === "") vObj.LocAttr = "/";
        vObj.Address           = vObj2.deliveryAddress;
        vObj.battSn            = vObj2.batterySN;
        
        // error in reading PIC.length for zero length array
        if (vObj2.PIC !== undefined && vObj2.PIC !== null) {
          for(let idx=0; idx < vObj2.PIC.length; idx++) {
            vObj.Pic.push(vObj2.PIC[idx]);
          } 
        }
        vObj.show = true;

        this.aeditems.push(vObj);
      }
    },


      searchSnFromMgmtRpts (xSn) {
        if (xSn === "" || this.tblMgm === undefined || this.tblMgm === null) return null;
        for(let idx=0; idx < this.tblMgm.length; idx++) {
          if (this.tblMgm[idx].devSn === xSn) return this.tblMgm[idx];
        }
        return null;
      },

      searchAedItemsSn (xSn) {
        if (xSn === "" || this.tblAed === undefined || this.tblAed === null) return null;
        for (let idx = 0; idx < this.tblAed.length; idx++) {
          if (this.tblAed[idx].devSn === xSn) return this.tblAed[idx];
        }
        return null;
      },

      searchTestRptSn (xSn) {
        if (xSn === "" || this.testrpts === undefined || this.testrpts === null) return null;
        for (let idx = 0; idx < this.testrpts.length; idx++) {
          if (this.testrpts[idx].sn === xSn) return this.testrpts[idx];
        }
        return null;
      },      

      searchEmergsSn (xSn) {
        if (xSn === "" || this.tblEmerg === undefined || this.tblEmerg === null) return null;
        for (let idx = 0; idx < this.tblEmerg.length; idx++) {
          if (this.tblEmerg[idx].devSn === xSn) return this.tblEmerg[idx];
        }
        return null;
      },
     
      // ----------------------------------------------------------------------
      //              [snackBar]
      // ----------------------------------------------------------------------      
      snackStart(xmsg, xcolor) {
        if (this.snackBar.on) {
          this.snackStop();
        }
        this.snackBar.message = xmsg;
        this.snackBar.color = xcolor;
        this.snackBar.on = true;
        this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
      },

      snackTimerEvent() {
        this.snackBar.on = false;
        this.tmrObj = null;        
        if (this.snackBar.message === this.snackMsgLogout) {
          this.snackBar.message = '';
          this.$root.$emit('App_Logout', "112");
        }
        this.snackBar.message = '';
      },

      snackStop() {
        clearTimeout(this.tmrObj);
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
      },


      // ----------------------------------------------------------------------
      //              [
      // ----------------------------------------------------------------------  

      searchPadsSt3mon(xSn) {
        if (xSn === "" || this.aryPadSt3mon === undefined || this.aryPadSt3mon === null) return null;
        for(let idx=0; idx < this.aryPadSt3mon.count; idx++) {
          if (xSn === Ut.padsSt3mon[idx].sn) return Ut.padsSt3mon[idx].padExpiry;
        }
        return null;
      },

      searchPadsExpire(xSn) {
        if (xSn === "" || this.aryPadExpire === undefined || this.aryPadExpire === null) return null;
        for(let idx=0; idx < this.aryPadExpire.count; idx++) {
          if (xSn === Ut.padsExpire[idx].sn) return Ut.padsExpire[idx].padExpiry;
        }
        return null;
      },


      // ---------------------------------------------
      // DESCRIPTION: reload Home Page
      async loadTables () {
        this.showProgress = true;
        /* if (this.aeditems === null) {
          let vdata = await this.axiosGetDevInfoV1();
          if (vdata !== undefined && vdata !== null) { 
            try { this.storeToAedItems(vdata); }
            catch(err) { Ut.noop(); }
          }
        } else {
          if (this.aeditems.length === 0) {
            let vdata = await this.axiosGetDevInfoV1();
            if (vdata !== undefined && vdata !== null) {
              try { this.storeToAedItems(vdata); }
              catch(err) { this.showProgress = false; }
            }
          }
        } */

        Ut.clearArray(this.aeditems);
        let vdata = await this.axiosGetDevInfoV1();
        if (vdata !== undefined && vdata !== null) { 
          try { this.storeToAedItems(vdata); }
          catch(err) { this.showProgress = false; }
        }

        vdata = await this.axiosGetEmergencyLatest();
        if (vdata !== undefined && vdata !== null && vdata.length > 0) {
          try {
            Ut.copyArray(this.emergrpts, vdata);
            this.storeEmergToTable(this.emergrpts);          
          } catch(err) { this.showProgress = false; }
        }

        vdata = await this.axiosGetTestReportLatest(); 
        if (vdata !== undefined && vdata !== null && vdata.length > 0) {
          try {
            Ut.copyArray(this.testrpts, vdata);              
            this.storeTestReportToEvntTable(this.testrpts);        
          } catch(err) { this.showProgress = false; }
        }

        if (this.tblMgm !== undefined && this.tblMgm !== null && this.tblMgm.length > 0) {
          this.setTab();
        }        

        // Load Dashboard data
        this.aryPadSt3mon = Ut.padsSt3mon;
        this.aryPadExpire = Ut.padsExpire;        

        this.showProgress = false;
      }

      // end of method
    },


    mounted () {
      if (cookies.exist("aa") === false) {
        this.$router.push('/');  // access this page without auth, back to home
      }
      this.$root.$emit('App_TopBar', true);
      // this.$root.$on('tbl2_SelSnEmit', (rxData) => { this.rxTblEmit(rxData) })
      this.$root.$on('emitMgmt', (rxData) => { this.onEmitMgmt(rxData) })

      this.objRoute = this.$route.params;   // normally, params = {"tab":"Fault", "value":0}
      // "tab" value if present:   Fault / Warning / Offline / Rescue / UserTest
      if (!("tab" in this.objRoute)) {  
        // source:  topBar tabs change, AND browser "reload",  
        //console.log("##eMgmt-mounted1");
        this.objRoute = {"tab":"UserTest", "value":0}; 
      } else {
        // source:  dash --> eMgmt by Fault/Warning/.... 
        //          value=0, value=1(battery), value=2(pads)
        //console.log("##eMgmt-mounted2", this.objRoute);
      }
      //this.setTab();

      //(2) Load Test Report to local table: this.tblMgm[]
      this.loadTables();

      // end of mounted()
    }
  }
</script>


<style scoped>

#idCard1 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  background-color: #37474F;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */    
}

#idCard1::-webkit-scrollbar {
    display: none;
}

#idCard2 {
  /* background-color: #37474F; */
  background-color: #37474F;
  height: calc(100vh - 165px);
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

/*
.theme--dark.v-tabs-items {
  background-color: black !important;
} */ 

</style>
