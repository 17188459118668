<!-- --------------------------------------------------------------------------------
                      DatePicker.vue

DESCRIPTIONS
  - display the "dayback" from today  (dayback : 0 to -xxxx)
  - click at DatePicker to display calendar

USAGE
  - <DatePicker :title="sDate1" :dayback="iDayBack" @DatePicker="onDateChange1"/>
  - change iDayBack        // 0 to -xxxx

Note:
  - parent container height >= 47px

---------------------------------------------------------------------------------- -->
<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
    :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="date" :label="title" :prepend-icon="pathDate" 
         readonly v-bind="attrs" v-on="on" class="fontB">
      </v-text-field>
    </template>
    <v-date-picker v-model="date" @change="onDateChange" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false" class="fontB">Cancel</v-btn>
      <!-- <v-btn text color="primary" @click="onBtnkOk(date)">OK</v-btn>  -->
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCalendarToday  } from '@mdi/js';

  export default {
    name: 'DatePicker',

    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: 'Select a Date'},
      dayback: {type: String, default: '0'}
    },

    data () {
      return {
        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: '',
        iDate: 0,
        menu: false,
        pathDate: mdiCalendarToday
      }
    },

    methods: {
      onBtnkOk (xdate) {
        this.$refs.menu.save(xdate);
        this.$emit('DatePicker', xdate);
      },

      onDateChange(xdate) {
        //console.log('date-picker', xdate);  //debug
        this.$refs.menu.save(xdate);
        this.$emit('DatePicker', xdate);
        this.menu = true;
      }

    },

    watch: {
      dayback: function(newVal) {
        this.iDate = Number(newVal); 
        this.date = moment().add(this.iDate, 'days').format("YYYY-MM-DD");
        this.$emit('DatePicker', this.date);          
        //console.log("##DatePicker-watch", newVal);
      }
    },

    mounted () {
      this.iDate = Number(this.dayback); 
      this.date = moment().add(this.iDate, 'days').format("YYYY-MM-DD");
      this.$emit('DatePicker', this.date);
    }
  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
