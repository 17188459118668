<!-- --------------------------------------------------------------------------------
                      DevStatus.vue

DESCRIPTIONS
  - display total warning signals collected

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]
---------------------------------------------------------------------------------- -->
<template>

  <v-card :width="width" :height="height" :color="bgcolor" class="pt-0 rounded-lg">
    
    <!-- <v-sheet width="40px" class="mx-auto pa-0 ma-0 transparent"><v-icon large color="green"> {{ `${vIcon}` }}</v-icon></v-sheet> -->
    <!-- <v-sheet width="30px" height="30px" class="mx-auto mt-n2 pa-0 mdi mdi-36px transparent" :class="vIcon.icon" :color="vIcon.color"></v-sheet> -->
    <!-- <v-sheet :color="bgcolor" class="text-left ml-2 mt-1 white--text fontB">{{ title }}</v-sheet> -->
    
    <v-card width="80%" height="80%" class="mx-auto pa-0 transparent d-flex elevation-0 border-0">

      <!------- (1) Left: icon ------------->
      <v-card width="50px" height="50px" class="mx-auto transparent elevation-0 border-0">
        <!-- <v-sheet v-if="showCircle==true" width="100%" height="100%" :color="iconColor" class="ml-2 mt-2 pa-0 rounded-pill elevation-0 border-0" 
          style="filter: blur(2px); opacity: 0.4; position: absolute; top:5; left:1;" >
        </v-sheet>  -->

        <v-sheet width="40px" height="40px" class="ml-1 mt-5 pa-0 rounded-pill transparent text-center elevation-0 border-0">
          <v-card v-if="iconType===1" width="50" height="50" class="ma-0 pa-0 transparent elevation-0">
            <v-sheet width="45" height="45" :color="iconColor" class="ma-0 pa-0 rounded-pill text-center" 
              style="opacity: 0.4; position: absolute; top: 0px; left: 10px;"></v-sheet>
            <svg-icon v-if="iconLevel===0" type="mdi" :path="pathCellular0" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathCellular1" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathCellular2" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathCellular3" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathNoSim" :color="iconColor" size="36" class="iconStyle"></svg-icon>
          </v-card>

          <v-card v-if="iconType===0" width="50" height="50" class="ma-0 pa-0 transparent  elevation-0">
            <v-sheet width="45" height="45" :color="iconColor" class="ma-0 pa-0 rounded-pill text-center" 
              style="opacity: 0.4; position: absolute; top: 0px; left: 10px;"></v-sheet>
            <svg-icon v-if="iconLevel===0" type="mdi" :path="pathWifi0" :color="iconColor" size="36" class="text-center iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathWifi1" :color="iconColor" size="36" class="text-center iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathWifi2" :color="iconColor" size="36" class="text-center iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathWifi3" :color="iconColor" size="36" class="text-center iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathWifi4" :color="iconColor" size="36" class="text-center iconStyle"></svg-icon>            
          </v-card>

        </v-sheet>
      </v-card>

      <!------- (2) Right: Text ------------->
      <v-sheet width="80%" class="ml-6 mt-n1 pa-0 transparent elevation-0 border-0">.
        <v-sheet class="text-left ml-0 mt-1 pa-0 white--text transparent elevation-0 border-0" 
          style="font-family: Montserrat; font-size: 1.1em;">{{ levels[1].value }}</v-sheet>
        <v-sheet class="text-left ml-0 mt-n1 pa-0 grey--text text--lighten-1 transparent"
          style="font-family: Montserrat; font-size: 0.9em;">{{ levels[1].text }}</v-sheet>
      </v-sheet>

    </v-card>

    <v-card width="100%" height="auto" class="mt-n1 ml-2 pa-1 transparent elevation-0 border-0">
      <v-sheet width="100%" height="100%" class="text-right pr-5 grey--text text--lighten-1 transparent fontSmall"
      style="font-family: Montserrat; font-size: 0.7em; font-weight: normal;">{{ levels[1].msg }}</v-sheet>
    </v-card>    
    
    <!-- <v-sheet width="30px" height="30px" class="mx-auto mt-n2 pa-0 transparent">
      <v-sheet v-if="iconType===1" width="100%" height="100%" class="mt-2 mx-0 pa-0 transparent">
      <svg-icon v-if="iconLevel===0" type="mdi" :path="pathCellular0" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathCellular1" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathCellular2" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathCellular3" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathNoSim" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

      <v-sheet v-if="iconType===0" width="100%" height="100%" class="mt-2 mx-0 pa-0 transparent">
      <svg-icon v-if="iconLevel===0" type="mdi" :path="pathWifi0" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathWifi1" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathWifi2" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathWifi3" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathWifi4" :color="iconColor" size="40"></svg-icon>
      </v-sheet>
    </v-sheet>    

      <v-list dense :color="bgcolor" class="mt-2"> 
      <v-list-item-group  color="transparent">
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0 transparent">
            <v-list-item-content class="pa-0 ml-1 transparent">
              <v-row no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"><span class="grey--text fontN" style="font-size: 0.9em;">{{ item.text }}</span></v-col>
                <v-col cols="7"><span :class="item.valueColor" style="font-style: bold; font-size: 0.8em; font-family: Montserrat;">{{ item.value }}</span></v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>  -->

  </v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiSignalCellularOutline, mdiSignalCellular1, mdiSignalCellular2 , mdiSignalCellular3, mdiSimOff, 
  mdiWifiStrengthOutline, mdiWifiStrength1, mdiWifiStrength2, mdiWifiStrength3, mdiWifiStrength4 } from '@mdi/js';

  export default {
    name: 'DevStatus2Nwk',
    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      iconType: {type: Number, default: 0},
      iconLevel: {type: Number, default: 0},
      iconColor: {type: String, default: "gray"}
    },

    data () {
      return {
        pathCellular0: mdiSignalCellularOutline,
        pathCellular1: mdiSignalCellular1,
        pathCellular2: mdiSignalCellular2,
        pathCellular3: mdiSignalCellular3,
        pathNoSim: mdiSimOff,
        pathWifi0: mdiWifiStrengthOutline,
        pathWifi1: mdiWifiStrength1,
        pathWifi2: mdiWifiStrength2,
        pathWifi3: mdiWifiStrength3,
        pathWifi4: mdiWifiStrength4,
        showCircle: true
      }
    },

    methods: {
      getListColor (xValue) {
        if (xValue.indexOf('Normal') >=0) {
          return 'green--text';
        } else {
          return 'red--text';
        }
      }

    },

    watch: {
      // iconLevel: function(newVal) { console.log('##iconLevel', newVal); }
    },

    mounted () {
      if (this.levels[1].value === "N/A") this.showCircle = false;
      else this.showCircle = true;
    }    

  }
</script>

<style scoped>

.iconStyle {
  filter: contrast(100%) brightness(120%)  !important; 
  opacity: 1.0 !important;
  position: absolute; 
  top: 5px; 
  left: 15px;  
}


.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}

.v-list-item {
  min-height: 20px;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
