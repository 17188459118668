var moment = require('moment');

const SysConf = {
  "http": "",
  "SimData": false,
  "language": 'eng',
  "tokenExpire": "60min",
  "OfflineDays": 1,
  "OfflineDays_OffsetHours": 1
}

const SysPara = {
  "loginTitle1": "Easy Management",
  "loginTitleColor1": "amber--text text--darken-3",
  "loginTitle2": "Remote AED Management System",
  "loginTitleColor2": "white--text",
  "loginTitle3": "©2024 Hong Kong Medisource Supply (Asia) Co. Ltd.",
  "loginVer": "1.0",

  "aboutTel": "2145 4442",
  "aboutFax": "2145 4445",
  "aboutEmail": "enquiry@hk-aed.com",
  "aboutAddress": "Unit 01-04, 32/F, New Trend Centre, No. 104 King Fuk Street, San Po Kong, KLN, Hong Kong",
  "aboutFacebook": "https://www.facebook.com/medisource"
}

const Limits = {
  pads: { "date0": 0, "date1": 90, "date2": 365 },
  batt: { "value0": 10.0, "value1": 25.0, "value2": 35.0 }
}

const Login = {
  username: "User1",
  password: "abcd1234"
}




// =============================================================================================
//             Global Variables
// =============================================================================================
const HttpAddr = "http://192.168.28.164:6005/";

let UserNamePwd = { "username": "User1", "password": "abcd1234", "token": "", "loginState": 0 };
function WriteToken(xstr) {
  UserNamePwd.token = xstr;
}

let currentUser = {};
function WriteUser(xobj) {
  currentUser = xobj;
}
function createUser() {
  return {
    "id": 0,
    "username": "",
    "phone1": "",
    "phone2": "",
    "address": "",
    "email1": "",
    "email2": "",
    "name": "",
    "userType": 0,
    "isActive": null,
    "isDeleted": false,
    "createdAt": "",
    "updatedAt": "",
    "addedBy": null,
    "updatedBy": null,
    "email": "",
    "mobileNo": "",
    "token": ""
  }
}

let otp2fa = {};
function WriteOtp2Fa(xobj) {
  otp2fa = xobj;
}
function createOtp2fa() {
  return {
    "phone1": "",
    "phone2": "",
    "mobileNo": "",
    "address": "",
    "email1": "",
    "email2": "",
    "password": "",
    "username": "",
    "email": "",
    "name": "",
    "userType": 0
  }
}


// DESCRIPTION:  save management page tab status
let tabMgmt = "";
function writeTabMgmt(xstr) {
  tabMgmt = xstr;
}

// DESCRIPTION:  save dashboard-PadsShelfLife-detail-st3mon
let padsSt3mon = [];
function writePadsSt3mon(xary) {
  padsSt3mon = xary;
}

let padsExpire = [];
function writePadsExpire(xary) {
  padsExpire = xary;
}

// DESCRIPTION:  st35 35% + st20 20% + critical 10%
let batt35 = [];
function writeBatt35(xary35, xary20, xary15) {
  batt35 = xary35;
  if (xary20 !== undefined && xary20 !== null) {
    for(let idx=0; idx < xary20.length; idx++) {
      batt35.push(xary20[idx]);
    }
  }
  if (xary15 !== undefined && xary15 !== null) {
    for(let idx=0; idx < xary15.length; idx++) {
      batt35.push(xary15[idx]);
    }
  }
}

let aryFault = []
function writeAryFault(xary) {
  aryFault = xary;
}

let aryRescue = []
function writeAryRescue(xary) {
  aryRescue = xary;
}


// =============================================================================================
//             Ut Functions
// =============================================================================================

function capitalize(xstr) {
  let str = xstr;
  if (str.length === 0) return "";
  if (str.length === 1) return xstr.toUpperCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// DESCRIPTION:  xdate - now,  expired if return <= 0
function getDateDiff(xdate) {
  // return (new Date(xdate) - new Date()) / (1000 * 3600 * 24);
  return moment(new Date(xdate)).diff(moment(new Date()), 'days');
}

// DESCRIPTION:  xdate1 and xdate2 are string
function compareDates(xdate1, xdate2) {
  //return (new Date(xdate2) - new Date(xdate1)) / (1000 * 3600 * 24);
  return moment(new Date(xdate2)).diff(moment(new Date(xdate1)), 'days');
}

function getFileNameFromPath(xPath) {
  return xPath.substring(xPath.lastIndexOf('/') + 1);
}

function clearArray(xary) {
  if (xary !== undefined && xary !== null && xary.length > 0) {
    xary.splice(0);
    xary.length = 0;
  }
}

function copyArray(xdes, xsrc) {
  if (xsrc === undefined || xsrc === null || xsrc.length === 0) return;
  clearArray(xdes);
  for (let idx = 0; idx < xsrc.length; idx++) {
    xdes.push(xsrc[idx]);
  }
}

function noop() {
  return;
}



// =============================================================================================
//             Fault, Warning, Offline, Rescue / Batt Status, Pads Status, Warranty
//
// called from:  DevInfo.vue\processTestReport()
//     - devSn = this.aeditems[irow].devSn;
//     - vT = this.findSnFromTestReport(devSn); 
//     - vEmerg = this.findSnFromEmergencyReport(devSn); 
// =============================================================================================

//-----------------------------------------------------
//      Emergency Status
// Input:  vEmerg.datetime
//-----------------------------------------------------
function getEmergencyStatus(xdate) {
  return xdate;
}

//-----------------------------------------------------
//      Fault Status
// Input:  xRes  = vTestInfo.testresult
//         xCode = vTestInfo.testresult
// Return: true if Fault, false if Pass
//-----------------------------------------------------
function getFaultStatus(xRes, xCode) {
  //if (xRes === 0 || (xCode !== "/" && xCode !== "")) {
  if (xRes !== 0 && xCode !== "/" && xCode !== "") {
    return true;
  }
}

//-----------------------------------------------------
//      Battery Status
// Input:  xRes  = vTestInfo.testitems.batResidualCapacity.result
//         xdate = testitems.batTotalCapacity.result
//-----------------------------------------------------
const battLow0 = Limits.batt.value0;
const battLow1 = Limits.batt.value1;
const battLow2 = Limits.batt.value2;
function getBattStatus(xRes, xTot) {
  if (xTot === 0 || xRes === 0) {
    return { "Status": "Batt Fault", "Capacity": "0", "numCapacity": 0 };
  }

  let vBattCap = 0;
  try {
    //vBattCap = parseInt((xRes * 100) / xTot);
    vBattCap = parseFloat((xRes * 100) / xTot).toFixed(1);
    /* if (vBattCap <= battLow1) {
      return { "Status": "Batt Low", "Capacity": vBattCap + "%" };
    } else if (vBattCap <= battLow2) {
      return { "Status": "Batt Low", "Capacity": vBattCap + "%" };
    } */
    //if (xRes < 1500) return { "Status": "Batt Low", "Capacity": vBattCap + "%" }; 
    if (vBattCap <= battLow2) {        // include 35.0
      return { "Status": "Batt Low", "Capacity": vBattCap + "%", "numCapacity": vBattCap };
    } 
  } catch (err) {
    return { "Status": "error", "Capacity": "error", "numCapacity": 0 };
  }

  return { "Status": "Normal", "Capacity": vBattCap + "%", "numCapacity": vBattCap };
}

//-----------------------------------------------------
//      Pads Status
// Input:  xIsExpired = vTestInfo.testitems.padsExpiried.result
//         xdate      = vTestInfo.testitems.padsExpiringDate.result
//-----------------------------------------------------
const padsExpire3Month = Limits.pads.date1;   // 90 days
const padsExpire12Month = Limits.pads.date2;  // 365 days
function getPadsStatus(xIsExpired, xdate) {
  // let expireDate = vTestInfo.testitems.padsExpiringDate.result;
  // let expired = vTestInfo.testitems.padsExpiried.result;
  let expireDate = xdate.trim();
  let expired = xIsExpired;
  //let dateDiff = moment(new Date(expireDate)).diff(moment(new Date()), 'days'); //expireDate - now
  let dateDiff = moment(new Date(expireDate)).diff(moment(new Date()), 'hours') / 24; //expireDate - now
  //case 1:  expiry date empty --> check expired flag only
  if (expireDate === "") {
    if (expired > 0 || dateDiff < 0) return "Expired";
    else return "Normal"; 
  }
  if (expired > 0 || dateDiff < 0.0) {  //expired
    //return { "Status": "Expired", "PadsShelfLife": expireDate };
    return "Expired";
  } else {
    if (dateDiff < padsExpire3Month) {  //expired soon
      //return { "Status": "Expire in 3 months", "PadsShelfLife": expireDate };
      return "3";
    } 
    else if (dateDiff < padsExpire12Month) {
      //return { "Status": "Expire in 12 months", "PadsShelfLife": expireDate };
      return "12";
    } }
  return "Normal";
}

//-----------------------------------------------------
//      Warranty Status
// Input:  xdate = aeditems[irow].WarrantyExpireDate
//-----------------------------------------------------
const warrExpire12Month = 365
function getWarrantyStatus(xdate) {
  let expireDate = xdate.tirm();
  if (expireDate !== "") {
    let dateDiff = getDateDiff(expireDate);
    if (dateDiff <= 0) {
      return "Expired";
    } else {
      /* if (dateDiff <= warrExpire12Month) {
        return "Expire within 1 year";
      } */
      return "in warranty";
    }
  } else {
    //this.aeditems[irow].WarrantyStatus = "";
  }
  return "";
}

//-----------------------------------------------------
//      RelatedTest Result
// Input:  object of relatedtest
//-----------------------------------------------------
function getRelatedTestResult(xObj) {
  if (xObj == undefined || xObj == null) return null;
  let str = "";
  if ('key' in xObj && xObj.key.result == 0) {
    str = "key";
  }
  if ('voice' in xObj && xObj.voice.result == 0) {
    if (str == "") str = "voice";
    else str = str + ",voice";
  }
  if ('j200' in xObj && xObj.j200.result == 0) {
    if (str == "") str = "j200";
    else str = str + ",j200";
  }
  if ('j360' in xObj && xObj.j360.result == 0) {
    if (str == "") str = "j360";
    else str = str + ",j360";
  }
  if (str == "") return "Normal";
  return str;
}




//-----------------------------------------------------
//      Network Status
// Input:  xType = vTestInfo.netinfo.NetType
//-----------------------------------------------------
function getNetworkStatus(xType) {
  if (xType === 0) {
    return "Wifi";
  } else if (xType === 1) {
    return "4G";
  }
  return "N/A";
}

//-----------------------------------------------------
//      Offline Status
// Input:  xdate =     vTestInfo.datetime
//         xTxPeriod = vTestInfo.netinfo.TransmissionPeriod
// Return: number of days offlined (positive number)
//         else return 0
//-----------------------------------------------------
function getOfflineStatus(xdate, xTxPeriod) {
  if (xdate === null || xdate === undefined) return Number.MIN_VALUE;
  //let dateDiff = this.getDateDiff(xdate);
  let dateDiff = getDateDiff(xdate);
  if (dateDiff < 0) {
    if (dateDiff <= xTxPeriod * (-1)) {
      return Math.floor(dateDiff * -1);
    }
  }
  return 0;
}

function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}




// =============================================================================================
//             Export
// =============================================================================================

export {
  SysConf, HttpAddr, WriteToken, UserNamePwd, Limits, SysPara, Login,
  currentUser, WriteUser, createUser, otp2fa, WriteOtp2Fa, createOtp2fa,
  getFaultStatus, getEmergencyStatus, getBattStatus, getPadsStatus, getWarrantyStatus,
  getNetworkStatus, getOfflineStatus,
  capitalize, compareDates, getDateDiff, clearArray, copyArray,
  getFileNameFromPath, writeTabMgmt, tabMgmt, noop, 
  padsSt3mon, padsExpire, aryFault, batt35, writePadsSt3mon, writePadsExpire, writeAryFault,
  writeBatt35, aryRescue, writeAryRescue, 
  getRelatedTestResult
};


/* =======================================================
Vue.prototype.aeditems = []    // not raw data
Vue.prototype.testrpts = []    // latest test report, raw data
Vue.prototype.emergrpts = []   //  raw data


======================================================= */

