<!-- --------------------------------------------------------------------------------
                      DialogMessage.vue

DESCRIPTIONS
  - Display Messages:  error message, warning message, ...

USAGE:
  <DialogMessage :message="sMessage" :bActivate="bAct" @DialogClose="onMsgClose"/>
  open:    bAct = true
  close:   at onMsgClose() { bAct=false }
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">
    <v-dialog v-model="bDialog" width="500">
      <v-card>
        <v-card-title class="grey lighten-2 fontB">
          Error
        </v-card-title>

        <v-card-text class="fontN">{{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="primary" text @click="onBtnClose">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: 'DialogMessage',

    components: {
      
    },

    props: {
      message: {type: String, default: ''},
      pAct: {type: Number, default: 0}
    },

    data () {
      return {
        bDialog: false
      }
    },

    methods: {
      onBtnClose () {
        // console.log('DialogMessage','onBtnClose');
        this.$emit('DialogClose');
        this.bDialog = false;
      }
    },

    watch: {
      pAct: function (newVal) {
        this.bDialog = true; 
      },

      bDialog: function (newValue) {
        if (!newValue) {
           this.$emit('DialogClose');
        }
      }
    }

  }
</script>

<style scoped>

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 22px;
  font-weight: bold;   
  color: black;
}

.cBorder {
  border: 1px solid grey;
}
</style>
