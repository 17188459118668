<!-- --------------------------------------------------------------------------------
                      InputBox2.vue

DESCRIPTIONS
  - label & input, 
  - no box outline

Note:
  - 

---------------------------------------------------------------------------------- -->
<template>
  <v-card width="100%" height="100%" class="ma-0 pa-0 d-flex flex-row transparent elevation-0 border-0">

    <v-sheet :width="width1" class="mt-0 transparent border-0 elevation-0 rounded-r-0 rounded-l-lg"><h5 class="ml-2 mt-2 fontN">{{ label }}</h5></v-sheet>
    <v-text-field regular :width="width2" dense light :placeholder="message" type="input" v-model="keybuff" hide-details
       class="ma-0 pa-0 text-decloration-none elevation-0 rounded-l-0 rounded-r-lg fontN" @keyup="onKeyUp" solo  
       :spellcheck="false" :readonly="readonly">
    </v-text-field>

  </v-card>
</template>

<script>
  export default {
    name: 'InputBox2',

    props: {
      label: {type: String, default: ''},
      message: {type: String, default: ''},
      readonly: {type: Boolean, default: false},
      width1: {type: String, default: '35%'},
      width2: {type: String, default: '65%'},
      id: {type: String, default: '0'},
      capital: {type: Boolean, default: false}
    },

    data () {
      return {
        keybuff: '',
        keybuffMax: 50
      }
    },

    methods: {
      onKeyUp (evn) {
        if (this.keybuff.length >= this.keybuffMax + 1) {
          this.keybuff = this.keybuff.substring(0, this.keybuffMax)
          return
        }
        if (evn.keyCode >= 0x20 || evn.keyCode === 13) {
          this.$emit('msgInputBox1', { 'id': this.id, 'text': this.keybuff} );
          return;
        }
        if (this.capital) {
          this.keybuff = this.keybuff.toUpperCase();
        }
      }
    },

    mounted () {
      this.keybuff = this.value;
    },

    watch: {
      message: function (newVal) {
        this.keybuff = newVal;
        this.$emit('msgInputBox1', { 'id': this.id, 'text': this.keybuff} );
        // console.log('keybuff', this.keybuff);
      }
    }

  }
</script>

<style scoped>


.v-text-field {
  min-height: 20px;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
