<!-- --------------------------------------------------------------------------------
                      OtpCard.vue

DESCRIPTIONS
  - handle OTP 2FA authentication
  - New login:  username/email + password + OTP code
  - access   /auth/send_otp_2fa     to request email containing OTP code by name + Pwd
  - access   /auth/login_with_2fa   to request token by OTP code, name + Pwd
---------------------------------------------------------------------------------- -->
<template>
<div>
    <div class="ma-auto position-relative transparent d-flex flex-row" style="max-width: 300px">
      <v-otp-input plain type="number" v-model="otp" :disabled="false" @finish="onFinish" class="transparent fontN"></v-otp-input>
      <v-btn x-small class="ml-2 mt-2 blue-grey lighten-4 black--text text-capitalize fontB" @click="onBtnClear"><h4>Clear</h4></v-btn>
    </div>
  
    <v-snackbar right v-model="bSnackbar" :color="snackbarColor" :timeout="3000" class="fontB">{{ text }}</v-snackbar>
    
    <!-- <v-overlay absolute :value="true">  
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>  -->
    
</div>
</template>

<script>

export default {

  props: {
    // Loading: {type: Boolean, default: true}
    pReset: {type: Number, default: 0}
  },

  data () {
    return {
      bLoading: false,
      otp: '',
      bSnackbar: false,
      snackbarColor: 'default',      
      text: ''
    }
  },

  methods: {
    onFinish (rsp) {
      // this.bLoading = true;
      /* setTimeout(() => {
      this.bLoading = false
      this.snackbarColor = (rsp === this.expectedOtp) ? 'success' : 'warning'
      this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
      this.bSnackbar = true
      }, 500)  */

      // this.axiosLogin2fa(rsp);
      let sRet = rsp.trim();
      this.$emit('OtpCard', {"id": "2", "text": sRet});  // send OTP to LoginLogo2.vue
    },

    onBtnClear () {
      // this.$emit('OtpCard', {"id": "1", "text": ""});
      this.otp = "";
    },

    snackBar (xmsg, xcolor) {
      this.snackbarColor = xcolor;
      this.text = xmsg;
      this.bSnackbar = true;
    },
   
  },

  watch: {
    pReset: function(newVal) {
      this.otp = "";
    }
  }
    
}

</script>

<style scoped>


 .position-relative {
   position: relative;
 }

 .fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


</style>
