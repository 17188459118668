<!-- --------------------------------------------------------------------------------
                      DialogPassword.vue

DESCRIPTIONS
  - Change Password

USAGE:
  <DialogPassword :message="sMessage" :bActivate="bAct" @DialogClose="onMsgClose"/>
  open:    bAct = true
  close:   at onMsgClose() { bAct=false }
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">
    <v-dialog v-model="bDialog" width="600px" height="640px" color="transparent">
      <!-- <div width="98%" height="500px" class="transparent"> -->
      <!-- <v-container class="ma-0 pa-0 bg-surface-variant rounded-t-xl rounded-b-xl overflow-hidden">  -->
      <v-card class="ma-0 pa-0 rounded-t-xl rounded-b-xl overflow-hidden">

        <v-card height="40px" class="border-0 rounded-b-0" style="background-color: #546E7A;">
          <v-btn icon small color="white" @click="bDialog= false" class="ma-1 pa-0" style="position: absolute; right:1px;">
            <svg-icon type="mdi" :path="pathClose" size="32"></svg-icon>
          </v-btn> 
          <v-layout justify-center class="pt-1 white--text fontB">{{ pTitle }}</v-layout>
        </v-card>

        <!-- <v-card-title class="ma-0 pa-0 justify-center white--text" style="background-color: #546E7A;">Change Password
        </v-card-title>
        <v-btn icon small color="white" class="mt-1" @click="onBtnClose" style="position: relatve; right:0;">
          <svg-icon type="mdi" :path="pathClose"></svg-icon></v-btn>   -->

        <v-card width="100%" height="300px" class="ml-0 mt-0 py-1 border-0 elevation-0 rounded-t-0 roundded-b-lg">
      
          <v-row class="pl-2 py-auto ml-5 mt-5 d-flex cBottLine">
            <v-col cols="5" class="pa-0 ma-0">
              <v-sheet class="ma-0 pa-0 black--text fontB">Current User Name</v-sheet>
            </v-col>
            <v-col cols="7" class="pa-0 ma-0 black--text fontN">{{ sUserName }}</v-col>
          </v-row>

          <v-row class="pl-2 py-auto ml-5 mt-8 d-flex flex-row cBottLine">
            <v-col cols="5" class="pa-0 ma-0">
              <v-sheet class="ma-0 pa-0 black--text fontB">Current Password</v-sheet>
            </v-col>
            <v-col cols="7" class="pa-0 ma-0">
              <v-text-field dense v-model="sOldPw" label="Enter your current password" variant="plain" 
              class="text-decloration-none black--text fontN" type="password" :spellcheck="false" hide-details></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pl-2 py-auto ml-5 mt-10 d-flex flex-row cBottLine">
            <v-col cols="5" class="pa-0 ma-0">
              <v-sheet class="ma-0 pa-0 black--text fontB">New Password</v-sheet>
            </v-col>
            <v-col cols="7" class="pa-0 ma-0">
              <v-text-field dense v-model="sNewPw" label="Enter your new password" variant="plain" 
              class="text-decloration-none black--text fontN" type="password" :spellcheck="false" hide-details></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pl-2 py-auto ml-5 mt-8 d-flex flex-row cBottLine">
            <v-col cols="5" class="pa-0 ma-0">
              <v-sheet class="ma-0 pa-0 black--text fontB">Confirm Password</v-sheet>
            </v-col>
            <v-col cols="7" class="pa-0 ma-0">
              <v-text-field dense v-model="sNewPw2" label="Enter your new password again" variant="plain" 
              class="text-decloration-none black--text fontN" type="password" :spellcheck="false" hide-details></v-text-field>
            </v-col>
          </v-row>          

          <v-row class="pl-2 py-auto ml-5 mt-5">
            <v-sheet v-if="this.respMsg.success==true" class="blue--text fontB">{{ respMsg.message }}</v-sheet>
            <v-sheet v-if="this.respMsg.success==false" class="red--text fontB">{{ respMsg.message }}</v-sheet>
          </v-row>

          <v-sheet class="mr-3 mb-2" style="position: absolute; bottom: 0; right: 0;">
            <v-btn height="25px" class="py-auto green accent-3 white--text text-capitalize rounded fontB" @click="onConfirm">Confirm</v-btn>
          </v-sheet>

        </v-card>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose } from '@mdi/js';
import cookies from '@/js/Cookie.js'

  export default {
    name: 'DialogPassword',

    components: {
      SvgIcon
    },

    props: {
      pTitle: {type: String, default: ''},
      pAct: {type: Number, default: 0}
    },

    data () {
      return {
        bDialog: false,
        pathClose: mdiClose,
        sUserName: "",
        sOldPw: "",
        sNewPw: "",
        sNewPw2: "",
        respMsg: {"message": "", "success": false}
      }
    },

    // ============= Method =============
    methods: {
      onBtnClose () {
        // console.log('DialogKeyValue','onBtnClose');
        this.$emit('DialogClose');
        this.bDialog = false;
      },

      //-------------------------------------------
      //         loadUserInfo
      // DESCRIPTION:  Load current login username
      //-------------------------------------------
      loadUserInfo () {
        if (!cookies.exist("us")) return;
        let str = cookies.get("us");
        let vObj = JSON.parse(str);
        this.sUserName = vObj.Username;
      },

      //-------------------------------------------
      //         onConfirm
      // DESCRIPTION:  Confirm new password
      //-------------------------------------------
      onConfirm () {
        let vOldPw = this.sOldPw.trim();
        let vPw1 = this.sNewPw.trim();
        let vPw2 = this.sNewPw2.trim();
        if (vOldPw === undefined || vOldPw === "") {
          this.respMsg.message = "Current password cannot be empty";
          this.respMsg.success = false;
          this.sOldPw = "";
          return;
        }        
        if (this.sNewPw === undefined || vPw1 === "") {
          this.respMsg.message = "New password cannot be empty";
          this.respMsg.success = false;
          this.sNewPw = "";
          return;
        }
        if (this.sNewPw2 === undefined || vPw2 === "") {
          this.respMsg.message = "Confirm password cannot be empty";
          this.respMsg.success = false;
          this.sNewPw2 = "";
          return;
        }
        if (vPw1 !== vPw2) {
          this.respMsg.message = "Both password not matched";
          this.respMsg.success = false;
          return;
        }        

        let vObj = {
          "old": this.sOldPw,
          "new": vPw1
        };
        this.axiosChangePassword(vObj);
      },

      //-------------------------------------------
      //         Axios
      //-------------------------------------------      
      async axiosChangePassword (xPassword) {
        this.sRespMsg = "";
        let vObj = {
          'method': 'PUT',
          'url':  this.$config.apiBaseUrl + '/client/api/v1/user/change-password',
          'headers': {
            //'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get()
            'Authorization': 'Bearer ' + cookies.get('kk')
          },
          'data': {
            'oldPassword': xPassword.old,
            'newPassword': xPassword.new
          }
        }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          // alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: null }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            //console.log("##axiosChangePassword: Change Password OK", resp.data);
            this.respMsg.message = "Password changed successfully";
            this.respMsg.success = true;
          } else {
            //console.log('#axiosChangePassword(1) error: ', resp.data.message);  // message from server
            this.respMsg.message = "Change password fail";
            this.respMsg.success = false;
          }
        } else {
          //console.log('#axiosChangePassword(2) error: receive null response');
          // this.openSnackBar('Device Info update fail', 'error');
          
        }
      } catch (error) {
        console.error('#axiosChangePassword(3) error: ' + error.response);
        this.respMsg.message = "Change password fail";
        this.respMsg.success = false;
      }
    }

    },

    // ============= Watch =============
    watch: {
      pAct (newVal) {
        if (newVal > 0) {
          this.loadUserInfo();
          this.bDialog = true; 
        }
      },

      bDialog: function(newVal) {
        if (!newVal) {
          this.$emit('DialogPasswordClose');
        }
      }
    }

  }
</script>

<style scoped>

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

.cBottLine {
  border-bottom: 1px solid #E0E0E0;
  width: 90%;
}

.cBorder {
  border: 1px solid grey;
}





</style>
