<!-- --------------------------------------------------------------------------------
                      DevStatistics.vue

DESCRIPTIONS
  - display : Fault / Warning / Offline / Rescue statistics
---------------------------------------------------------------------------------- -->
<template>
  <v-card id="idCard"  :width="size.width" :height="size.height" @click="onClick" class="ma-0 pa-0 rounded-lg elevation-0" 
  style="background-color: #303030;">
    
    <!-- <v-sheet class="text-left ml-2 mt-0 pa-0  transparent fontB">{{ title }}</v-sheet>
    <v-spacer></v-spacer>
    <v-sheet class="text-right mr-0 mt-3 pa-0 transparent fontN">{{ value }}</v-sheet>
    <v-sheet class="text-right mr-5 mt-10 pa-0  transparent fontN">{{unit}}</v-sheet> -->

    <!-- <v-card width="70%" height="80%" class="mx-auto my-auto pa-0 transparent d-flex elevation-0">  -->
    <v-container fluid height="30px" class="transparent elevation-0">
      <!------- (1) Left: icon ------------->
      <v-row align="center" no-gutters class="d-flex ma-0 pa-0 elevation-0">
      <v-col cols="3" class="my-0 mx-0 pa-0 transparent elevation-0">
        <!-- <ButtonBlur :pIconType="title" :pValue="value" class="elevation-0"/>  -->
        <!-- <v-sheet class="ma-0 pa-0 rounded-pill text-center red">  -->
        <v-card width="45" height="45" class="ml-3 mt-n2 pa-0 transparent elevation-0">
          <v-sheet width="45" height="45" :class="bgColor" class="ma-0 pa-0 rounded-pill text-center elevation-0" 
            style="opacity: 0.4; position: absolute; top: 0px; left: 10px;"></v-sheet>
          <SvgIcon  size="36" type="mdi" :path="pathVar" :class="iconClass" class="mt-1 text-center elevation-0 imgFilter1" 
            style="position: absolute; top: 0px; left: 15px;"></SvgIcon>
        </v-card>
      </v-col>

      <!------- (2) Right: Text ------------->
      <v-col cols="7" class="my-0 ml-2 pa-0 transparent elevation-0">
      <!-- <v-sheet width="80%" class="mx-0 mt-n1 pa-0 transparent">  -->
        <v-sheet class="text-left ml-5 mt-n2 pa-0 transparent d-flex elevation-0">
          <v-sheet class="text-left ml-0 mt-0 pa-0 transparent elevation-0 fontValue">{{ value }}</v-sheet>
          <v-sheet class="text-left ml-2 mt-1 pa-0 transparent elevation-0 fontUnit">{{unit}}</v-sheet>
        </v-sheet>
        <v-sheet class="text-left ml-5 mt-n2 pa-0 transparent elevation-0 fontTitle">{{ title }}</v-sheet>
      <!-- </v-sheet>  -->
      </v-col>
      </v-row>

      <v-row align="center" no-gutters class="elevation-0" >
        <v-card height="20%" class="mx-0 mt-n0 pa-0 black--white transparent elevation-0 d-flex">
          <v-sheet v-if="msg2 !== ''" height="100%" class="text-left ml-2 my-0 red--text elevation-0 transparent fontSmall">{{ msg2 }}</v-sheet>
          <v-sheet height="100%" class="text-left ml-1 my-0 grey--text transparent elevation-0 fontSmall">{{ msg }}</v-sheet>
        </v-card>        
      </v-row>

    </v-container>
    <!-- </v-card>  -->

  </v-card>
</template>


<!-- svg-icon type="mdi" :path="pathShieldAlertOutline" size="32"></svg-icon> -->
<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiShieldAlertOutline, mdiShieldCheckOutline, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiAccessPointOff, mdiAccessPointCheck } from '@mdi/js';

import ButtonBlur from '@/components/Common/ButtonBlur'

  export default {
    name: 'DevStatistics',

    components: {
      SvgIcon, ButtonBlur
    },

    props: {
      title: {type: String, default: ''},
      value: {type: Number, default: 0},
      unit:  {type: String, default: ''},
      //width: {type: String, default: '16.5%'},
      //height: {type: String, default: '100'}
      msg: {type: String, default: ''},
      msg2: {type: String, default: ''},
      size:  {type: Object}
    },

    data () {
      return {
        bgColor: 'red',
        iconClass: "darken-5 red--text rounded-pill text-center",

        pathVar: mdiShieldAlertOutline,
        pathShieldAlertOutline: mdiShieldAlertOutline,
        pathShieldCheckOutline: mdiShieldCheckOutline,
        pathAlertCircleOutline: mdiAlertCircleOutline,
        pathCheckCircleOutline: mdiCheckCircleOutline,
        pathAccessPointOff: mdiAccessPointOff,
        pathAccessPointCheck: mdiAccessPointCheck,
      }
    },

    methods: {
      onClick () {
        this.$emit('onClickCard');
      },

      setIcon () {
        switch(this.title) {
        case 'Fault': 
          if (this.value > 0) {
            this.pathVar = this.pathShieldAlertOutline;
            this.bgColor = "red darken-2";       // v-sheet color
            this.iconClass = "text--lighten-2 red--text rounded-pill";  // svgIcon color
          }
          else {
            this.pathVar = this.pathShieldCheckOutline;
            this.bgColor = "green";
            this.iconClass = "text--darken-5 green--text rounded-pill";
          }
          break;

        case 'Warning':
          if (this.value > 0) {
            this.pathVar = this.pathAlertCircleOutline;
            this.bgColor = "yellow darken-2";
            this.iconClass = "darken-4 amber--text rounded-pill";            
          }
          else {
            this.pathVar = this.pathCheckCircleOutline;          
            this.bgColor = "green";
            this.iconClass = "darken-5 green--text rounded-pill";            
          }
          break;

        case 'Offline':
          if (this.value > 0) {
            this.pathVar = this.pathAccessPointOff;
            this.bgColor = "purple lighten-2";
            this.iconClass = "accent-4 purple--text rounded-pill";            
          }
          else {
            this.pathVar = this.pathAccessPointCheck;              
            this.bgColor = "green";
            this.iconClass = "darken-5 green--text rounded-pill";            
          }
          break;
        }
      }
      
    },  //end of methods

    watch: {
      value: function(newVal) {
      this.setIcon();
    }      
    },

    mounted () {
      this.setIcon();
    }

}
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}


.fontValue {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  font-size: 28px;
  color: white;
}

.fontUnit {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  font-size: 20px;
  color: white;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: #FFB74D;
}

.fontTitle {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.0em;
  font-weight: normal;   
  color: #BDBDBD;
}

.fontSmall {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.7em;
  font-weight: normal;   
}


#idCard {
  /* background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A, #78909C); */
  background-color: #282828;
}


.imgFilter1 {
  filter: contrast(120%) brightness(100%) !important;
  opacity: 1.0 !important;
}

</style>

