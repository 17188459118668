<!-- --------------------------------------------------------------------------------
                      DevStatus.vue

DESCRIPTIONS
  - display total warning signals collected

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]
---------------------------------------------------------------------------------- -->
<template>
  <v-card  :width="width" :height="height" :color="bgcolor" class="pt-0 rounded-lg">
    <!-- <v-sheet :color="bgcolor" width="40px" class="mx-auto pa-0 ma-0"><v-icon large :color="vIcon.color"> {{ `${vIcon.icon}` }}</v-icon></v-sheet> -->
     <!-- level: 0=fail, 1=pass, 2=hidden   -->

    <v-card width="80%" height="80%" class="mx-auto pa-0 transparent d-flex elevation-0 border-0">

      <!------- (1) Left: icon ------------->
      <v-card width="50px" height="50px" class="mx-auto transparent elevation-0 border-0">
        <!-- <v-sheet v-if="showCircle==true" width="100%" height="100%" :color="iconColor" class="ml-2 mt-4 pa-0 rounded-pill" 
          style="filter: blur(2px); opacity: 0.4; position: absolute; top:5; left:1;" >
        </v-sheet>  -->

        <v-sheet width="40px" height="40px" class="ml-1 mt-5 pa-0 transparent rounded-pill text-center elevation-0 border-0 ">
          <v-card v-if="iconType==='battery'" width="50" height="50" class="ma-0 pa-0 transparent  elevation-0">
            <v-sheet width="45" height="45" :color="iconColor" class="ma-0 pa-0 rounded-pill text-center" 
              style="opacity: 0.4; position: absolute; top: 0px; left: 10px;"></v-sheet>
            <svg-icon v-if="iconLevel===6" type="mdi" :path="pathBattAlert" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===0" type="mdi" :path="pathBatt0" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathBatt20" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathBatt40" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathBatt60" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===4" type="mdi" :path="pathBatt80" :color="iconColor" size="36" class="iconStyle"></svg-icon>
            <svg-icon v-else-if="iconLevel===5" type="mdi" :path="pathBatt100" :color="iconColor" size="36" class="iconStyle"></svg-icon>
          </v-card>

          <v-card v-else width="50" height="50" class="ma-0 pa-0 transparent  elevation-0">
            <v-sheet width="45" height="45" :color="iconColor" class="ma-0 pa-0 rounded-pill text-center" 
              style="opacity: 0.4; position: absolute; top: 0px; left: 10px;"></v-sheet>
            <SvgIcon v-if="iconLevel===1" size="36" type="mdi" :path="pathCheck" :color="iconColor" class="iconStyle"></SvgIcon>
            <SvgIcon v-else-if="iconLevel===0" size="36" type="mdi" :path="pathClose" :color="iconColor" class="iconStyle" ></SvgIcon>              
          </v-card>       

        </v-sheet> 
      </v-card>

      <!------- (2) Right: Text ------------->
      <v-sheet width="80%" class="ml-6 mt-4 pa-0 transparent elevation-0 border-0">
        <v-sheet class="text-left ml-0 mt-1 pa-0 white--text transparent elevation-0 border-0" 
          style="font-family: Montserrat; font-size: 1.1em;">{{ levels[1].value }}
        </v-sheet>
        <v-sheet class="text-left ml-0 mt-n1 pa-0 grey--text text--lighten-1 transparent elevation-0 border-0"
          style="font-family: Montserrat; font-size: 0.9em;">{{ levels[1].text }}</v-sheet>
      </v-sheet>

    </v-card>

    <!-- bottom row text -->
    <v-card width="100%" height="20%" class="ml-2 mt-n2 pa-0 transparent elevation-0 border-0">
      <v-sheet width="100%" height="100%" class="text-right pr-5 grey--text text--lighten-1 transparent"
      style="font-family: Montserrat; font-size: 0.7em;">{{ levels[1].msg }}</v-sheet>
    </v-card>

    <!-- 
    <v-sheet :color="bgcolor" class="text-left ml-2 mt-1 white--text elevation-0 border-0 fontB">{{ title }}</v-sheet>
       
    <v-sheet :color="bgcolor" width="40px" class="mx-auto pa-0 ma-0">

      <v-sheet v-if="iconType==='battery'" width="100%" height="100%" color="transparent">
        <svg-icon v-if="iconLevel===0" type="mdi" :path="pathBattAlert" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===1" type="mdi" :path="pathBatt20" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===2" type="mdi" :path="pathBatt40" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===3" type="mdi" :path="pathBatt60" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===4" type="mdi" :path="pathBatt80" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===5" type="mdi" :path="pathBatt100" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

      <v-sheet v-else width="100%" height="100%" color="transparent">
        <svg-icon v-if="iconLevel===1" type="mdi" :path="pathCheckCircle" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-else-if="iconLevel===0" type="mdi" :path="pathCloseCircle" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

    </v-sheet>

      <v-list dense :color="bgcolor"> 
      <v-list-item-group>
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0">
            <v-list-item-content class="pa-0 ml-2">
              <v-row no-gutters class="d-flex flex-row pa-0 ml-0">
                <v-col cols="7"><span class="grey--text fontN" style="font-size: 0.9em;">{{ item.text }}</span></v-col>
                <v-col cols="5"><span :class="item.valueColor" style="font-weight: normal; font-size: 0.8em; font-family: Montserrat;">{{ item.value }}</span></v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>  -->

  </v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCheckCircle, mdiInformation, mdiCloseCircle, mdiBatteryAlert, mdiCheck, mdiClose,
  mdiBatteryOutline, mdiBattery20, mdiBattery40, mdiBattery60, mdiBattery80, mdiBattery } from '@mdi/js';

  export default {
    name: 'DevStatus',

    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      vIcon: {type: Object, default: null},
      iconType: {type: String, default: "generic"},
      iconLevel: {type: Number, default: 0},
      iconColor: {type: String, default: "gray"}
    },

    data () {
      return {
        pathCheckCircle: mdiCheckCircle,
        pathCloseCircle: mdiCloseCircle,
        pathCheck: mdiCheck,
        pathClose: mdiClose,
        pathInfo: mdiInformation,
        pathBatt0: mdiBatteryOutline,
        pathBatt20: mdiBattery20,
        pathBatt40: mdiBattery40,
        pathBatt60: mdiBattery60,
        pathBatt80: mdiBattery80,
        pathBatt100: mdiBattery,
        pathBattAlert: mdiBatteryAlert,
        showCircle: true,
        levelValue: ''
      }
    },

    methods: {

    },

    watch: {

    },

    mounted () {

    }

  }
</script>


<style scoped>

.iconStyle {
  filter: contrast(100%) brightness(100%) !important; 
  opacity: 1.0 !important;
  position: absolute; 
  top: 4px; 
  left: 15px;  
}


.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}

.v-list-item {
  min-height: 20px;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: normal;   
  color: white;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: bold;   
  color: white;
}

.fontN2 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: normal;   
  color: white;
}

/* for number only */
.fontN3W {
  font-family: Terminal; 
  font-style: normal; 
  font-size: 0.7em;
  font-weight: normal;   
  color: white;
}

.fontN3B {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: normal;   
  color: black;
}


</style>
