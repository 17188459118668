<template>
  <v-card id="idCard2" class="mt-1 pa-0">
    
    <DevTables2 v-if="tableItems === null" :tblHeaders="tableHeader" :tblItems="aryEmpty" tblHeight="calc(100vh - 220px)"/>
    <DevTables2 v-else :tblHeaders="tableHeader" :tblItems="tableItems" tblHeight="calc(100vh - 220px)"
      :ppSn="pSn" :ppDate1="pDate1" :ppDate2="pDate2" @tblEmit="onEmitTbl2" :trigger2="trigger"/> 

  </v-card>
</template>

<script>
import DevTables2 from '@/components/DevInfoPage/DevTables2'
//import * as Samples from "@/js/samples/data.js"
import * as Tbl from "@/js/tables.js"

  export default {
    name: 'TblEventTestInfo',

    components: {
      DevTables2
    },

    props: {
      tableItems: {type: Array},
      pSn: {type: String, default: ''},
      pDate1: {type: String, default: ''},
      pDate2: {type: String, default: ''},
      trigger: {type: Number, default: 0}
    },

    data () {
      return {
        tableHeader: Tbl.TblTestInfoHeaders2,
        aryEmpty: [],
        //tableItems:  Samples.WarningData2
      }
    },

    methods: {
      onEmitTbl2(xstr) {
        this.$emit("tblDevEmit", xstr);
      }      
    },

    watch: {
    },
  }
</script>

<style>

#idCard2 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  /* overflow: hidden; */
  background-color: #37474F;
  /* overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;  */
}



</style>
