<!-- --------------------------------------------------------------------------------
                      DevTables2.vue

DESCRIPTIONS
  - called from Managment.vue\Fault,Warning,Offline,UserTest
  - exclude rescue
  - include filter for Date and sn

Parameters:
  - tblHeaders (array), tblItems (array)

Last update:  241029   watch sn
---------------------------------------------------------------------------------- -->
<template>
  <div id="idCard3" class="pt-0" :height="tblHeight" >

    <!------------------------- Table ------------------------------->
    <!-- Table https://v2.vuetifyjs.com/en/components/data-tables/ -->
    <v-card width="auto" fixed-header class="mx-0 pa-0" color="transparent"> 
      <!-- <v-data-table :headers="tblHeaders" :items="tblItems" 
          :item-class="filterItems">
        <template v-slot:[`item.devSn`]="{ item }">
          <v-btn color="blue--text transparent" height="100%" class="pa-0 elevation-0" @click="onClickDevSn(item)">{{ item.devSn }}</v-btn>
        </template>
      </v-data-table>  -->

      <!-- <v-data-table :headers="tblHeaders" :items="tblItems" 
          :item-class="filterItems" :height="tblHeight" :fixed-header="true" >
        <template v-slot:[`item.devSn`]="{ item }">
          <v-btn color="blue--text transparent" height="100%" class="pa-0 elevation-0" 
          @click="onClickDevSn(item)">{{ item.devSn }}</v-btn>
        </template>          
      </v-data-table>  -->

      <v-data-table :height="tblHeight" :fixed-header="true" 
        :headers="tblHeaders" :items="vItems" :footer-props="{ 'items-per-page-options': [30, 50, 100, -1]}" 
        sort-by="receiveTime" :sort-desc="true">

        <!-- (1) device SN -->
        <template v-slot:[`item.devSn`]="{ item }">
          <!-- allowed to click  -->
          <v-btn v-if="item.clickable==true" color="blue--text transparent" height="100%" class="pa-0 elevation-0" 
          @click="onClickDevSn(item)">{{ item.devSn }}</v-btn>
          <!-- disallowed bot click -->
          <v-btn v-else color="grey--text transparent" height="100%" class="pa-0 elevation-0">{{ item.devSn }}</v-btn>
        </template>

        <!-- (2) faultMessage -->
        <template v-slot:[`item.faultMessage`]="{ item }">
          <v-sheet v-if="item.faultMessage=='Normal'" color="transparent" class="py-auto elevation-0">
            <v-sheet v-if="item.warnMessage=='' || item.warnMessage=='Normal'" color="transparent" class="py-auto elevation-0 d-flex">
              <svg-icon type="mdi" :path="pathCheckCircle" color="green" :size=iconSizeMsg></svg-icon>
              <v-sheet class="ml-1 black--text">Normal</v-sheet>
            </v-sheet>
            <v-sheet v-else class="py-auto elevation-0 d-flex">
              <svg-icon type="mdi" :path="pathAlertCircle" color="orange" :size=iconSizeMsg></svg-icon>
              <v-sheet class="ml-1 black--text">{{ item.warnMessage }}</v-sheet>
            </v-sheet>
          </v-sheet>
          <v-sheet v-else color="transparent" class="py-auto elevation-0 d-flex">
            <!-- <svg-icon type="mdi" :path="pathCloseCircle" color="red"></svg-icon>
            <v-sheet class="ml-1 black--text">{{ item.faultMessage }}</v-sheet>  -->
            <v-row width="100%" class="ma-0 pa-0">
              <v-col cols="1" class="ma-0 pa-0 my-auto">
                <svg-icon type="mdi" :path="pathCloseCircle" color="red" :size=iconSizeMsg></svg-icon>
              </v-col>
              <v-col cols="16" class="pa-0 ma-0 ml-2 mt-1">{{ item.faultMessage }}</v-col>
            </v-row>            
          </v-sheet> 
        </template>  

      </v-data-table> 

    </v-card>

  </div>
</template>


<script>
// import * as Samples from "@/js/samples/data.js"
// import * as Tbl from "@/js/tables.js"
import moment from 'moment';
import * as Ut from '@/js/ut.js'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCloseCircle, mdiCheckCircle, mdiAlertCircle } from '@mdi/js';

  export default {
    name: 'DevTables2',

    components: {
      SvgIcon
    },

    props: {
      tblHeaders: {type: Array},
      tblItems: {type: Array},
      tblHeight: {type: String},
      ppSn: {type: String, default: ''},
      ppDate1: {type: String, default: ''},
      ppDate2: {type: String, default: ''},
      trigger2: {type: Number, default: 0}
    },

    data () {
      return {
        iconSizeMsg: 32,
        pathCheckCircle: mdiCheckCircle,
        pathCloseCircle: mdiCloseCircle,
        pathAlertCircle: mdiAlertCircle,

        headers: [],
        vItems: []
        // Sample device info data at  assets/samples/data.js
        // deviceData: Samples.DeviceData
      }
    },

    methods: {

      // DESCRIPTION:  chk if string is empty (or default value 'All')
      isFieldEmpty (xstr) {
        let str = xstr.toString();
        if (str === "" || str === "All") return true;
        return false;
      },

      // DESCRIPTION:  click at SN and go to SN page
      onClickDevSn (xitem) {
        //console.log("##DevTable2-", xitem);  //debug
        // this.$root.$emit("tbl2_SelSnEmit", xitem.devSn);
        this.$emit("tblEmit", xitem.devSn);
      },

      // DESCRIPTION:  reload table and filter data (within time range)
      refreshTable () {
        //console.log("##DevTables2", this.vItems);

        if (this.tblItems === undefined || this.tblItems === null) return;
        if (this.vItems !== undefined && this.vItems !== null && this.vItems.length > 0) Ut.clearArray(this.vItems);

        let vDate2 = "", vDate1 = "";
        if (this.ppDate2 !== undefined && this.ppDate2 !== "" && this.ppDate1 !== undefined && this.ppDate1 !== "") {
          //vDate2 = this.ppDate2 + " 23:59:59";
          //vDate1 = this.ppDate1 + " 00:00:00";
          vDate2 = this.ppDate2
          vDate1 = this.ppDate1;
        }
        for(let idx=0; idx < this.tblItems.length; idx++) {
          let vObj = this.tblItems[idx];

          if (vObj.hidden) {
            //console.log(vObj.devSn, vObj.errorCode, vObj.offlineDays, vObj.hidden);    //debug*
            continue;
          }
          if (this.ppSn !== "" && vObj.devSn.indexOf(this.ppSn) < 0) continue;  

          //(5) filter time range
          let vRxTime = vObj.receiveTime.substring(0, 10);
          if (vDate2 !== "") {
            //if (Ut.compareDates(vObj.receiveTime, vDate2) = 0) continue;
            if (moment(new Date(vDate2)).diff(moment(new Date(vRxTime)), 'days') < 0) continue;
          }
          if (vDate1 !== "") {
            //if (Ut.compareDates(vDate1, vObj.receiveTime) < 0) continue;
            if (moment(new Date(vRxTime)).diff(moment(new Date(vDate1)), 'days') < 0) continue;
          }            
          this.vItems.push(vObj);
        }
      }

    },

    watch: {
      trigger2: function(newVal) {
        this.refreshTable();
      },
      
      ppSn: function(newVal) {
        this.refreshTable();
      }
    },    

    mounted () {
      this.refreshTable();
    }
  }
</script>


<style scoped>
.cBorder {
  border: 1px solid grey;
  border-radius: 100;
  background-color: #E3F2FD;
  margin-left: 2px;
  padding-left: 2px;
}

.v-data-table >>> .v-data-table-header {
  background-color: #FF8F00;
  font-family: Montserrat !important; 
}

>>> table td {
  font-family: Montserrat !important; 
  color: black;
}


.some-other-style {
  background: blue;
}
.tbl-header1 {
  background-color: #FF8F00;
  color: white !important;
  font-size: 0.9em !important;
}

.tbl-header2 {
  border-top-left-radius: 10px;
}

.tbl-header3 {
  border-top-right-radius: 10px;
}

#idCard3 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  /* overflow: hidden; */
  background-color: #37474F;
  /* overflow-y: scroll; */
  /* -ms-overflow-style: none; 
  scrollbar-width: none;   */
}
/*
#idCard3::-webkit-scrollbar {
    display: none;
} */


.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}


</style>
