n<template>

  <!-- <v-card width="100%" class="ma-0 pa-0" style="background-color: #161616;"> -->
  <v-card width="100%" class="ma-0 pa-0 bg_page">

    <v-card width="100%" height="100%" class="ma-0 pa-0 d-flex transparent">

      <!------------------ LHS -------------------------------->
      <v-card width="65%" height="90%" class="ma-0 pa-0 transparent">

        <!------------------ 1st row ------------------------>
        <v-card width="100%" class="ma-0 pa-0 d-flex transparent elevation-0 border-0">
          
          <!---------------- BasicInfo ---------------------->
          <v-card width="35%" min-height="270px" height="40vh" class="ml-1 mt-1 pa-0 transparent elevation-0 border-0" 
          style="height: calc(100vh - 330px);">
            <DevStatus2BasicInfo title="Basic Info" :width="statusW2" height="100%" :levels="aryBasic"
            :bgcolor="boxBgColor" :DevObj="devObj" class="mt-1 ml-0 bg_card"/>
          </v-card>

          <!---------------- Carousel (image) --------------->
          <!-- <v-sheet  width="63%" min-height="270px" height="40vh" class="ml-3 mt-2 pa-0 rounded-lg" :color="boxBgColor"> -->
          <v-sheet  width="65%" min-height="270px" height="40vh" class="ml-2 mt-2 pa-0 rounded-lg bg_card" 
          style="height: calc(100vh - 330px);">
            <v-carousel width="100%" height="100%" class="ma-0 pa-0 rounded-lg transparent">
              <v-sheet v-if="bImageExist"  width="100%" height="100%" class="ma-0 pa-0 transparent">
              <v-carousel-item v-for="(item,i) in aryPic" :key="i">
                <v-img :src="item.src" contain height="100%"></v-img>
              </v-carousel-item>
              </v-sheet>
              <v-sheet v-else width="80%" height="80%" class="ma-auto transparent">
                <v-img :src="picNoImage" contain width="100%" height="100%"></v-img>
              </v-sheet>
            </v-carousel>
          </v-sheet>

        </v-card>

        <!------------------ 2nd row ------------------------>
        <v-card width="100%" max-height="250" class="mx-0 mt-2 pa-0 d-flex transparent elevation-0 border-0" >
          
          <!---------------- Timeline (L) ---------------------->
          <v-card width="54%" height="100%" class="ml-1 mt-0 rounded-lg border-0 bg_card" >
            <!---- header ---->
            <v-card width="100%" height="40px" class="mx-0 pa-0 mt-1 d-flex transparent elevation-0">
              <!-- <v-sheet width="30%" class="mt-5 rounded transparent green--text">
                <v-img :width="30" :src="heartwaveIcon" class="ml-2"></v-img>
              </v-sheet>  -->
              <v-card width="45px" height="45px" class="transparent elevation-0" style="position: relative;">
                <v-sheet width="45px" height="45px" color="green" class="ml-2 mt-2 pa-0 rounded-pill elevation-0" 
                  style="filter: blur(2px); opacity: 0.4; position: absolute; top:0; left:0;" >
                </v-sheet>
                <v-img :width="30" :src="heartwaveIcon" class="ml-4 mt-4 elevation-0"></v-img>
              </v-card>  

              <v-sheet class="transparent">
                <v-row width="70%" class="mt-1 transparent elevation-0">
                  <v-sheet class="ml-8 transparent white--text elevation-0 border-0 fontTimeLine1">{{ rescueTimes }} times</v-sheet>
                </v-row>
                <v-row width="80%" class="pa-0 d-flex">
                  <v-sheet class="ml-8 mt-n2 transparent grey--text fontTimeLine2 elevation-0 border-0 ">Rescue</v-sheet>
                  <select class="mt-n1 ml-2 px-1 py-0 grey lighten-4 black--text rounded" @click="onChangeRescue" 
                  style="height: 18px; padding: 2; font-family: Montserrat; font-size: 0.7em; font-weight: 580;">
                    <option value="1">1 days</option>
                    <option value="7">7 days</option>
                    <option value="30" selected>30 days</option>
                  </select>
                </v-row>
              </v-sheet>
            </v-card>

            <!---- timeline body ---->
            <v-card id="id_timeline"  width="100%" height="115px" class="ml-1 mt-6 pa-0 transparent elevation-0" style="overflow-y: scroll;">
              <!-- <v-timeline  align-top dense dark class="pt-0 ml-n5 mt-0 transparent">
                <v-timeline-item v-for="item in aryEmergs" :key="item.id" color="orange" class="mt-2 pa-0 white--text" small fill-dot>
                  <v-sheet class="transparent white--text fontN2">{{getReceiveTime(item.datetime)}}</v-sheet>
                </v-timeline-item>
              </v-timeline>  -->

              <v-timeline v-if="rescueTimes !== '0'" align-top dense dark class="pt-0 ml-n5 mt-0 transparent">
                <v-timeline-item v-for="item in aryEmergs" :key="item.id" color="#63625f"  
                  class="mt-2 pa-0 white--text" small icon="mdi-checkbox-blank-circle" fill-dot icon-color="orange">
                  <v-sheet class="transparent white--text fontTimeLst">{{item.receiveTime}}</v-sheet>
                </v-timeline-item>
              </v-timeline>
              <v-sheet v-else width="auto" height="20px" class="transparent ">
                  <p class="mt-5 grey--text text--lighten-1 text-center text-h5" style="font-family: Montserrat; font-size: 0.9em;">No Rescue data within {{selectRescue}} days</p>
              </v-sheet>
            </v-card>
          </v-card>

          <!---------------- 4 Boxes (R) --------------->
          <!-- <v-sheet width="63%" height="380px" class="ml-3 mt-2 pa-0 rounded-lg" :color="boxBgColor"> -->
          <v-card width="100%" height="100%" class="ml-2 mt-0 transparent elevation-0 border-0">
            
            <v-sheet  height="90px" class="ma-0 pa-0 transparent d-flex">
              <DevStatus2 title="Battery Status" width="100%"  height="100%" :levels="aryBatt"
                :bgcolor="boxBgColor" iconType="battery" :iconLevel="iconBattLevel" :iconColor="iconBattColor" 
                class="ma-0 pa-0"/>   
              <DevStatus2 title="Pads Status"  width="100%"  height="100%" :levels="aryPads"
                :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconPadsLevel" :iconColor="iconPadsColor" 
                class="ml-1 my-0 pa-0"/> 
            </v-sheet>

            <v-sheet  height="90px" class="mx-0 mt-1 px-0 pb-2 transparent d-flex">
              <DevStatus2 title="Device Status" width="100%" height="100%" :levels="aryDev"
                :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconDevStatusLevel" :iconColor="iconDevStatusColor" 
                class="ma-0 pa-0"/>  
              <DevStatus2Nwk :title="netTitle" width="100%" height="100%" :levels="aryNetwork"
                :bgcolor="boxBgColor" :iconType="iconNwkType" :iconLevel="iconNwkLevel" :iconColor="iconNwkColor"
                class="ml-1 my-0 pa-0"/>
            </v-sheet>
            
            <!-- <v-row height="100%"  no-gutters class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <DevStatus2 title="Battery Status" width="100%"  height="100px" :levels="aryBatt"
                :bgcolor="boxBgColor" iconType="battery" :iconLevel="iconBattLevel" :iconColor="iconBattColor" 
                class="ma-0 pa-0"/>   
              </v-col>
              <v-col class="ml-1 my-0 pa-0">
                <DevStatus2 title="Pads Status"  width="100%"  height="100px" :levels="aryPads"
                :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconPadsLevel" :iconColor="iconPadsColor" 
                class="ma-0 pa-0"/> 
              </v-col>
            </v-row>

            <v-row  height="100%" no-gutters class="mx-0 mt-2 pa-0">
              <v-col class="ma-0 pa-0">
                <DevStatus2 title="Device Status" width="100%" height="100%" :levels="aryDev"
                :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconDevStatusLevel" :iconColor="iconDevStatusColor" 
                class="ma-0 pa-0"/>  
              </v-col>
              <v-col class="ml-1 my-0 pa-0">
                <DevStatus2Nwk :title="netTitle" width="100%" height="100%" :levels="aryNetwork"
                :bgcolor="boxBgColor" :iconType="iconNwkType" :iconLevel="iconNwkLevel" :iconColor="iconNwkColor"
                class="ma-0 pa-0"/>
              </v-col>
            </v-row>  -->

          </v-card>

        </v-card>

        <!-- <v-card width="100%" height="100%" class="mx-0 mt-2 pa-0 transparent d-flex" style="padding-top: 1200px;">
          <DevStatus2 title="Battery Status" :width="statusW" :height="statusH" :levels="aryBatt"
          :bgcolor="boxBgColor" iconType="battery" :iconLevel="iconBattLevel" :iconColor="iconBattColor" class="my-1 ml-1"/>   

          <DevStatus2 title="Pads Status" :width="statusW" :height="statusH" :levels="aryPads"
          :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconPadsLevel" :iconColor="iconPadsColor" class="mt-1 ml-2"/>
          
          <DevStatus2 title="Device Status" :width="statusW" :height="statusH" :levels="aryDev"
          :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconDevStatusLevel" :iconColor="iconDevStatusColor" class="mt-1 ml-2"/>  

          <DevStatus2Nwk :title="netTitle"  :width="statusW" :height="statusH" :levels="aryNetwork"
          :bgcolor="boxBgColor" class="mt-2 ml-2" :iconType="iconNwkType" :iconLevel="iconNwkLevel" 
          :iconColor="iconNwkColor"/>
        </v-card>  -->

      </v-card>

      <!------------------ Map RHS ----------------------------->
      <v-card width="35%" min-height="400px"  :color="mapBkColor" class="ml-2 mt-2 mb-1 pa-1 rounded-xl elevation-0" 
      style="height: calc(100vh - 160px);" >
        <v-card width="99%" height="35px" :color="mapBkColor" class="mt-0 pa-0 d-flex elevation-0">
          <v-img :aspect-ratio="1" width="12px" height="20px" :src="locationIcon" class="ml-1 mt-1 elevation-0"></v-img>
          <v-sheet width="30%" height="100%" :color="mapBkColor" class="ml-3 mt-0 transparent white--text fontN">Location </v-sheet>
          <v-sheet width="70%" height="100%" :color="mapBkColor" class="ml-2 mt-0 transparent white--text fontN">{{ map.location }}</v-sheet>
        </v-card>

        <!-- <GoogleMap width="100%" height="100%" class="ma-0" :class="getMapClass" :location="devObj.InstLoc"/> -->
        <GoogleMap width="100%" height="100%" class="ma-0 rounded-xl" :class="getMapClass" :location="getLocation(devObj)" />

      </v-card>

    </v-card>
    
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import cookies from '@/js/Cookie.js'
import * as Ut from '@/js/ut.js'
// import devTable from '@/components/Common/DeviceTable.vue'
import * as Emergency from '@/js/samples/Emergency.js'
import GoogleMap from '@/components/Common/GoogleMap'
import DevStatus2 from '@/components/DevInfoPage/DevStatus2'
import DevStatus2Nwk from '@/components/DevInfoPage/DevStatus2Nwk'
import DevStatus2BasicInfo from '@/components/DevInfoPage/DevStatus2BasicInfo'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiToothbrushElectric } from '@mdi/js';

  export default {
    name: 'TblBasic',

    components: {
      GoogleMap, SvgIcon, DevStatus2, DevStatus2Nwk, DevStatus2BasicInfo
    },

    props: {
      devObj: {type: Object},
      testInfo: {type: Object},
      isTstExist: {type: Boolean, default: false},
      trigger: {type: Number, default: 0}
    },

    data () {
      return {
        axiosPathEmergencySn: '/api/v1/emergreports',
        heartwaveIcon: require("@/assets/Images/icons/heartwave1.png"),
        locationIcon: require("@/assets/Images/icons/location1.png"),
        aryEmergs: [],

        statusW: '50%',
        statusH: '150',
        statusW2: '100%',
        statusH2: '400px',
        // boxBgColor: '#455A64',
        //boxBgColor: '#282828',
        boxBgColor: '#303030',
        boxTextColor: 'grey--text text--lighten-4',
        boxIconColor: 'green--text',
        mapBkColor: '#303030',
        colorNormal: 'green--text',
        colorFail: 'red--text',
        fileUpload: {},

        iconBattLevel: 0,
        iconBattColor: "green",
        iconPadsLevel: 0,
        iconPadsColor: 'green',
        iconDevStatusLevel: 0,
        iconDevStatusColor: 'green',
        iconNwkType: 0,  // 1 if 4G, 0 if wifi
        iconNwkLevel: 0,
        iconNwkColor: "green",

        // last row, set text="message", "value" displayed as a long message
        //aryBasicLast: "Last PM",
        aryBasicLast: "Customer",
        // aryBasic format:  text=message(for error code), =longline1 (2 lines title), =longline2(data)
        aryBasic: [
          {text: "Serial #", value: this.devObj.devSn, valueColor: 'white--text'},
          {text: "Model", value: this.devObj.devModel, valueColor:  'white--text'},
          {text: "Installation", value: this.devObj.InstLoc, valueColor:  'white--text'},
          //{text: "Installation Date", value: this.devObj.InstTime === "/" ? "": this.devObj.InstTime, valueColor:  'white--text'},
          // aryBasic.push({text:'', value: "", valueColor:  'black--text'});  // empty line
          {text: "longline1", value: "Installation Date", valueColor:  'white--text'},
          {text: "longline2", value: this.devObj.InstTime === "/" ? "": this.devObj.InstTime, valueColor:  'white--text'},
          //{text: "", value: "", valueColor: 'transparent'},
          {text: "Customer", value: this.devObj.CustName, valueColor:  'white--text'},
          //{text: "Battery SN", value: this.devObj.battSn, valueColor:  'white--text'},
          // PM & Preventive Maintennance -------------------
          //{text: "longline1", value: "Last preventive maintenance", valueColor:  'white--text'},
          //{text: "longline2", value: this.devObj.LastPM === "/" ? "": this.devObj.LastPM, valueColor:  'white--text'}
        ],        
        aryDev: [{text:'Device Status', value: '', valueColor:  'white--text', msg: ''},
            {text:'Device Status', value: '', valueColor:  'red--text', msg: ''}
        ],
        aryBatt: [{text: 'Battery', value: '', valueColor:  this.boxTextColor, msg: ''}, 
            {text:'Battery', value: '', valueColor:  this.boxTextColor, msg: ''}
        ],
        aryPads: [{text:'Pads expiry Date', value: '', valueColor:  this.boxTextColor, msg: ''},
            {text:'Pads expiry Date', value: '', valueColor:  this.boxTextColor, msg: ''}
        ],
        aryNetwork: [{text:'Network Status', value: '', valueColor:  this.boxTextColor, msg: ''}, 
            {text:'Network Status', value: "", valueColor:  this.boxTextColor, msg: ''}
        ],           
        aryLoc: [{text:'Status', value: '', valueColor:  this.boxTextColor, msg: ''}
        ],            
        
        devStatusIcon: {icon: 'mdi-check-circle', color:  this.colorNormal},
        battIcon: {icon: 'mdi-battery-charging', color:  this.colorNormal},
        padsIcon: {icon: 'mdi-check-circle', color:  this.colorNormal},
        locIcon: {icon: 'mdi-map-marker-outline', color:  this.colorNormal},
        nwkIcon: {icon: 'mdi-signal', color:  'red'},
        rescueTimes: "0",

        netTitle: 'Network Status',

        selectRescue: '30',

        map: {"location": this.devObj.InstLoc},
 
        picNoImage: require("@/assets/Images/App/No_Image.png"),
        bImageExist: false,
        bImageExist2: false,
        image: '',
        // imageD1: require("@/assets/Images/Models/D1.jpg"),
        /* aryPic: [
          {src: require("@/assets/Images/Installation/pic001.jpg")},
          {src: require("@/assets/Images/Installation/pic002.jpg")},
        ] */
        vPic: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
        aryPic: [],
      }
    },

    methods: {

      // DESCRIPTION:  handler of pic upload button
      onBtnUpload () {

      },

      onChangeUpload () {

      },

      async onChangeRescue (xitem) {
        // parameter: xitem.target.selectedIndex    type: number
        //console.log("##onChangeRescue", xitem.target.selectedIndex, typeof(xitem.target.selectedIndex));  //both types are number
        if (xitem.target.selectedIndex === undefined || xitem.target.selectedIndex === null) return;
        try {
          let flag = false;
          switch(xitem.target.selectedIndex) {
            case 0: this.selectRescue = "1"; flag=true; break;
            case 1: this.selectRescue = "7"; flag=true; break;
            case 2: this.selectRescue = "30"; flag=true; break;
            default: flag=false; break;
          }
          if (flag) {
            let vData = await this.axiosGetEmergencySn2(this.devObj.devSn);
            //console.log("##onChangeRescue", vData);
            if (vData !== undefined && vData !== null) {
              this.storeEmergToTable(vData);
            }
          }
        } 
        catch(err) { return; }
      },      

      getMapClass() {
        //console.log("#getMapClass", this.devObj.InstLoc);
        if (this.devObj.InstLoc === "/" || this.devObj.InstLoc === "") {
          return "d-none";
        }
        return "";
      },

      // DESCRIPTION:  for map
      // return MapLoc --> InstLoc --> DeliveryAddr --> CustName  (Address Obsoleted)
      getLocation (xitem) {
        let bFlag = false;
        if (xitem.DeliveryAddr !== undefined && xitem.DeliveryAddr !== null && xitem.DeliveryAddr !== '' && xitem.DeliveryAddr !== '/') bFlag=true;
        if (xitem.MapLoc !== undefined && xitem.MapLoc !== null && xitem.MapLoc !== '' && xitem.MapLoc !== '/') {
          if (bFlag) this.map.location = xitem.DeliveryAddr;
          else this.map.location = xitem.InstLoc;          
          return xitem.MapLoc;
        } else if (xitem.InstLoc !== undefined && xitem.InstLoc !== null && xitem.InstLoc !== '' && xitem.InstLoc !== '/') {
          if (bFlag) this.map.location = xitem.DeliveryAddr;
          else this.map.location = xitem.InstLoc;  
          return xitem.InstLoc;
        } else if (bFlag) {
          this.map.location = xitem.DeliveryAddr; 
          return xitem.DeliveryAddr;
        }
        if (this.map.location === "" || this.map.location === "/") this.map.location = "Hong Kong";
        return xitem.CustName;
      },

      // DESCRIPTION:  for aryBasic[] only
      getLocation2(xitem) {
        let bFlag = false;
        if (xitem.DeliveryAddr !== undefined && xitem.DeliveryAddr !== null && xitem.DeliveryAddr !== '' && xitem.DeliveryAddr !== '/') bFlag=true;
        if (xitem.MapLoc !== undefined && xitem.MapLoc !== null && xitem.MapLoc !== '' && xitem.MapLoc !== '/') {      
          return xitem.MapLoc;
        } else if (xitem.InstLoc !== undefined && xitem.InstLoc !== null && xitem.InstLoc !== '' && xitem.InstLoc !== '/') {
          return xitem.InstLoc;
        } else if (bFlag) {
          return xitem.DeliveryAddr;
        }
        return "";
      },

      getImage (xmodel) {
        if (xmodel === 'BeneHeart D1') return this.imageD1;
        if (xmodel === 'BeneHeart C1') return this.imageC1;
        if (xmodel === 'BeneHeart C2A') return this.imageC2A;
        if (xmodel === 'BeneHeart C2 Fully automatic') return this.imageC2auto;
        return this.imageD1;
      },

      // DESCRIPTION:  expiry date - date now, return <= 0 if expired
      getDateDiff (xdate) {
        return (new Date(xdate) - new Date())/(1000 * 3600 * 24);
      },

      setIcon (xIcon, xtype, xcolor) {
        xIcon.icon = xtype;
        xIcon.color = xcolor;
      },

      // DESCRIPTION:  dateTime in 24hour, HK format  getReceiveTime(item.receiveTime) OBSOLETED
      getReceiveTime (xDateTime) {
        //return moment(xDateTime).format("YYYY-MM-DD HH:mm:ss");  
        return xDateTime;
      },


      // ---------------------------------------------------------------------------------------------------
      //      [Axios]
      // ---------------------------------------------------------------------------------------------------

      // ---------------------------------------------
      // DESCRIPTION:  download picture and stored into array aryPic[]
      async axiosGetImage (xPic) {
        let config = {
            // url: 'http://192.168.253.199:7003/uploads/aedImg/C1_20240503030445.jpeg',
            url:  this.$config.apiBaseUrl + '/' + xPic,
            method: 'GET',
            responseType: 'blob',
            'headers': {
              'Authorization': 'Bearer ' + cookies.get('kk')
            }
        }

        axios(config)
          .then((response) => {
              let reader = new FileReader();
              reader.readAsDataURL(response.data); 
              reader.onload = () => {
                  //this.vPic = reader.result;
                  this.aryPic.push({src: reader.result});
              }
          });
      },

      
      // ---------------------------------------------
      // DESCRIPTION:  All Emergency(Rescue) Reports
      async axiosGetEmergencySn (xSn) {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.axiosPathEmergencySn + "/" + xSn,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== undefined && resp.data.data !== null && 'data' in resp.data) {
                //this.storeEmergToTable(resp.data.data);
                return resp.data.data;
              } else {
                Ut.clearArray(this.aryEmergs);
                this.rescueTimes = "0";
              }
              return;
            } 
          } else {
            console.log('#Detail-axiosGetEmergencySn(2) error: receive null data');
          }
        } catch (error) {
          console.error('#Detail-axiosGetEmergencySn(3) error: ' + error.message);
        }
        this.rescueTimes = "0";
        return null;
      },

      // ---------------------------------------------
      // DESCRIPTION:  All Emergency(Rescue) Reports
      async axiosGetEmergencySn2 (xSn) {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + "/api/v1/emergreportsWithInterval/" + xSn +"?interval=" + this.selectRescue,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        //console.log(vObj.url);
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== undefined && resp.data.data !== null && 'data' in resp.data) {
                //this.storeEmergToTable(resp.data.data);
                return resp.data.data;
              } else {
                Ut.clearArray(this.aryEmergs);
                this.rescueTimes = "0";
              }
              return null;
            }
          } else {
            console.log('#Detail-axiosGetEmergencySn(2) error: receive null data');
          }
        } catch (error) {
          console.error('#Detail-axiosGetEmergencySn(3) error: ' + error.message);
        }
        this.rescueTimes = "0";
        return null;
      },

      // ---------------------------------------------
      // DESCRIPTION:  load axios or sim data to this.aryEmergReport[]
      storeEmergToTable (xAry) {
        if (xAry === undefined || xAry === null) return;
        Ut.clearArray(this.aryEmergs);
        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          let vObj = Emergency.createEmergReportSn();
          vObj.devSn          = vObj2.sn;
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.rescueTime     = vObj2.totalduration;
          vObj.cprDuration    = vObj2.cprduration;
          vObj.shock          = vObj2.shock;
          vObj.testTime       = "/";
          vObj.state          = "Rescue";
          vObj.hidden         = false;

          this.aryEmergs.push(vObj);
        }

        if (this.aryEmergs !== undefined && this.aryEmergs !== null) {
          this.rescueTimes = this.aryEmergs.length + " ";
          // sort receiveTime, most recent record on top
          this.aryEmergs.sort((a, b) => Ut.compareDates(a.receiveTime, b.receiveTime));
        } else {
          this.rescueTimes = "0";
        }
      },


      // ---------------------------------------------
      // DESCRIPTION:  check battery status
      checkBatteryStatus (xlevel, xStatus) {
        //console.log(xlevel, xStatus);
        if (xlevel === 0) {
          this.aryBatt[0].value = "Fault";
          this.aryBatt[0].valueColor = "red--text";
          //this.setIcon(this.battIcon, "mdi-battery-alert", "red");
          this.iconBattLevel = 6;
          this.iconBattColor = "red";
          return;
        }        
        else if (xlevel <= 10.0) {
          this.aryBatt[0].value = "Low Battery";
          this.aryBatt[0].valueColor = "red--text";
          //this.setIcon(this.battIcon, "mdi-battery-alert", "red");
          this.iconBattLevel = 0;
          this.iconBattColor = "red";
          return;
        }
        else if (xlevel < 30) {
          this.aryBatt[0].value = "Low Battery";
          this.aryBatt[0].valueColor = "yellow--text";
          //this.setIcon(this.battIcon, "mdi-battery-10", "yellow");
          this.iconBattLevel = 1;
          this.iconBattColor = "yellow";          
        }
        else if (xlevel < 50) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-20", "green");
          this.iconBattLevel = 2;
          this.iconBattColor = "green";
        }
        else if (xlevel < 70) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-40", "green");
          this.iconBattLevel = 3;
          this.iconBattColor = "green";          
        }
        else if (xlevel < 90) {   //241025 1.55c
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-60", "green");
          this.iconBattLevel = 4;
          this.iconBattColor = "green";          
        }
        else if (xlevel <= 100) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery", "green");
          this.iconBattLevel = 5;
          this.iconBattColor = "green";          
        }
        else {
          //this.setIcon(this.battIcon, "mdi-battery-charging", "green");
          this.aryBatt[0].value = "---";
          this.iconBattLevel = 0;
          this.iconBattColor = "black";          
        }

        // conform to Dashboard Limits
        //if (xlevel <= Ut.Limits.batt.value2 && xlevel > Ut.Limits.batt.value0) {
        if (xStatus === "Batt Low") {
            this.aryBatt[0].value = "Low Battery";
            this.aryBatt[0].valueColor = "yellow--text";
            this.iconBattColor = "yellow";              
        }
      },

      // ---------------------------------------------
      // DESCRIPTION:  display network status & icons, xtype=0 wifi, =1 4G, 2=NB?
      checkNetworkStatus (xlevel, xtype) {
        if (xtype === 1) {
          this.aryNetwork[1].value = "4G";
          this.iconNwkType = 1;
        }
        else if (xtype === 0) {
          this.aryNetwork[1].value = "WIFI";
          this.iconNwkType = 0;
        }
        else {
          //this.netTitle = "Network Status (N/A)";
          this.aryNetwork[1].value = "No Network";
          this.iconNwkType = 2;
        }
        this.aryNetwork[1].valueColor = "white--text";        

        if (xlevel === 0) {
          this.aryNetwork[0].value = "Receiver Fail";
          this.aryNetwork[0].valueColor = "red--text";
          this.aryNetwork[1].msg = "Level: 0";
          this.iconNwkLevel = 0;
          this.iconNwkColor = "red";
          if (xtype===1) this.iconNwkLevel = 4;
          return 'Fault';
        } 
        else if ((xtype===1 && xlevel <= -111) || (xtype===0 && xlevel <= -80)) {          
          //this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].value = "Fault";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.aryNetwork[1].msg = "Level: " + xlevel.toString() + " dBm";
          this.iconNwkLevel = 0;
          this.iconNwkColor = "red";          
          return 'Normal';
        } 
        else if ((xtype===1 && xlevel <= -103) || (xtype===0 && xlevel <= -67)) {    
          //this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].value = "Warning";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.aryNetwork[1].msg = "Level: " + xlevel.toString() + " dBm";
          this.iconNwkLevel = 1;
          this.iconNwkColor = "yellow";          
          return 'Normal';
        } 
        else if ((xtype===1 && xlevel <= -85) || (xtype===0 && xlevel < -30)) {    
          //this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].value = "Normal";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.aryNetwork[1].msg = "Level: " + xlevel.toString() + " dBm";
          this.iconNwkLevel = 2;
          this.iconNwkColor = "green";          
          return 'Normal';          
        } 
        else {
          if (xtype===1 || (xtype===0 && xlevel >= -30)) {  
            //this.aryNetwork[0].value = xlevel.toString() + " dBm";
            this.aryNetwork[0].value = "Normal";
            this.aryNetwork[0].valueColor = this.boxTextColor;
            this.aryNetwork[1].msg = "Level: " + xlevel.toString() + " dBm";
            this.iconNwkLevel = 3;
            this.iconNwkColor = "green";
            if (xtype===0 && xlevel >= -30) this.iconNwkLevel = 4;
          }
        }
        return 'Normal';
      },

      //-----------------------------------------------------
      //             [setStatus] called in mounted()
      // DESCRIPTION:  set Fault/Warning/Normal for Batt/Pads/Device
      //-----------------------------------------------------
      setStatus () {
        // devObj, testInfo
        let str = "";
        //(3) Battery:    table.js battStatusData = ['All', 'Normal', 'Fault']
        if (this.testInfo === undefined || this.testInfo === null || !("testitems" in this.testInfo)) {
          //console.log('#TblBasic-setStatus error: testInfo or testitems null');
          /* if ( this.aryBasic.length > 0) {
            let vObj = this.aryBasic[this.aryBasic.length - 1];
          } */
          this.turnOffIcons();
          return;
        }
        let j2 = 0;
        //console.log('#TblBasic-setStatus',this.testInfo );  //debug
        let netType = this.testInfo.netinfo.NetType;     // 0=wifi 1=4G, 2=NB?
        let battTot = this.testInfo.testitems.batTotalCapacity.result;
        let battRes = this.testInfo.testitems.batResidualCapacity.result;
        if (this.devObj.battSn !== "/" && this.devObj.battSn !== "") {
          this.aryBatt[1].msg = "Battery SN: " + this.devObj.battSn;
        } else {
          this.aryBatt[1].msg = "Battery SN: N/A";
        }
        if (battTot === 0) {
          this.aryBatt[1].value = "0";
          this.aryBatt[0].value = "Fault";
          this.aryBatt[0].valueColor = "red--text";
          //this.aryDev[0].value = 'Fault';
          this.aryDev[1].value = 'Fault';
        }
        else {
          //j2 = Math.floor((battRes * 100)/battTot);
          let battObj = Ut.getBattStatus(battRes, battTot);
          let j2 = battObj.numCapacity;
          try {
            this.aryBatt[1].value = j2.toString() + "%";
            this.aryBatt[1].valueColor = this.boxTextColor;
          } catch(err) {
            this.aryBatt[1].value = "";
          }
          this.checkBatteryStatus(j2, battObj.Status); 
        }

      //(4) Pads:     table.js PadsStatusData = ['All', 'Normal', 'Expired Soon', 'Expired']
      let expireDate = this.testInfo.testitems.padsExpiringDate.result;
      let expired = this.testInfo.testitems.padsExpiried.result;
        let dateDiff = this.getDateDiff(expireDate);
        //console.log('TblBasic-mount', expireDate, 'dateDiff=', dateDiff);
        this.aryPads[1].value = expireDate;
        this.aryPads[1].valueColor = this.boxTextColor;
        if (this.devObj.PadLot !== "/" && this.devObj.PadLot !== "") {
          this.aryPads[1].msg = "lot: " + this.devObj.PadLot;
        } else {
          this.aryPads[1].msg = "lot: N/A";
        }
        if (expired > 0 || dateDiff <= 0) {
          this.aryPads[0].value = "Expired";
          this.aryPads[0].valueColor = "red--text";
          //this.setIcon(this.padsIcon, "mdi-close-circle", "red");
          this.iconPadsLevel = 0;  //0 gives a 'tick', 1 gives a 'X'
          this.iconPadsColor = "red";
        } else {
            if (dateDiff < 90 && dateDiff > 0) {  // 90 days --> warning
            this.aryPads[0].value = "Warning";
            this.aryPads[0].valueColor = "yellow--text";
            this.aryDev[1].value = 'Warning';
            this.aryDev[1].valueColor = 'yellow--text';
            this.iconPadsLevel = 0; 
            this.iconPadsColor = "yellow";
          } else {                             // expire > 90 days --> normal
            this.aryPads[0].value = "Normal";
            this.aryPads[0].valueColor = this.boxTextColor;
            this.iconPadsLevel = 1;
            this.iconPadsColor = "green";
          }
        }
   
        //(5) Location:      Normal or Warning
        this.aryLoc[0].value = "Normal";
        //this.setIcon(this.locIcon, "mdi-map-marker-outline", "green");

        //(6) Network:  TestInfo.js TblTestInfo[objs]
        let nwkLvl = this.testInfo.netinfo.SignalLevel;
        this.checkNetworkStatus(nwkLvl, netType);

        //(2) Device Status     // this.testInfo.testresult? 'Normal':'Fail';   
        //                   table.js DeviceStatusData ['All', 'Normal', 'Fault', 'Warning', 'Offline']
        let errcode = this.testInfo.testerrorcode;
        errcode = errcode.replaceAll("/", "");
        errcode = errcode.trim();
        if (this.testInfo.testresult === 0 || errcode != "") {  //1st priority
          this.aryDev[1].value = "Fault";
          this.aryDev[1].valueColor = "red--text";
          /* if (errcode != "") {
            this.aryDev[1].text = "Error";
            this.aryDev[1].value = errcode;
            this.aryDev[1].valueColor = this.boxTextColor;
          } */
          //this.setIcon(this.devStatusIcon, "mdi-close-circle", "red");
          this.iconDevStatusLevel = 0;   // 0 shows a close-circle, 1 check-circle
          this.iconDevStatusColor = 'red';
        }
        else {
          if (this.aryBatt[0].value === "Low Battery" || this.aryPads[0].value !== "Normal" ||  this.aryNetwork[0].value !== "Normal" ) {
            //2nd priority
            this.aryDev[1].value = "Warning";
            this.aryDev[1].valueColor = "yellow--text";
            this.iconDevStatusLevel = 0;
            this.iconDevStatusColor = 'yellow'; 
              /* let vmsg = "Source: ";   //display status in Device Status
              if (this.aryBatt[0].value === "Low Battery") vmsg += "Battery";
              if (this.aryPads[0].value === "Warning") vmsg += " Pads";
              this.aryDev[1].msg = vmsg; */
          } else {  //3rd priority
            this.aryDev[1].value = "Normal";
            this.aryDev[1].valueColor = "white--text";
            this.setIcon(this.devStatusIcon, "mdi-check-circle", "green");
            this.iconDevStatusLevel = 1;
            this.iconDevStatusColor = 'green';            
          }
          // handle exceptional case
          if (this.aryBatt[0].value === "Fault") {          // case 1:  batt level = 0
            this.aryDev[1].value = "Fault";
            this.aryDev[1].valueColor = "red--text";          
            this.iconDevStatusLevel = 0;   // 0 shows a close-circle, 1 check-circle
            this.iconDevStatusColor = 'red';
          }
        }
        this.aryDev[1].text = "Device Status";

        //end of setStatus()
      },

      // DESCRIPTION:  
      setErrorMsg() {
        if (this.testInfo !== undefined && this.testInfo !== null && 'errorMessage' in this.testInfo) {
          this.deleteBasicInfo();   // remove data starting from this.aryBasicLast

          let err = this.testInfo.errorMessage;
          //this.aryBasic.push({text:'', value: "", valueColor:  'black--text'});  // empty line
          let vObj = {};
          let str = "";
          if (err !== undefined && err !== null) {
            for(let idx=0; idx < err.length; idx++) {
              if (idx === 0) str = "Fault";
              else str = "";
              if (Ut.SysConf.language === 'eng') {
                //vObj = {text:'code ' + err[idx].code, value: err[idx].message, valueColor:  'yellow--text'};
                vObj = {text: str, value: err[idx].message, valueColor:  'yellow--text'};
              }
              else {
                //vObj = {text:'code ' + err[idx].code, value: err[idx].chiMessage, valueColor:  'yellow--text'};
                vObj = {text: str, value: err[idx].chiMessage, valueColor:  'yellow--text'};
              }
              if (vObj.text != "") {
                this.aryBasic.push(vObj);
              }
            } //end of for
          } 
        }

        //(5) relatedtest
        if ('relatedtest' in this.testInfo) {
          let str2 = Ut.getRelatedTestResult(this.testInfo.relatedtest);
          if (str2 !== "Normal") {
            this.aryBasic.push(
              { text: "malfunction", value: str2, valueColor: 'yellow--text' }
            );
          }
        }
      },

      turnOffIcons() {
        console.log("##TblBasic-turnOffIcons");
        this.iconBattLevel = 0;
        this.iconBattColor = 'grey';
        this.iconPadsLevel = 0;
        this.iconPadsColor = 'grey';
        this.iconDevStatusLevel = 0;
        this.iconDevStatusColor = 'grey';
        this.iconNwkLevel = 0;
        this.iconNwkColor = 'grey';

        this.aryBatt[0].value = "N/A"; this.aryBatt[0].valueColor = "grey--text";
        this.aryBatt[1].value = "N/A"; this.aryBatt[1].valueColor = "grey--text";
        this.aryPads[0].value = "N/A"; this.aryPads[0].valueColor = "grey--text";
        this.aryPads[1].value = "N/A"; this.aryPads[1].valueColor = "grey--text";
        this.aryDev[0].value = "N/A"; this.aryDev[0].valueColor = "grey--text";
        this.aryDev[1].value = "N/A"; this.aryDev[1].valueColor = "grey--text";
        this.aryNetwork[0].value = "N/A"; this.aryNetwork[0].valueColor = "grey--text";
        this.aryNetwork[1].value = "N/A"; this.aryNetwork[1].valueColor = "grey--text";                        

        if (!this.searchBasicInfo("Test Report unavailable")) {
          //this.aryBasic.push({text:'', value: "", valueColor:  'black--text'});  // empty line
          this.aryBasic.push({text:'', value: "Test Report unavailable", valueColor:  'yellow--text'});  // empty line
        }
      },

      searchBasicInfo(xValue) {
        if (this.aryBasic === undefined || this.aryBasic === null) return false;
        for(let idx=0; idx < this.aryBasic.length; idx++) {
          if (this.aryBasic[idx].value === xValue) return true;
        }
        return false;
      },

      deleteBasicInfo() {
        if (this.aryBasic === undefined || this.aryBasic === null) return;
        for(let idx=0; idx < this.aryBasic.length; idx++) {
          if (this.aryBasic[idx].text === this.aryBasicLast) {
            this.aryBasic = this.aryBasic.splice(0, idx + 1);
            return;
          }
        }
      }

    },

    async mounted () {
      // props:  devObj, testInfo
      this.selectRescue = "30";

      // Load picture to Carousel
      if (this.devObj.Pic !== undefined && this.devObj.Pic !== null) {
        for(let idx=0; idx < this.devObj.Pic.length; idx++) {
          this.axiosGetImage(this.devObj.Pic[idx]);
        }
        if (this.devObj.Pic.length === 0) {
          this.bImageExist = false;
        } else {
          this.bImageExist = true;
        }
      } else {
        this.bImageExist = false;
      }

      // Load rescue info to Timeline
      //let vRescue = await this.axiosGetEmergencySn(this.devObj.devSn);
      let vRescue = await this.axiosGetEmergencySn2(this.devObj.devSn);  //with interval=days
      if (vRescue !== undefined && vRescue !== null) {
        this.storeEmergToTable(vRescue);
      }

      // turn off icons, if test report not availabe
      if (!this.devObj.testreport) {
        this.turnOffIcons();  
      } 

    },

    watch: {
      // props:  devObj(from DashBoard.vue), testInfo(from DevInfoDetail.vue)
      trigger: function(newVal) {
        if ( this.testInfo === undefined || this.testInfo === null)  {
          // turn off icons if test report not available
          this.turnOffIcons();        
        } else {
          // set icon levels and display
          this.setStatus();
          this.setErrorMsg();
          this.aryBasic[2].value = this.getLocation(this.devObj);
        }         
        if (!this.devObj.testreport) {  //testreport abs for this sn
          this.turnOffIcons();  
        }
        //console.log("##BasicInfo1", this.devObj);  //debug*
        //console.log("##BasicInfo2", this.testInfo);  //debug*
      }
    }

  }
</script>

<style scoped>

#id_timeline { 
  overflow: hidden; 
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

#id_timeline::-webkit-scrollbar {
    display: none;
}

.theme--dark.v-timeline:before {
    background: orange;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: white;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: white;
}

.fontN2 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: normal;   
  color: white;
}

/* for number only */
.fontN3W {
  font-family: Terminal; 
  font-style: normal; 
  font-size: 0.7em;
  font-weight: normal;   
  color: white;
}

.fontN3B {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: normal;   
  color: black;
}

.fontTimeLine1 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.4em;
  font-weight: normal;   
  color: white;
}

.fontTimeLine2 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.0em;
  font-weight: normal;   
  color: white;
}

.fontTimeLst {
  font-family: Montserrat !important; 
  font-style: normal; 
  font-size: .9em;
  font-weight: normal;   
  color: white;
}

.bg_page {
  background-color: #424141;
}

.bg_card {
  background-color: #303030;
}


</style>
