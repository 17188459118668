<template>

  <v-card width="62px" height="55px" class="transparent" style="position:relative;">

    <!-- <v-sheet width="45px" height="45px" :color="bgColor" class="ml-2 mt-2 pa-0 rounded-pill" 
      style="filter: blur(2px); opacity: 0.4; position: absolute; top:0; left:0;" >
    </v-sheet>  -->

    <v-sheet width="100%" height="100%" :color="bgColor" class="ml-2 mt-2 pa-0 rounded-pill" 
      style="filter: blur(2px); opacity: 0.4; position: absolute; top:0; left:0;" >
    </v-sheet>    

    <svg-icon type="mdi" :path="pathVar" :class="iconClass" size="38" 
      style="filter: contrast(150%) brightness(110%); position: absolute; top:16; left:15;"></svg-icon>

  </v-card>

</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiShieldAlertOutline, mdiShieldCheckOutline, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiAccessPointOff, mdiAccessPointCheck } from '@mdi/js';


export default {
  name: 'ButtonBlur',

  components: {
    SvgIcon
  },

  props: {
    pIconType: {type: String, default: ''},
    pIconStatus: {type: String, default: ''},
    pValue: {type: Number, default: 0}
  },

  data () {
    return {
      bgColor: 'red',

      pathVar: mdiShieldAlertOutline,
      pathShieldAlertOutline: mdiShieldAlertOutline,
      pathShieldCheckOutline: mdiShieldCheckOutline,
      pathAlertCircleOutline: mdiAlertCircleOutline,
      pathCheckCircleOutline: mdiCheckCircleOutline,
      pathAccessPointOff: mdiAccessPointOff,
      pathAccessPointCheck: mdiAccessPointCheck,
      iconClass: "darken-5 red--text rounded-pill text-center"
    }
  },

  methods: {

    setIcon () {
      switch(this.pIconType) {
        case 'Fault': 
          this.bgColor = "red";
          this.iconClass = "darken-5 red--text rounded-pill";
          if (this.pValue > 0) this.pathVar = this.pathShieldAlertOutline;
          else {
            this.pathVar = this.pathShieldCheckOutline;
            this.bgColor = "green";
            this.iconClass = "darken-5 green--text rounded-pill";
          }
          break;

        case 'Warning':
          this.bgColor = "yellow darken-3";
          this.iconClass = "colorYellow rounded-pill";
          if (this.pValue > 0) this.pathVar = this.pathAlertCircleOutline;
          else {
            this.pathVar = this.pathCheckCircleOutline;          
            this.bgColor = "green";
            this.iconClass = "darken-5 green--text rounded-pill";            
          }
          break;

        case 'Offline':
          this.bgColor = "lighten-2 purple";
          this.iconClass = "accent-4 purple--text rounded-pill";
          if (this.pValue > 0) this.pathVar = this.pathAccessPointOff;
          else {
            this.pathVar = this.pathAccessPointCheck;              
            this.bgColor = "green";
            this.iconClass = "darken-5 green--text rounded-pill";            
          }
          break;
      }
    }
  },  //end of method

  watch: {
    pValue: function(newVal) {
      this.setIcon();
    }

  },

  mounted () {
    this.setIcon();
  }
}
</script>


<style scoped>

.colorYellow {
  /*  #FFEB3B  #F4BE37  #FFBB56  */
  color: #F4BE37 
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>


