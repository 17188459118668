<template>
  <div class="cRoot">
    <!--  <v-btn @click="exportFile">Export XLSX</v-btn>  -->
    <v-btn width="110px" height="22px" color="#37474F" class="mt-1 ml-1 white--text text-capitalize fontB" 
    @click="exportTable">Export</v-btn>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color" class="fontB">{{ snackBar.message }}</v-snackbar>
  </div> 

</template>

<script>
import moment from 'moment'
// import * as XLSX from "xlsx"
import { utils, writeFileXLSX } from 'xlsx';
import * as Ut from '@/js/ut.js'
import { mdiToothbrushElectric } from '@mdi/js';

export default {
  name: 'exportExcel',
  props: {
    tbl: {type: Array},
    selectedSn: {type: Array}
  },

  data () {
    return {
      snackBar: {"on": false, "color": "", "message": ""},
      tmrObj: {},
      snackTimeDef: 3000,
      snackTimeout: this.snackTimeDef,
      aryExport: []
    }
  },

  methods: {
    exportTable() {
      this.copySelected(this.selectedSn);
      if (this.aryExport === null || this.aryExport === undefined) {
        this.snackBar.message = "Please select device(s) before export";
        this.snackBar.color = "error";
        this.snackBar.on = true;        
        return;
      }

      if (this.aryExport.length > 0) {
        this.removeFields();
        this.exportToExcel();
        //console.log('##Excel', this.aryExport);  //debug
      } else {
        this.snackBar.message = "Please select device(s) before export";
        this.snackBar.color = "error";
        this.snackBar.on = true;
      }
    },

    copySelected(xary) {
      Ut.clearArray(this.aryExport);
      if (xary !== null && xary !== undefined) {
        this.aryExport = xary;
      }
      else if (this.tbl !== null && xary !== undefined) {
        this.aryExport = this.tbl;
      }
    },

    removeFields() {
      for(let idx=0; idx < this.aryExport.length; idx++) {
        delete this.aryExport[idx].show;
        delete this.aryExport[idx].RescueTimes;
        delete this.aryExport[idx].Pic;
        delete this.aryExport[idx].Offline;
        delete this.aryExport[idx].Map;
        delete this.aryExport[idx].DevAdmin;
      }
    },

    exportToExcel() {
      // (1) generate worksheet from state 
      const ws = utils.json_to_sheet(this.aryExport);

      // (2) create workbook and append worksheet 
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");

      // (3) export to XLSX 
      //writeFileXLSX(wb, "SheetJSVueAoO.xlsx");
      let sOutputFile = process.env.VUE_APP_EXCEL_FILE + moment(new Date()).format("YYYY-MM-DD") + ".xlsx";
      writeFileXLSX(wb, sOutputFile);
    },

      // ----------------------------------------------------------------------------
      //                   Ut
      // ----------------------------------------------------------------------------
      snackStart(xmsg, xcolor, xtime) {
        if (this.snackBar.on) {
          this.snackStop();
        }
        this.snackBar.message = xmsg;
        this.snackBar.color = xcolor;
        this.snackBar.on = true;
        if (xtime >= 1000) {
          this.tmrObj = setTimeout(this.snackTimerEvent, xtime);
        } else {
          this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
        }
      },

      snackTimerEvent() {
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
        this.snackTimeout = this.snackTimeDef;  //default timeout 3sec
      },

      snackStop() {
        clearTimeout(this.tmrObj);
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
  color: #42b983;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>


<!-- ===================================================================================

Examples
1.  https://docs.sheetjs.com/docs/demos/frontend/vue/



SheetJS Tutorial
https://docs.sheetjs.com/docs/getting-started/examples/export/


-->