<!-- --------------------------------------------------------------------------------
                      DevStatus2BasicInfo.vue

DESCRIPTIONS
  - display Basic Info card

---------------------------------------------------------------------------------- -->
<template>
  <v-card :color="bgcolor" :width="width" height="100%" class="pt-0 rounded-lg">

    <v-sheet :color="bgcolor" height="20px" class="d-flex ma-0 pa-0">
      <v-sheet :color="bgcolor" class="ml-2 mt-1 pb-1 white--text text-no-wrap fontN">Basic Info</v-sheet>

      <!-- <dialogDevInfoDetail height="20" width="120" :devObj="DevObj" color="455A64" class="mr-2 mt-1 pa-0" /> -->
      <v-sheet :color="bgcolor" class="ml-5">
        <svg-icon type="mdi" :path="pathInfoSlabCircle" color="green" size="28"></svg-icon>
      </v-sheet>
    </v-sheet>

    <v-list dense :color="bgcolor"  style="height: calc(100% - 25px)"> 
      <v-list-item-group  color="transparent">
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0 transparent">
            <v-list-item-content class="pa-0 ml-1 transparent">

              <!-- (1) single line message -->
              <v-row v-if="item.text == 'message'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-sheet class="fontN" :class="getMessageColor(item)">{{ item.value }}</v-sheet>
              </v-row>

              <!-- (2a) 2 lines message - 1st line -->
              <v-row v-else-if="item.text == 'longline1'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-sheet class="grey--text transparent fontN">{{ item.value }}</v-sheet>
              </v-row>

              <!-- (2a) 2 lines message - 2nd line -->
              <v-row v-else-if="item.text == 'longline2'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"></v-col>
                <v-col cols="7" class="text-right"><span class="fontN" :class="item.valueColor">{{ item.value }}</span></v-col>
              </v-row>                 

              <!-- (3) single line message - left & right -->
              <v-row v-else no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"><span class="grey--text fontN">{{ item.text }}</span></v-col>
                <v-col cols="7" class="text-right"><span class="fontRight" :class="item.valueColor">{{ item.value }}</span></v-col>
              </v-row>

              <!-- 
              <v-row v-if="item.text == 'message'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-sheet :class="getMessageColor(item)" style="font-weight: normal; font-size: 0.9em; font-family: Montserrat;">{{ item.value }}</v-sheet>
              </v-row>

              <v-row v-else-if="item.text == 'longline1'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-sheet class="grey--text transparent fontN" style="font-weight: normal; font-size: 0.9em; font-family: Montserrat;">{{ item.value }}</v-sheet>
              </v-row>

              <v-row v-else-if="item.text == 'longline2'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"></v-col>
                <v-col cols="7"><span :class="item.valueColor" style="font-weight: normal; font-size: 0.9em; font-family: Montserrat;">{{ item.value }}</span></v-col>
              </v-row>                 

              <v-row v-else no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"><span class="grey--text fontN" style="font-size: 0.9em;">{{ item.text }}</span></v-col>
                <v-col cols="7"><span :class="item.valueColor" style="font-weight: normal; font-size: 0.9em; font-family: Montserrat;">{{ item.value }}</span></v-col>
              </v-row>
              -->

            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-card>
</template>

<script>
import dialogDevInfoDetail from '@/components/DevInfoPage/dialogDevInfoDetail'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiInformationSlabCircle } from '@mdi/js';

  export default {
    name: 'DevStatus2BasicInfo',

    components: {
      SvgIcon, dialogDevInfoDetail
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      DevObj: {type: Object, default: null}
    },

    data () {
      return {
        vIcon: {icon: 'mdi-information', color:  "green"},
        pathInfoSlabCircle: mdiInformationSlabCircle
      }
    },

    methods: {
      getMessageColor (xitem) {
        return xitem.valueColor +" transparent";
      }
    }

  }
</script>

<style scoped>



.cBorder {
  border: 1px solid grey;
}

.v-list-item {
  min-height: 20px;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  font-size: 0.9em;
  color: black;
}

.fontRight {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  font-size: 1.0em;
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

</style>
